import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBarberAboutInfo, putBarberAboutInfo } from "./barberAboutAPI";

// Get barber About info
export const getAsyncBarberAboutInfo = createAsyncThunk(
  "barber-about/getInfo",
  async () => {
    const response = await getBarberAboutInfo();
    return response;
  }
);

// Put barber About info
export const putAsyncBarberAboutInfo = createAsyncThunk(
  "barber-about/postInfo",
  async (data) => {
    const response = await putBarberAboutInfo(data);
    return response;
  }
);

const initialState = {
  // Data
  barberAboutSlice: {},
  // Status
  fetchStatus: "idle",
  updateStatus: "idle",
  // Errors
  fetchError: null,
  updateError: null,
};

const barberAboutSlice = createSlice({
  name: "barber-home",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get barber About info
      .addCase(getAsyncBarberAboutInfo.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncBarberAboutInfo.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.barberAboutSlice = action.payload;
      })
      .addCase(getAsyncBarberAboutInfo.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Put barber About info
      .addCase(putAsyncBarberAboutInfo.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(putAsyncBarberAboutInfo.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.barberAboutSlice = action.payload;
      })
      .addCase(putAsyncBarberAboutInfo.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      });
  },
});

export default barberAboutSlice.reducer;
