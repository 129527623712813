import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Offcanvas, Form, Button, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAsyncAllCategory } from "../../../store/category/categorySlice";
import { fetchAsyncEmployeeByServiceId } from "../../../store/employeeServices/employeeServicesSlice";
import { createAsyncWaitlist } from "../../../store/bookingAndWaitlist/bookingAndWaitlistSlice";
import { fetchTodaysWaitlist } from "../../../store/waitlist/waitlistSlice";
import Swal from "sweetalert2";

const WaitListOffCanvas = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [serviceSelected, setServiceSelected] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [fetchingEmployees, setFetchingEmployees] = useState(false);

  useEffect(() => {
    dispatch(fetchAsyncAllCategory());
    if (selectedServiceId !== null) {
      setFetchingEmployees(true);
      dispatch(fetchAsyncEmployeeByServiceId(selectedServiceId))
        .then(() => setFetchingEmployees(false))
        .catch(() => setFetchingEmployees(false));
    }
  }, [dispatch, selectedServiceId]);

  const allCategory = useSelector((state) => state.Category.allCategory);
  const allEmployees = useSelector(
    (state) => state.EmployeeServices.servicesGivenEmpoyees
  );

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value;

    const formattedPhoneNumber = inputPhoneNumber.replace(/^\+/, "");

    const lebanesePhoneNumberPattern = /^(961|0)?(0|1|3|4|7|8)\d{7}$/;

    setPhoneNumber(inputPhoneNumber);

    if (lebanesePhoneNumberPattern.test(formattedPhoneNumber)) {
      setPhoneError("");
    } else {
      setPhoneError("Enter a valid Lebanese phone number");
    }
  };

  const handleServiceChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setServiceName(selectedOption.value);
    setServiceSelected(true);
    setSelectedServiceId(selectedOption.id);
  };

  const handleEmployeeChange = (selectedOption) => {
    setEmployeeName(selectedOption.value);
    setSelectedEmployeeId(selectedOption.value);
  };

  const isFormValid = () => {
    return (
      name !== "" &&
      phoneNumber !== "" &&
      serviceName !== "" &&
      employeeName !== ""
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      phoneNumber,
      serviceName,
      employeeName,
    };

    const employeeId = selectedEmployeeId;
    const serviceId = selectedServiceId;

    await dispatch(createAsyncWaitlist({ employeeId, serviceId, data }));
    await dispatch(fetchTodaysWaitlist());
    // Display SweetAlert confirmation
    Swal.fire({
      icon: "success",
      title: "Added to waitlist!",
      showConfirmButton: false,
      timer: 1500,
    });
    // Reset form fields
    setName("");
    setPhoneNumber("");
    setServiceName("");
    setEmployeeName("");
    setServiceSelected(false);
    setSelectedServiceId(null);
    setSelectedEmployeeId(null);
  };

  let options = [];
  allCategory?.forEach((category) => {
    category?.services?.forEach((service) => {
      options.push({
        value: service.name,
        label: service.name,
        id: service.id,
      });
    });
  });

  const employees = allEmployees?.map((employee) => ({
    value: employee.id,
    label: employee.name,
  }));

  // Custom dropdown indicator with spinner
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {fetchingEmployees ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ms-2"
            />
          ) : (
            props.children
          )}
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add Waitlist</Offcanvas.Title>
      </Offcanvas.Header>
      <hr />
      <Offcanvas.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={handleNameChange}
            />

            <Form.Label className="mt-3">Phone Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              isInvalid={phoneError}
            />
            <Form.Control.Feedback type="invalid">
              {phoneError}
            </Form.Control.Feedback>

            <Form.Label className="mt-3">Service Name</Form.Label>
            <Select
              options={options}
              className="w-100"
              style={{
                lineHeight: "40px",
                color: "#7e7e7e",
                paddingLeft: " 15px",
              }}
              onChange={handleServiceChange}
            />
            <Form.Label className="mt-3">Employee Name</Form.Label>

            <Select
              options={employees}
              className="w-100"
              isDisabled={!serviceSelected}
              styles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: "38px",
                }),
              }}
              onChange={handleEmployeeChange}
              components={{ DropdownIndicator }}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              Swal.fire({
                position: "top-end",
                title: "Wait a moment, please",
                showConfirmButton: false,
                icon: "info",
                timer: 2000,
                toast: true,
              });
              handleClose();
            }}
            disabled={!isFormValid()}
          >
            Submit
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default WaitListOffCanvas;
