import React, { useEffect } from "react";
import { SVGICON } from "../../../constant/theme";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {fetchAsyncTotalAnalytics} from "../../../../store/analytics/analyticsSlice";
import OrderSummary from "./OrderSummary";
import Revenue from "./Revenue";


const Home = () => {
  const dispatch = useDispatch();
  // svg map
  useEffect(() => {
    dispatch(fetchAsyncTotalAnalytics());
  }, [dispatch]);
  const totalAnalytics = useSelector((state) => state.analytics.totalAnalytics);

  const iconBoxCard = [
    {
      title: "Total Customers this Month",
      number: totalAnalytics ? totalAnalytics.totalCustomer : 0,
      icon: SVGICON.DashHome,
    },
    { title: "Total Profit or Loss", number: totalAnalytics ? totalAnalytics.totalRevenue : 0, icon: SVGICON.GroupCoin },
    {
      title: "Revenue of this Month",
      number: totalAnalytics ? totalAnalytics.revenueOfThisMonth : 0,
      icon: SVGICON.MessagePing,
    },
    { title: "Total Expenses", number: totalAnalytics ? totalAnalytics.totalExpense : 0, icon: SVGICON.DoubleUser },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          {iconBoxCard.map((item, ind) => (
            <div className="col-xl-3 col-sm-6" key={ind}>
              <div className="card">
                <div className="card-body d-flex justify-content-between">
                  <div className="card-menu">
                    <span>{item.title}</span>
                    <h2 className="mb-0">{item.number}</h2>
                  </div>
                  <div className="icon-box icon-box-lg bg-primary-light">
                    {item.icon}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-xl-12 custome-width">
            <OrderSummary />
          </div>
          <div className="col-xl-12 custome-width">
            <Revenue />
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
