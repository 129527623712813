import React from "react";
import Booking from "./Booking/Booking";
import Waitlist from "./Waitlist";
import Serving from "./Serving";
import CompleteToday from "./CompleteToday";
import OverviewTable from "./OverviewTable";
import { useDispatch } from "react-redux";
import { fetchAllData } from "../../../store/globalActions";
import { subscriptionClient } from "../../../apollo-client/subscription"
import { gql } from "apollo-boost";
const VisitsNav = ({ selectBtn, tableView }) => {
  const dispatch = useDispatch();
  /**
   * This section establishes a subscription using the Apollo Client instance previously configured.
   * It subscribes to a GraphQL subscription, listens for incoming data, and performs actions accordingly.
   *
   * Subscription Query:
   * - The subscription query defines the data structure to be received from the server.
   * - In this example, it subscribes to the 'createReservation' event and specifies the fields to be returned.
   *
   * Subscription Handling:
   * - The 'subscribe' method initiates the subscription.
   * - Upon receiving data from the server:
   *   - The 'next' callback logs the received data and dispatches an action to fetch all data.
   *   - The 'error' callback logs any errors that occur during the subscription.
   *   - The 'complete' callback logs a message when the subscription is completed (although in this case, it's set up as a continuous subscription and may never complete).
   *
   * Note:
   * - 'eslint-disable-next-line no-undef' is used to disable linting errors for the 'gql' function, which may not be recognized by ESLint.
   * - The subscription is stored in the 'subscription' variable, allowing it to be manipulated or unsubscribed from later if needed.
   */
  const reservationSubscription = subscriptionClient
    .subscribe({
      // eslint-disable-next-line no-undef
      query: gql`
        subscription MySubscription {
          createReservation {
            id
            name
            reservationDate
            reservationTime
          }
        }
      `,
    })
    .subscribe({
      next(data) {
        console.log({ data }); // Log received data
        dispatch(fetchAllData()); // Dispatch action to fetch all data
      },
      error({ error }) {
        console.log({ error }); // Log any errors
      },
      complete() {
        console.log("subs. DONE"); // Log completion message
        reservationSubscription.unsubscribe();
      }, // This callback is never printed, as this subscription is intended to be continuous
    });

  // waitlist subscription
  const waitlistSubscription = subscriptionClient
    .subscribe({
      query: gql`
        subscription MySubscription {
          createWaitlist {
            id
            name
            phone
          }
        }
      `,
    })
    .subscribe({
      next(data) {
        console.log({ data }); // Log received data
        dispatch(fetchAllData()); // Dispatch action to fetch all data
      },
      error({ error }) {
        console.log({ error }); // Log any errors
      },
      complete() {
        console.log("subs. DONE"); // Log completion message
        waitlistSubscription.unsubscribe();
      }, // This callback is never printed, as this subscription is intended to be continuous
    });

  console.log({ reservationSubscription }, "subscription");
  console.log({ waitlistSubscription }, "subscription");

  return (
    <div className="container-fluid">
      <div className="row">
        {selectBtn === "overview" ? (
          tableView ? (
            <div className="col-12">
              <OverviewTable tableView={tableView} />
            </div>
          ) : (
            <div className="row justify-content-between">
              <div className="col-lg-3">
                <Booking></Booking>
              </div>
              <div className="col-lg-3">
                <Waitlist></Waitlist>
              </div>
              <div className="col-lg-3">
                <Serving></Serving>
              </div>
              <div className="col-lg-3">
                <CompleteToday></CompleteToday>
              </div>
            </div>
          )
        ) : selectBtn === "booking" ? (
          <>
            <div className="">
              <Booking></Booking>
            </div>
          </>
        ) : selectBtn === "waitlist" ? (
          <>
            <div className="">
              <Waitlist></Waitlist>
            </div>
          </>
        ) : selectBtn === "serving" ? (
          <>
            <div className="">
              <Serving></Serving>
            </div>
          </>
        ) : selectBtn === "completed" ? (
          <>
            <div className="">
              <CompleteToday></CompleteToday>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default VisitsNav;
