import React from "react";
import { Card, Dropdown } from "react-bootstrap";

const Services = () => {
  return (
    <>
      <Card className="m-3">
        <Card.Header as="h5">Serving</Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-md-3">
              <h5>Verification</h5>
              <p className="text-muted">
                Scan the QR code that each client receives to automatically
                serve them when it's their turn.{" "}
              </p>
            </div>

            <div className="col-md-9">
              <h5>QR Scan Action</h5>
              {/* dropdown starts */}
              <Dropdown className="mb-3">
                <Dropdown.Toggle
                  className=" col-md-4"
                  variant="outline-primary"
                  id="dropdown-basic"
                >
                  user
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">All user</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">New user</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">No users</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* dropdown ends */}
            </div>
          </div>
          <Card.Footer className="mt-3" />
        </Card.Body>
      </Card>
    </>
  );
};

export default Services;
