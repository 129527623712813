import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAsyncBarberAboutInfo,
  putAsyncBarberAboutInfo,
} from "../../../store/barberAbout/barberAboutSlice";
import Swal from "sweetalert2";
import {
  getAsyncContact,
  updateAsyncContact,
} from "../../../store/contact/contactSlice";
import { fetchContact } from "../../../store/testAPI/testSlice";

const GeneralProfile = () => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [landline, setLandline] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form submission starts

    try {
      const data = { phone, landline, email, address };
      await dispatch(updateAsyncContact(data));
      Swal.fire({
        title: "Success",
        text: "Update Contact Success",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.error("Error updating contact:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to update contact",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } finally {
      setLoading(false); // Set loading to false after the update operation is complete
    }
  };

  // Get All Data from Contact
  useEffect(() => {
    dispatch(getAsyncContact());
  }, [dispatch]);
  const contact = useSelector((state) => state.Contact.contactDetails);
  const status = useSelector((state) => state.Contact.fetchStatus);
  

  //Test API
  useEffect(() => {
    dispatch(fetchContact());
  }, [dispatch]);
  const test = useSelector((state) => state.Test.contact);
  

  useEffect(() => {
    if (contact) {
      setPhone(contact.phone);
      setLandline(contact.landline);
      setEmail(contact.email);
      setAddress(contact.address);
    }
  }, [contact]);

  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <h5>BarberShop Profile</h5>
          <p className="text-muted">
            Your location name abss is displayed across many areas including on
            your online pages and messages to client{" "}
          </p>
        </div>
        <div className="col-md-9">
          <form onSubmit={handleSubmit}>
            <div className="col">
              <div data-mdb-input-init className="form-outline">
                <h5 className="mt-3">Phone</h5>
                <input
                  type="text"
                  value={phone}
                  required
                  onChange={(e) => setPhone(e.target.value)}
                  id="form6Example1"
                  className="form-control"
                />
              </div>
            </div>
            <div data-mdb-input-init className="form-outline mb-4">
              <h5 className="mt-3">Landline</h5>
              <input
                value={landline}
                type="text"
                required
                onChange={(e) => setLandline(e.target.value)}
                id="form6Example4"
                className="form-control"
              />
            </div>

            <div data-mdb-input-init className="form-outline mb-4">
              <h5 className="mt-3">Email</h5>
              <input
                type="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                id="form6Example5"
                className="form-control"
              />
            </div>

            <div data-mdb-input-init className="form-outline mb-4">
              <h5 className="mt-3">address</h5>

              <input
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                id="form6Example6"
                className="form-control"
              />
            </div>
            <button
              onClick={() => {
                Swal.fire({
                  position: "top-end",
                  title: "Wait a moment, please",
                  showConfirmButton: false,
                  icon: "info",
                  timer: 2000,
                  toast: true,
                });
              }}
              className="btn btn-outline-primary rounded-5"
            >
              {loading === true ? (
                <div className="d-flex align-items-center justify-content-center ">
                  <span className="me-2">Initiated</span>
                  <Spinner animation="border" size="sm" />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </form>
        </div>
      </div>
      {/* About  */}
      <Card.Footer className="mt-3" />
    </div>
  );
};

export default GeneralProfile;
