



import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBarberMonthlytRevenue } from './dailyAndMonthlyRevenueAPI';

// get monthly revenue
export const fetchAsyncBarberMonthlyRevenueData = createAsyncThunk(
  "monthlyRevenue/fetchAsyncMonthlyRevenue",
  async () => {
    const revenue = await getBarberMonthlytRevenue();
    return revenue;
  }
);

const initialState = {
  barberMonthlyRevenue: [],
  status: 'idle',
  error: null
};

const monthlyRevenueSlice = createSlice({
  name: 'barberMonthlyRevenues',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncBarberMonthlyRevenueData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAsyncBarberMonthlyRevenueData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.barberMonthlyRevenue = action.payload;
      })
      .addCase(fetchAsyncBarberMonthlyRevenueData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default monthlyRevenueSlice.reducer;