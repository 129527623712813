import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createEmployeeHours,
  getEmployeeHours,
} from "./employeeWorkingHoursAPI";

// Add employeeWorkingHours
export const createAsyncEmployeeHours = createAsyncThunk(
  "employee/createEmployee",
  async (data) => {
    console.log(data);
    const response = await createEmployeeHours(data);
    return response;
  }
);

// Fetch employeeWorkingHours
export const fetchEmployeeWorkingHours = createAsyncThunk(
  "employee/fetchEmployeeWorkingHours",
  async (employeeId) => {
    const response = await getEmployeeHours(employeeId);
    return response;
  }
);

const initialState = {
  employeeWorkingHours: [],
  createWorkingHours: "",
  loading: "idle",
  error: null,
};

const employeeWorkingHoursSlice = createSlice({
  name: "employeeWorkingHours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAsyncEmployeeHours.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(createAsyncEmployeeHours.fulfilled, (state, {payload}) => {
        state.createWorkingHours = payload;
        state.loading = "succeeded";
      })
      .addCase(createAsyncEmployeeHours.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchEmployeeWorkingHours.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchEmployeeWorkingHours.fulfilled, (state, action) => {
        state.employeeWorkingHours = action.payload;
        state.loading = "succeeded";
      })
      .addCase(fetchEmployeeWorkingHours.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  }
});

export default employeeWorkingHoursSlice.reducer;
