import { del, get, post, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

// Get Trending Images
export async function getTrendingImages() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/trending-images",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Update Trending Image
export async function updateTrendingImage({ id, title }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/trending-images/${id}`,
      options: { body: { title } },
    });
    const response = await restOperation.response;
    Swal.fire("Success", "Image updated successfully.", "success");
    return response.body.json();
  } catch (error) {
    console.log("PUT call failed: ", error);
  }
}

// Get Trending Single Image
export async function getTrendingSingleImage(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/trending-images/${id}`,
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Post Trending Image
export async function postTrendingImage({ title, image }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/trending-images",
      options: { body: { title, image } },
    });
    const response = await restOperation.response;
    Swal.fire("Success", "Image added successfully.", "success");
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
  }
}

// Delete Trending Image
export async function deleteTrendingImage(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/trending-images/${id}`,
    });
    await restOperation.response;
  } catch (error) {
    console.log("DELETE call failed: ", error);
  }
}
