import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteGalleryImage,
  getGalleryImages,
  getGallerySingleImage,
  postGalleryImage,
  updateGalleryImage,
} from "./galleryAPI";

// Get Gallery Images
export const getAsyncGalleryImages = createAsyncThunk(
  "gallery/fetchImages",
  async () => {
    const response = await getGalleryImages();
    // console.log("response", response);
    return response;
  }
);

// Get Gallery Single Image
export const getAsyncGallerySingleImage = createAsyncThunk(
  "gallery/fetchSingleImage",
  async (id) => {
    const response = await getGallerySingleImage(id);
    // console.log("response", response);
    return response;
  }
);

// Post Gallery Image
export const postAsyncGalleryImage = createAsyncThunk(
  "gallery/addImage",
  async (data) => {
    console.log("data", data);
    const response = await postGalleryImage(data);
    return response;
  }
);

// Update Gallery Image
export const updateAsyncGalleryImage = createAsyncThunk(
  "gallery/updateImage",
  async (data) => {
    const response = await updateGalleryImage(data);
    console.log("response", response);
    return response;
  }
);

// Delete Gallery Image
export const deleteAsyncGalleryImage = createAsyncThunk(
  "gallery/deleteImage",
  async (id) => {
    const response = await deleteGalleryImage(id);
    console.log("response", response);
    return { id, ...response };
  }
);

const initialState = {
  // Data
  allImages: [],
  // Status
  fetchStatus: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  // Errors
  fetchError: null,
  createError: null,
  updateError: null,
  deleteError: null,
};

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get Gallery Images
      .addCase(getAsyncGalleryImages.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncGalleryImages.fulfilled, (state, { payload }) => {
        console.log("Get payload", payload)
        state.fetchStatus = "succeeded";
        state.allImages = payload;
      })
      .addCase(getAsyncGalleryImages.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Post Gallery Image
      .addCase(postAsyncGalleryImage.pending, (state) => {
        state.createStatus = "loading";
        state.createError = null;
      })
      .addCase(postAsyncGalleryImage.fulfilled, (state, { payload }) => {
        console.log("Post payload", payload)
        state.createStatus = "succeeded";
        state.allImages.push(payload);
      })
      .addCase(postAsyncGalleryImage.rejected, (state, action) => {
        state.createStatus = "failed";
        state.createError = action.error.message;
      })
      // Update Gallery Image
      .addCase(updateAsyncGalleryImage.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(updateAsyncGalleryImage.fulfilled, (state, action) => {
        state.allImages = state.allImages.map((image) =>
          image.id === action.payload.id ? action.payload : image
        );
        state.updateStatus = "succeeded";
      })
      .addCase(updateAsyncGalleryImage.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      })
      // Delete Gallery Image
      .addCase(deleteAsyncGalleryImage.pending, (state) => {
        state.deleteStatus = "loading";
        state.deleteError = null;
      })
      .addCase(deleteAsyncGalleryImage.fulfilled, (state, action) => {
        state.allImages = state.allImages.filter(
          (image) => image.id !== action.payload.id
        );
        state.deleteStatus = "succeeded";
      })
      .addCase(deleteAsyncGalleryImage.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.deleteError = action.error.message;
      });
  },
});

export default gallerySlice.reducer;
