import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsyncTrendingImage,
  getAsyncTrendingImages,
  updateAsyncTrendingImage,
} from "../../../store/trending/trendingSlice";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import AddNewImage from "./AddNewImage";
import AddCustomImage from "./AddCustomImage";
import { Spinner } from "react-bootstrap";

const Trending = () => {
  // All states
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  // Modal For Add a New
  const [selectedImage, setSelectedImage] = useState(null);
  // Modal for Add a New
  const [modalShow, setModalShow] = useState(false);
  // Modal for Add Custom
  const [modalShowCustom, setModalShowCustom] = useState(false);

  // Get Trending Images
  useEffect(() => {
    dispatch(getAsyncTrendingImages());
  }, [dispatch]);
  const trending = useSelector((state) => state.Trending.allImages);
  const status = useSelector((state) => state.Trending.fetchStatus);

  // Delete Image
  const deleteImage = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure for delete?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd6b55",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
        await dispatch(deleteAsyncTrendingImage(id));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } catch (error) {
        Swal.fire("Error", "An error occurred while deleting.", "error");
        console.error("Error deleting service:", error);
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, [id]: false }));
      }
    }
  };

  // Update Image
  const handleFavorite = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure you want to change Title?",
      text: "This will toggle the title of this image.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, change it!",
    });

    if (result.isConfirmed) {
      try {
        await dispatch(updateAsyncTrendingImage(title));
        Swal.fire("Changed!", "Title has been changed.", "success");
      } catch (error) {
        Swal.fire("Error", "An error occurred while changing title.", "error");
        console.error("Error updating image:", error);
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">Trending Section</h4>
                <AddNewImage
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                <AddCustomImage
                  show={modalShowCustom}
                  onHide={() => setModalShowCustom(false)}
                />
                <div>
                  <button
                    onClick={() => setModalShowCustom(true)}
                    className="btn btn-primary me-3"
                  >
                    Add Custom
                  </button>
                  {/* <button
                    onClick={() => setModalShow(true)}
                    className="btn btn-primary"
                  >
                    Add a New
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-1">
          <div id="trending" className="px-2 pb-5">
            {status === "loading" ? (
              <Loading status={status} />
            ) : (
              <div className="card-body row g-3">
                {trending?.map((trending, index) => (
                  <div
                    key={index}
                    className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                  >
                    <div className="de-image-hover shadow-none rounded position-relative">
                      <img
                        src={trending?.url}
                        className="rounded-2 mx-auto gallery-image"
                        alt=""
                      />
                      <div className=" py-2 my-2 position-absolute trending-img-delete">
                        <h5 className="text-center bg-white px-2 rounded-2">
                          {trending?.title}
                        </h5>
                      </div>
                      <div>
                        <button
                          onClick={() => deleteImage(trending?.id)}
                          className="btn btn-danger py-1 px-2 m-3 m-lg-2 position-absolute trending-custom-button"
                        >
                          {loading[trending?.id] === true ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <FaTrash />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Trending;
