import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAsyncBarberHomeInfo,
  putAsyncBarberHomeInfo,
} from "../../../store/barberHome/barberHomeSlice";
import Swal from "sweetalert2";

const GeneralHome = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);

  const [initialTitle, setInitialTitle] = useState("");
  const [initialSubtitle, setInitialSubtitle] = useState("");
  const [initialImageURL, setInitialImageURL] = useState("");
  
  

  // Get barber home info
  useEffect(() => {
    dispatch(getAsyncBarberHomeInfo());
  }, [dispatch]);
  const barberHomeInfo = useSelector(
    (state) => state.BarberHome.barberHomeInfo
  );

  // Convert image to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.split("base64,")[1]);
      };
      reader.onerror = (error) => {
        console.error("Error converting file to base64!", error);
        reject(error);
      };
    });
  };

  // Update state when the data is fetched
  useEffect(() => {
    if (barberHomeInfo) {
      setTitle(barberHomeInfo.title);
      setSubtitle(barberHomeInfo.subTitle);
      setImageURL(barberHomeInfo.url);
    }
  }, [barberHomeInfo]);

  const handlePostData = async () => {
    try {
      setLoading(true);
      let base64Image = "";
      if (imageFile) {
        base64Image = await convertToBase64(imageFile);
      }
      if (
        title === initialTitle &&
        subtitle === initialSubtitle &&
        base64Image === initialImageURL
      ) {
        return;
      }
      const changes = {};
      if (title !== initialTitle) {
        changes.title = title;
      }
      if (subtitle !== initialSubtitle) {
        changes.subTitle = subtitle;
      }
      if (base64Image !== "") {
        changes.image = base64Image;
      }
      if (Object.keys(changes).length === 0) {
        return;
      }
      console.log("Changes:", changes)
      // Dispatch API call with the changed data
      await dispatch(putAsyncBarberHomeInfo(changes))
        .then((updatedData) => {
          if (updatedData) {
            setTitle(updatedData.title || title);
            setSubtitle(updatedData.subTitle || subtitle);
            setImageURL(updatedData.url || imageURL);
          }

          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your changes have been saved!",
          });
        })
        .catch((error) => {
          console.error("Error posting data:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while saving your changes!",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error posting data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving your changes!",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <h5>Home</h5>
          <p className="text-muted">
            Your location name abss is displayed across many areas including on
            your online pages and messages to client
          </p>
        </div>
        <div className="col-md-9">
          <h5>Title</h5>
          <input
            value={title}
            placeholder="Add a title"
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            className="form-control"
          />
          <h5 className="mt-3">Sub title</h5>
          <input
            value={subtitle}
            placeholder="Add a sub title"
            onChange={(e) => setSubtitle(e.target.value)}
            type="text"
            className="form-control"
          />
          <h5 className="mt-3">Upload a photo</h5>
          <div className="d-flex align-items-center">
            <img
              // src={barberHomeInfo?.url}
              src={imageURL}
              alt="Barbershop"
              className="mr-2 border rounded-1 p-2 me-4"
              style={{ height: "100px", width: "100px" }}
            />
            <input
              value={image}
              type="file"
              onChange={(e) => {
                setImageFile(e.target.files[0]);
                setImage(e.target.value);
              }}
              className="form-control"
            />
          </div>
          <button
            onClick={() => {
              Swal.fire({
                position: "top-end",
                title: "Wait a moment, please",
                showConfirmButton: false,
                icon: "info",
                timer: 2000,
                toast: true,
              });
              handlePostData();
            }}
            className="btn btn-outline-primary rounded-5 mt-4"
          >
            {loading === true ? (
              <div className="d-flex align-items-center justify-content-center ">
                <span className="me-2">Initiated</span>
                <Spinner animation="border" size="sm" />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      {/* Home */}
      <Card.Footer className="mt-3" />
    </div>
  );
};

export default GeneralHome;
