import { get, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

// Get available dates for reservation
export async function getAvailableTime({ date, employeeId }) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/reservations/available-time/${date}/employees/${employeeId}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed", error);
  }
}

// post waitlist
export async function addNewWaitlist({ employeeId, serviceId, data }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/waitlists/employees/${employeeId}/services/${serviceId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      position: "top-end",
      title: "Item added successfully",
      showConfirmButton: false,
      icon: "success",
      timer: 2000,
      toast: true,
    });
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// post waitlist
export async function addNewReservation({ employeeId, serviceId, data }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/reservations/employees/${employeeId}/services/${serviceId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      position: "top-end",
      title: "Item added successfully",
      showConfirmButton: false,
      icon: "success",
      timer: 2000,
      toast: true,
    });
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error.message);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// cancel waitlist
export async function deleteWaitlist(waitlistId) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/waitlists/${waitlistId}/cancel`,
    });
    const response = await restOperation.response;
    await Swal.fire({
      position: "top-end",
      title: "Item deleted successfully",
      showConfirmButton: false,
      icon: "success",
      timer: 2000,
      toast: true,
    });
    return response.body.json();
  } catch (error) {
    console.log("GET call failed", error);
  }
}

// cancel waitlist
export async function deleteReservation(reservationId) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/reservations/${reservationId}/cancel`,
    });
    const response = await restOperation.response;
    await Swal.fire({
      position: "top-end",
      title: "Item deleted successfully",
      showConfirmButton: false,
      icon: "success",
      timer: 2000,
      toast: true,
    });
    return response.body.json();
  } catch (error) {
    console.log("GET call failed", error);
  }
}
