import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCategories,
  getCategories,
  updateCategory,
  deleteCategory,
  getCategoryById,
} from "./categoryAPI";

// Async thunk for fetching all categories
export const fetchAsyncAllCategory = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const categories = await getCategories();
    return categories;
  }
);

// Async thunk for fetching a category by id
export const fetchAsyncCategoryById = createAsyncThunk(
  "categories/fetchCategoryById",
  async (categoryId) => {
    const category = await getCategoryById(categoryId);
    return category;
  }
);

// Async thunk for creating a category
export const createAsyncCategory = createAsyncThunk(
  "categories/createCategory",
  async (data) => {
    console.log(data);
    const response = await createCategories(data);
    return response;
  }
);

// Async thunk for updating a category
export const updateAsyncCategory = createAsyncThunk(
  "categories/updateCategory",
  async (data) => {
    console.log(data);
    const response = await updateCategory(data);
    return response;
  }
);

// Async thunk for deleting a category
export const deleteAsyncCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (categoryId) => {
    const response = await deleteCategory(categoryId);
    return response;
  }
);

// Initial state for the category slice
const initialState = {
  // Data
  allCategory: [],
  singleCategory: {},
  // Status
  fetchStatus: "idle",
  fetchSingleStatus: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  // Errors
  fetchError: null,
  fetchSingleError: null,
  createError: null,
  updateError: null,
  deleteError: null,
};

// Category slice
const categorySlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle fetchAsyncAllCategory
      .addCase(fetchAsyncAllCategory.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(fetchAsyncAllCategory.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.allCategory = action.payload;
      })
      .addCase(fetchAsyncAllCategory.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Handle fetchAsyncCategoryById
      .addCase(fetchAsyncCategoryById.pending, (state) => {
        state.fetchSingleStatus = "loading";
        state.fetchSingleError = null;
      })
      .addCase(fetchAsyncCategoryById.fulfilled, (state, action) => {
        state.fetchSingleStatus = "succeeded";
        state.singleCategory = action.payload;
      })
      .addCase(fetchAsyncCategoryById.rejected, (state, action) => {
        state.fetchSingleStatus = "failed";
        state.fetchSingleError = action.error.message;
      })
      // Handle createAsyncCategory
      .addCase(createAsyncCategory.pending, (state) => {
        state.createStatus = "loading";
        state.createError = null;
      })
      // .addCase(createAsyncCategory.fulfilled, (state, action) => {
      //   state.allCategory.push(action.payload);
      //   state.createStatus = "succeeded";
      // })
      .addCase(createAsyncCategory.fulfilled, (state, action) => {
        // Check if 'allCategory' array exists in the state
        if (!state.allCategory) {
          state.allCategory = [];
        }
        state.allCategory.push(action.payload);
        state.createStatus = "succeeded";
      })      
      .addCase(createAsyncCategory.rejected, (state, action) => {
        state.createStatus = "failed";
        state.createError = action.error.message;
      })
      // Handle updateAsyncCategory
      .addCase(updateAsyncCategory.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(updateAsyncCategory.fulfilled, (state, action) => {
        const updatedCategory = action.payload;
        if (updatedCategory && updatedCategory.id) {
          state.allCategory = state.allCategory.map((category) =>
            category.id === updatedCategory.id ? updatedCategory : category
          );
        }
      })
      .addCase(updateAsyncCategory.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      })
      // Handle deleteAsyncCategory
      .addCase(deleteAsyncCategory.pending, (state) => {
        state.deleteStatus = "loading";
        state.deleteError = null;
      })
      .addCase(deleteAsyncCategory.fulfilled, (state, action) => {
        const categoryId = action.payload;
        state.allCategory = state.allCategory.filter(
          (category) => category.id !== categoryId
        );
        state.deleteStatus = "succeeded";
      })
      .addCase(deleteAsyncCategory.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.deleteError = action.error.message;
      });
  },
});

export default categorySlice.reducer;
