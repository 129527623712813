import React from "react";

const Loading = ({status}) => {
  return (
    status === "loading" ? (
      <div className="element-center gap-3">
        <div className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) : status === "failed" ? (
      <div className="alert alert-danger text-center" role="alert">
        Failed to fetch data!
      </div>
    ) : null
  );
};

export default Loading;