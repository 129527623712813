import { amplifyConfigs } from "../../config";
import { get, put } from "aws-amplify/api";

// barbershop info get Data
export async function getBarberHomeInfo() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/home",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// barberShop Info post Data
export async function putBarberHomeInfo({ title, subTitle, image }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/home",
      options: { body: { title, subTitle, image } },
    });
    const response = await restOperation.response;
    return response;
  } catch (error) {
    console.log("Put call failed: ", error);
  }
}
