import React from "react";
import Booking from "./Booking/Booking";
import Waitlist from "./Waitlist";
import Serving from "./Serving";
import CompleteToday from "./CompleteToday";

const OverviewTable = ({ tableView }) => {
  return (
    <div>
      <Booking tableView={tableView}></Booking>
      <Waitlist tableView={tableView}></Waitlist>
      <Serving tableView={tableView}></Serving>
      <CompleteToday tableView={tableView}></CompleteToday>
    </div>
  );
};

export default OverviewTable;
