import React, { useState } from "react";
import VisitsNav from "./VisitsNav";
import { useEffect } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import { RxColumns } from "react-icons/rx";
import { fetchUserAttributes } from "aws-amplify/auth";
import EmployeeStatusModal from "./EmployeeStatusModal";
import Loading from "../../components/Loading";

const Visit = () => {
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("card");
  const [selectBtn, setSelectBtn] = useState("overview");
  const [showModal, setShowModal] = useState(false);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    fetchUserAttributes()
      .then((attributes) => {
        const userRole = attributes["custom:role"];
        setRole(userRole);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleActiveBtn = (id) => {
    setSelectBtn(id);
  };

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      setSelectBtn("booking");
    } else {
      setSelectBtn("overview");
    }

    const handleResize = () => {
      const isMobileNow = window.innerWidth <= 768;
      if (isMobileNow) {
        setSelectBtn("booking");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [tableView, setTableView] = useState(false);
  const [cardView, setCardView] = useState(false);
  
  const handleTableView = () => {
    setTableView(true);
    setCardView(false);
    setActive("tableView");
  };

  const handleCardView = () => {
    setCardView(true);
    setTableView(false);
    setActive("card");
  };

  // Function to show the modal
  const handleShowModal = () => {
    setShowModal(true);
  };

  // Function to hide the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="py-3 bg-white">
        <div className="container-fluid pt-0">
          <div className="row mb-2 justify-content-between w-100">
            <div className="col-5 col-lg-8">
              <p className="mb-0 d-none d-md-block">abss</p>
              <p className="mb-0 fw-bold fs-4">Visits</p>
            </div>
          </div>
          <div className="row overflow-scroll">
            <div className="col-10">
              <div className="d-flex">
                <button
                  onClick={() => handleActiveBtn("overview")}
                  className={`me-2 d-none d-md-block ${
                    selectBtn === "overview" ? "visit-btn-active" : "visit-btn"
                  }`}
                >
                  Overview
                </button>
                <button
                  onClick={() => handleActiveBtn("booking")}
                  className={`me-2 ${
                    selectBtn === "booking" ? "visit-btn-active" : "visit-btn"
                  }`}
                >
                  Booking
                </button>
                <button
                  onClick={() => handleActiveBtn("waitlist")}
                  className={`me-2 ${
                    selectBtn === "waitlist" ? "visit-btn-active" : "visit-btn"
                  }`}
                >
                  Waitlist
                </button>
                <button
                  onClick={() => handleActiveBtn("serving")}
                  className={`me-2 ${
                    selectBtn === "serving" ? "visit-btn-active" : "visit-btn"
                  }`}
                >
                  Serving
                </button>
                <button
                  onClick={() => handleActiveBtn("completed")}
                  className={`me-2 ${
                    selectBtn === "completed" ? "visit-btn-active" : "visit-btn"
                  }`}
                >
                  Completed
                </button>
              </div>
            </div>
            <div className="col-2 text-end d-none d-lg-block">
              <TfiMenuAlt
                onClick={handleTableView}
                className={`cursor-pointer ${
                  active === "tableView" ? "active-toggle" : ""
                }`}
              ></TfiMenuAlt>
              <RxColumns
                onClick={handleCardView}
                className={`ms-3 cursor-pointer ${
                  active === "card" ? "active-toggle" : ""
                }`}
              ></RxColumns>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loading status={"loading"} />
      ) : (
        <VisitsNav tableView={tableView} selectBtn={selectBtn}></VisitsNav>
      )}
      {/* Modal component */}
      <EmployeeStatusModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default Visit;
