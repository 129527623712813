import React, { useState } from "react";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import OpeningHours from "../components/ScheduleModals/OpeningHours";
import HoursByDate from "../components/ScheduleModals/HoursByDate";

const Booking = () => {
  const [isSwitchActive, setIsSwitchActive] = useState(false);
  const [showOpeningHours, setShowOpeningHours] = useState(false);
  const [showHoursByDate, setShowHoursByDate] = useState(false);

  return (
    <>
      <Card className="m-3">
        <Card.Header as="h5">Bookings</Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-md-3">
              <h5>Booking times</h5>
              <p className="text-muted">
                Manage which times your clients can schedule bookings and how
                long each booking is.{" "}
              </p>
            </div>
            <div className="col-md-9">
              <h5>Booking slot size</h5>
              <p>
                e.g. 15 min slots will show open slots at 8:00, 8:15, 8:30 etc.
              </p>
              {/* dropdown starts */}
              <Dropdown className="mb-3">
                <Dropdown.Toggle
                  className=" col-md-4"
                  variant="outline-primary"
                  id="dropdown-basic"
                >
                  30 min
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">5 min</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">10min</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">15min</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">20 min</Dropdown.Item>
                  <Dropdown.Item href="#/action-5">25min</Dropdown.Item>
                  <Dropdown.Item href="#/action-6">25 min</Dropdown.Item>
                  <Dropdown.Item href="#/action-7">30 min</Dropdown.Item>
                  <Dropdown.Item href="#/action-8">35 min</Dropdown.Item>
                  <Dropdown.Item href="#/action-9">40 min</Dropdown.Item>
                  <Dropdown.Item href="#/action-10">50 min</Dropdown.Item>
                  <Dropdown.Item href="#/action-11">55 min</Dropdown.Item>
                  <Dropdown.Item href="#/action-12">1 hour</Dropdown.Item>
                  <Dropdown.Item href="#/action-13">
                    1 hour 30 min
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-14">2 hour</Dropdown.Item>
                  <Dropdown.Item href="#/action-15">3 hour</Dropdown.Item>
                  <Dropdown.Item href="#/action-16">4 hour</Dropdown.Item>
                  <Dropdown.Item href="#/action-17">5 hour</Dropdown.Item>
                  <Dropdown.Item href="#/action-18">6 hour</Dropdown.Item>
                  <Dropdown.Item href="#/action-19">7 hour</Dropdown.Item>
                  <Dropdown.Item href="#/action-20">8 hour</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* dropdown ends */}
              <h5>Booking hours</h5>
              <div className="row">
                <div className="col-md-4 col-lg-2 col-4">
                  <p className="m-0">Mon</p>
                  <p className="m-0">Tue</p>
                  <p className="m-0">Web</p>
                  <p className="m-0">Thu</p>
                  <p className="m-0">Fri</p>
                  <p className="m-0">Sat</p>
                  <p className="m-0">Sun</p>
                </div>
                <div className="col-md-8 col-lg-10 col-8">
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                </div>
              </div>
              <Button
                disabled={isSwitchActive}
                onClick={() => setShowOpeningHours(true)}
                variant="primary mt-2 rounded-5"
              >
                Set waitlist hours
              </Button>
              <OpeningHours
                show={showOpeningHours}
                onHide={() => setShowOpeningHours(false)}
              />

              <h5 className="mt-3 mb-2">Specific dates</h5>

              <Button
                disabled={isSwitchActive}
                onClick={() => setShowHoursByDate(true)}
                variant="primary mt-2 rounded-5"
              >
                Set hours by date
              </Button>
              <HoursByDate
                show={showHoursByDate}
                onHide={() => setShowHoursByDate(false)}
              ></HoursByDate>
              <Form className="py-2">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Same as Opening Hours"
                  onChange={() => setIsSwitchActive(!isSwitchActive)}
                />
              </Form>
              <div>
                <h5 className="mt-3">Booking Lead Time</h5>
                <p>
                  How much advanced notice you require before clients book an
                  appointment
                </p>
                <div className="row  g-3">
                  <div className="col-md-6">
                    <input className="form-control rounded-1" type="number" />
                  </div>
                  <div className="col-md-6">
                    <Dropdown className="mb-3">
                      <Dropdown.Toggle
                        className=" col-md-4"
                        variant="outline-primary"
                        id="dropdown-basic"
                      >
                        Minute(s)
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Minute(s)
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Hour(s)</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Day(s)</Dropdown.Item>
                        <Dropdown.Item href="#/action-4">Week(s)</Dropdown.Item>
                        <Dropdown.Item href="#/action-5">
                          Month(s)
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              {/* schedule window */}
              <div>
                <h5 className="mt-3">Scheduling Window</h5>
                <p>
                  How far in advance are clients allowed to book an appointment
                </p>
                <div className="row  g-3">
                  <div className="col-md-6">
                    <input className="form-control rounded-1" type="number" />
                  </div>
                  <div className="col-md-6">
                    <Dropdown className="mb-3">
                      <Dropdown.Toggle
                        className=" col-md-4"
                        variant="outline-primary"
                        id="dropdown-basic"
                      >
                        Minute(s)
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Month(s)
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Hour(s)</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Day(s)</Dropdown.Item>
                        <Dropdown.Item href="#/action-4">Week(s)</Dropdown.Item>
                        <Dropdown.Item href="#/action-5">
                          Month(s)
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Card.Footer className="mt-3" />
        </Card.Body>
      </Card>
    </>
  );
};

export default Booking;
