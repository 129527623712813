import { post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

export async function postCustomerSms(data) {
  console.log(data);
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/waitlists/send-sms",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log("POST call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "SMS sent successfully!",
    });
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}
