import React, { useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import EmployeesTwo from "./EmployeesTwo";
import EmployeesThree from "./EmployeesThree";
import EmployeeSalaryType from "./EmployeeSalaryType";
import EmployeeSalaryPercentage from "./EmployeeSalaryPercentage";

const Employees = () => {
  const [goSteps, setGoSteps] = useState(0);
  const [salaryType, setSalaryType] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [bonusOrSalary, setBonusOrSalary] = useState("");
  const [employeeName, setEmployeeName] = useState("");

  // Employee Salary Type
  const handleSalaryType = (data) => {
    setSalaryType(data);
  };
  const handleEmployeeId = (data) => {
    setEmployeeId(data);
  };

  const handleBonusOrSalary = (data) => {
    setBonusOrSalary(data);
  };
  const handleEmployeeName = (data) => {
    setEmployeeName(data);
  }



  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Employees</h4>
            </div>
            <div className="card-body">
              <div className="form-wizard ">
                <Stepper className="nav-wizard" activeStep={goSteps}>
                  <Step className="nav-link" onClick={() => setGoSteps(0)} />
                  <Step className="nav-link" onClick={() => setGoSteps(1)} />
                  <Step className="nav-link" onClick={() => setGoSteps(2)} />
                </Stepper>
                {goSteps === 0 && (
                  <>
                    <EmployeesTwo
                      handleEmployeeId={handleEmployeeId}
                      handleEmployeeName={handleEmployeeName}
                      handleBonusOrSalary={handleBonusOrSalary}
                      setGoSteps={setGoSteps}
                    />
                  </>
                )}
                {bonusOrSalary === "Bonus" ? (
                  <>
                    {goSteps === 1 && (
                      <>
                        <EmployeesThree employeeName={employeeName} setGoSteps={setGoSteps} />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {goSteps === 1 && (
                      <>
                        <EmployeeSalaryType
                          handleSalaryType={handleSalaryType}
                          setGoSteps={setGoSteps}
                        />
                      </>
                    )}
                    {salaryType === "Fixed" ? (
                      <>
                        {goSteps === 2 && (
                          <>
                            <EmployeesThree employeeName={employeeName} setGoSteps={setGoSteps} />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {goSteps === 2 && (
                          <>
                            <EmployeeSalaryPercentage
                              setGoSteps={setGoSteps}
                              employeeId={employeeId}
                              employeeName={employeeName}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employees;
