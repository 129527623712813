import { SVGICON } from "../../constant/theme";

export const MenuList = [
  {
    title: "Dashboard",
    to: "/",
    iconStyle: SVGICON.Home,
  },
  {
    title: "Visit",
    iconStyle: SVGICON.Visit,
    to: "visit-page",
  },
  {
    title: "Expense Tracker",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Overview,
    content: [
      {
        title: "Discovery",
        to: "discovery-page",
      },
      {
        title: "Expense History",
        to: "/expense-history",
      },
    ],
  },
  {
    title: "Inventory",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Inventory,
    content: [
      {
        title: "Inventory",
        to: "/inventory-card",
      },
      {
        title: "Inventory-history",
        to: "/inventory-history",
      },
      {
        title: "Inventory-User",
        to: "/inventory-user",
      },
    ],
  },
  {
    title: "Employee",
    iconStyle: SVGICON.Barber,
    to: "employees",
  },
  {
    title: "Category & Services",
    iconStyle: SVGICON.Category,
    to: "category-page",
  },
  {
    title: "Gallery",
    iconStyle: SVGICON.Gallery,
    to: "gallery-page",
  },
  {
    title: "Trending Styles",
    iconStyle: SVGICON.Trending,
    to: "trending-page",
  },
  {
    title: "Settings",
    iconStyle: SVGICON.General,
    to: "general-page",
  },
  {
    title: "History",
    iconStyle: SVGICON.History,
    to: "history-page",
  },
  {
    title: "Customers",
    iconStyle: SVGICON.Customer,
    to: "customers-page",
  },
];

export const MenuList2 = [
  {
    title: "Visit",
    iconStyle: SVGICON.Visit,
    to: "visit-page",
  },
  {
    title: "Inventory-User",
    iconStyle: SVGICON.Inventory,
    to: "inventory-user",
  },
];
