import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAsyncSentAllMessage, postAsyncComposeMessage, postAsyncReplyMessage } from "./messagesAPI";


// Get All Sent messages
export const fetchAsyncSentMessages = createAsyncThunk(
  "messages/fetchAsyncSentMessages",
  async (pages) => {
    const messages = await getAsyncSentAllMessage(pages);
    return messages;
  }
);

// Post Compose message
export const fetchAsyncComposeMessage = createAsyncThunk(
  "messages/fetchAsyncComposeMessage",
  async (data) => {
    const response = await postAsyncComposeMessage(data);
    return response;
  }
);

// Sent Reply message
export const fetchAsyncReplyMessage = createAsyncThunk(
  "messages/fetchAsyncReplyMessage",
  async (data) => {
    console.log(data)
    const response = await postAsyncReplyMessage(data);
    return response;
  }
);

const initialState = {
  // Get all Sent  messages
  sentMessages: {},
  sentMessageStatus: "idle",
  sentMessageError: null,
  // Compose message
  composeMessage: {},
  composeMessageStatus: "idle",
  composeMessageError: null,

};

const composeSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get All Sent messages
      .addCase(fetchAsyncSentMessages.pending, (state) => {
        state.sentMessageStatus = "loading";
      })
      .addCase(fetchAsyncSentMessages.fulfilled, (state, action) => {
        state.sentMessages = action.payload;
        state.sentMessageStatus = "idle";
      })
      .addCase(fetchAsyncSentMessages.rejected, (state, action) => {
        state.sentMessageStatus = "failed";
        state.sentMessageError = action.error.message;
      })
      // Compose message
      .addCase(fetchAsyncComposeMessage.pending, (state) => {
        state.composeMessageStatus = "loading";
      })
      .addCase(fetchAsyncComposeMessage.fulfilled, (state, action) => {
        state.composeMessage = action.payload;
        state.composeMessageStatus = "idle";
      })
      .addCase(fetchAsyncComposeMessage.rejected, (state, action) => {
        state.composeMessageStatus = "failed";
        state.composeMessageError = action.error.message;
      })
      
      
  },
});


export default composeSlice.reducer;