import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import { Amplify } from "aws-amplify";
import Router from "./jsx/Router";
import "./css/custom.css";
import { amplifyConfigs } from "./config";


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: amplifyConfigs.userPoolId,
      userPoolClientId: amplifyConfigs.userPoolClientId,
      identityPoolId: amplifyConfigs.identityPoolId,
    },
  },
  API: {
    REST: {
      [amplifyConfigs.apiName]: {
        endpoint: amplifyConfigs.apiURL,
        region: amplifyConfigs.apiRegion,
      },
    },
    GraphQL: {
      endpoint: amplifyConfigs.AppSync.GraphQL.endpoint,
      region: amplifyConfigs.AppSync.GraphQL.region,
      defaultAuthMode: amplifyConfigs.AppSync.GraphQL.defaultAuthMode,
      apiKey: amplifyConfigs.AppSync.GraphQL.apiKey,
    },
  },
});




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeContext>
        <RouterProvider router={Router}></RouterProvider>
      </ThemeContext>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
