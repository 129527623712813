import React, { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { MdLock } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import { IoMdHourglass } from "react-icons/io";
import { Dropdown } from "react-bootstrap";
import TabViewCard from "./TabViewCard/TabViewCard";
import Card from "./Card";
import { useDispatch } from "react-redux";
import {
  fetchTodaysWaitlist,
  updateAsyncWaitlistStatus,
} from "../../../store/waitlist/waitlistSlice";
import { useSelector } from "react-redux";
import WaitListOffCanvas from "./WaitListOffCanvas";
import { fetchUserRole } from "../../../store/userInfo/userInfoSlice";

const Waitlist = ({ tableView }) => {
  const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
  const dispatch = useDispatch();
  const [filteredWaitlist, setFilteredWaitlist] = useState([]);

  // Get todays waitlist
  useEffect(() => {
    dispatch(fetchTodaysWaitlist());
    dispatch(fetchUserRole());
  }, [dispatch]);
  const allWaitlist = useSelector((state) => state.Waitlist.todaysWaitlist);

  const userData = useSelector((state) => state.Employee.userProfile);
  const data = useSelector((state) => state.User.role);
  console.log(data);

  useEffect(() => {
    // Check if the logged-in user is Admin
    if (data === "admin" || data === "supervisor") {
      // If Admin, return all waitlist items

      setFilteredWaitlist(allWaitlist);
    } else {
      // If not Admin, filter the waitlist by the logged-in employee's name
      const filter = allWaitlist.filter(
        (waitlistItem) => waitlistItem.employee.name === userData.name
      );
      setFilteredWaitlist(filter);
    }
  }, [data, allWaitlist]);

  // Update waitlist status
  const handleStatus = async (waitlistId) => {
    await dispatch(
      updateAsyncWaitlistStatus({
        waitlistId: waitlistId,
        data: { status: "Serving" },
      })
    );
  };

  // Offcanvas for card details
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const handleCardClick = (id) => {
    setShowOffCanvas(true);
  };

  const handleCloseOffCanvas = () => {
    setShowOffCanvas(false);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-12">
          <div className="bg-white rounded-3 p-3">
            <div className="row justify-content-between align-items-center">
              <div className="col-7 col-lg-6">
                <h4 className="mb-0">Waitlist</h4>
                <p className="mb-0">{allWaitlist?.length} Client</p>
              </div>
              <div className="col-5 col-lg-6 text-end">
                <Dropdown className="dropdown d-inline-block">
                  <Dropdown.Toggle
                    variant=""
                    className=" i-false p-0 sharp border-0 "
                  >
                    <svg
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <circle fill="#000000" cx="5" cy="12" r="2" />
                        <circle fill="#000000" cx="12" cy="12" r="2" />
                        <circle fill="#000000" cx="19" cy="12" r="2" />
                      </g>
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item className="dropdown-item" to="/widget-basic">
                      <MdLock></MdLock> Close Registration
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item" to="/widget-basic">
                      <FiExternalLink></FiExternalLink> Go to Registration
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item" to="/widget-basic">
                      <IoMdHourglass></IoMdHourglass> Waitlist Overview
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <BsFillPlusCircleFill
                  className="cursor-pointer text-primary display-6 ms-2"
                  onClick={() => setShowOffCanvas(true)}
                ></BsFillPlusCircleFill>
              </div>
            </div>
          </div>
        </div>
        <WaitListOffCanvas
          show={showOffCanvas}
          handleClose={handleCloseOffCanvas}
        />
        {isTablet || tableView ? (
          <>
            <TabViewCard name={"waitlist"} items={allWaitlist}></TabViewCard>
          </>
        ) : (
          <>
            {filteredWaitlist?.map((item) => (
              <Card
                item={item}
                name={"waitlist"}
                handleCardClick={handleCardClick}
                handleStatus={handleStatus}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Waitlist;
