import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBarberSocialInfo, putBarberSocialInfo } from "./barberSocialAPI";

export const getAsyncBarberSocialInfo = createAsyncThunk(
  "barber-social/getInfo",
  async () => {
    const response = await getBarberSocialInfo();
    return response;
  }
);

export const putAsyncBarberSocialInfo = createAsyncThunk(
  "barber-social/postInfo",
  async (data) => {
    const response = await putBarberSocialInfo(data);
    console.log("response", response);
    return response;
  }
);

const initialState = {
  barberSocial: {},
  // Status
  fetchStatus: "idle",
  updateStatus: "idle",
  // Errors
  fetchError: null,
  updateError: null,
};

const barberSocialSlice = createSlice({
  name: "barber-social",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get barber Social info
      .addCase(getAsyncBarberSocialInfo.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncBarberSocialInfo.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.barberSocial = action.payload;
      })
      .addCase(getAsyncBarberSocialInfo.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Put barber Social info
      .addCase(putAsyncBarberSocialInfo.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(putAsyncBarberSocialInfo.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.barberSocial = action.payload;
      })
      .addCase(putAsyncBarberSocialInfo.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      });
  },
});

export default barberSocialSlice.reducer;
