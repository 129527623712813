import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { fetchAsyncProduct } from "../../../../store/inventory/inventorySlice";
import { useSelector } from "react-redux";
import { addProductToExpense } from "../../../../store/expenseTracker/ExpenseSlice";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

const formatOptionLabel = ({ name, url, stock, price }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <img
      src={url}
      alt={name}
      style={{ width: "200px", marginRight: "10px", borderRadius: "5px" }}
    />
    <div className="mx-auto">
      <p>Product Name: {name}</p>
      <p>Current Stock: {stock}</p>
      <p>Price: ${price}</p>
    </div>
  </div>
);

const ProductTracker = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  // const now = new Date();
  // const date = now.toISOString().substring(0, 10);
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");
  // const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("selectedOption", selectedOption);

  useEffect(() => {
    dispatch(fetchAsyncProduct());
  }, [dispatch]);

  const inventoryData = useSelector((state) => state.Inventory.inventory);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const formData = {
        quantity,
        amount,
        expenseDate: date,
      };
      await dispatch(
        addProductToExpense({ data: formData, productId: selectedOption.id })
      );
      Swal.fire({
        icon: "success",
        title: "Added the product",
        showConfirmButton: false,
        timer: 1500,
      });

    } catch (error) {
      console.error("Error occurred while adding product:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Products</h4>
            </div>
            <div className="card-body">
              <label htmlFor="amount" /> Select a Product
              <Select
                className="my-3"
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={inventoryData}
                formatOptionLabel={formatOptionLabel}
              />
              <div className="row">
                <div className="col-12 col-lg-6">
                  <label htmlFor="title" /> Quantity
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <label htmlFor="amount" /> Amount
                  <input
                    type="text"
                    className="form-control"
                    id="amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                {/* <div className="col-12 mt-4">
                  <label htmlFor="description">Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div> */}
                <div className="col-12 mt-4">
                  <label htmlFor="date">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    defaultValue={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
                {/* <div className="col-12 col-lg-6 mt-4">
                  <label htmlFor="time">Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="time"
                    defaultValue={time}
                  />
                </div> */}

                <div className="text-end mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleSubmit();
                      Swal.fire({
                        position: "top-end",
                        title: "Wait a moment, please",
                        showConfirmButton: false,
                        icon: "info",
                        timer: 2000,
                        toast: true,
                      });
                    }}
                  >
                    {loading === true ? (
                      <div className="d-flex align-items-center justify-content-center ">
                        <span className="me-2">Initiated</span>
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTracker;
