import React, { useState } from "react";
import TemplateModal from "./TemplateModal";
import SmsCounter from "sms-counter";

const TemplateMessage = () => {
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState("");
  const [smsInfo, setSmsInfo] = useState(null);

  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleOptionSelect = (option) => {
    console.log("Selected option:", option);
  };

  // Handle  SMS count
  const handleContentChange = (event) => {
    const newContent = event.target.value;
    setContent(newContent);

    const smsCount = SmsCounter.count(newContent, true);
    setSmsInfo(smsCount);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <h5>Template Message</h5>
          <p className="text-muted">
            your online pages and messages to clients.
          </p>
        </div>
        <div className="col-md-9">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h5>Title</h5>
            <button
              className="btn btn-primary rounded-5"
              onClick={handleEditClick}
            >
              Edit
            </button>
          </div>
          <input
            placeholder="add your title here..."
            type="text"
            className="form-control"
          />
          <h5 className="mt-3">Content</h5>
          <textarea
            value={content}
            onChange={handleContentChange}
            placeholder="write your content..."
            type="text"
            className="form-control"
          />
          {smsInfo && (
            <small className="form-text mt-2 ms-1">
              Total character: {smsInfo.length}
            </small>
          )}
        </div>
      </div>
      <TemplateModal
        show={showModal}
        onHide={handleModalClose}
        onOptionSelect={handleOptionSelect}
      />
    </>
  );
};

export default TemplateMessage;
