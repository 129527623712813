import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateContact, getContact } from "./contactAPI";

// Get All Data from Contact
export const getAsyncContact = createAsyncThunk(
  "contact/fetchContact",
  async () => {
    const response = await getContact();
    return response;
  }
);


// put Data from Contact
export const updateAsyncContact = createAsyncThunk(
  "contact/createContact",
  async (data) => {
    const response = await updateContact(data);
    return response;
  }
);

const initialState = {
  contactDetails: {},
  fetchStatus: "loading",
  createStatus: "loading",
  error: null,
};

const contactSlice = createSlice({
  name: "Contact",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAsyncContact.pending, (state) => {
        state.fetchStatus = "loading";
      })
      .addCase(getAsyncContact.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.contactDetails = action.payload;
      })
      .addCase(getAsyncContact.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updateAsyncContact.pending, (state) => {
        state.createStatus = "loading";
      })
      .addCase(updateAsyncContact.fulfilled, (state, action) => {
        state.createStatus = "succeeded";
        state.contactDetails = action.payload;
      })
      .addCase(updateAsyncContact.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default contactSlice.reducer;
