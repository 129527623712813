import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBarberContactInfo, putBarberContactInfo } from "./barberContactAPI";

// Get barber About info
export const getAsyncBarberContactInfo = createAsyncThunk(
  "barber-Contact/getInfo",
  async () => {
    const response = await getBarberContactInfo();
    return response;
  }
);

// Put barber About info
export const putAsyncBarberContactInfo = createAsyncThunk(
  "barber-Contact/postInfo",
  async (data) => {
    const response = await putBarberContactInfo(data);
    return response;
  }
);

const initialState = {
  // Data
  barberContact: {},
  // Status
  fetchStatus: "idle",
  updateStatus: "idle",
  // Errors
  fetchError: null,
  updateError: null,
};

const barberContactSlice = createSlice({
  name: "barber-home",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get barber About info
      .addCase(getAsyncBarberContactInfo.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncBarberContactInfo.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.barberContact = action.payload;
      })
      .addCase(getAsyncBarberContactInfo.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Put barber About info
      .addCase(putAsyncBarberContactInfo.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(putAsyncBarberContactInfo.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.barberContact = action.payload;
      })
      .addCase(putAsyncBarberContactInfo.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      });
  },
});

export default barberContactSlice.reducer;
