/// Menu
import React, { useContext, useEffect, useReducer, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { useLocation } from 'react-router-dom';


/// Link
import { Link } from "react-router-dom";

import { MenuList, MenuList2 } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { fetchUserAttributes } from "aws-amplify/auth";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  useEffect(() => {}, []);
  //  For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const [heartBeat, setHeartBeat] = useState();

  function heartBlast() {
    setHeartBeat(!heartBeat);
  }

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserAttributes()
      .then((attributes) => {
        const userRole = attributes["custom:role"];
        setRole(userRole);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  // ForAction Menu
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];


  const location = useLocation();
  useEffect(() => {
    // Function to check if a menu item is active
    const isMenuItemActive = (menuItem) => {
      if (menuItem.to) {
        return location.pathname.includes(menuItem.to);
      }
      return false;
    };

    if (role === "admin") {
      MenuList.forEach((data) => {
        // Check if the current main menu item is active
        if (isMenuItemActive(data)) {
          setState({ active: data.title });
        }

        data.content?.forEach((item) => {
          // Check if the current submenu item is active
          if (isMenuItemActive(item)) {
            setState({ activeSubmenu: item.title, active: data.title });
          }

          item.content?.forEach((ele) => {
            // Check if the current sub-submenu item is active
            if (isMenuItemActive(ele)) {
              setState({ activeSubmenu: item.title, active: data.title });
            }
          });
        });
      });
    } else if (role === "employee") {
      MenuList2.forEach((data) => {
        // Check if the current menu item is active
        if (isMenuItemActive(data)) {
          setState({ active: data.title });
        }

        data.content?.forEach((item) => {
          // Check if the current submenu item is active
          if (isMenuItemActive(item)) {
            setState({ activeSubmenu: item.title, active: data.title });
          }

          item.content?.forEach((ele) => {
            // Check if the current sub-submenu item is active
            if (isMenuItemActive(ele)) {
              setState({ activeSubmenu: item.title, active: data.title });
            }
          });
        });
      });
    }
  }, [location.pathname, role]);

  if (loading) {
    return <div></div>;
  }

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`deznav border-right zIndex ${iconHover} 
      ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <div className="deznav-scroll d-flex flex-column justify-content-between">
        <ul className="metismenu" id="menu">
          {role === "admin"
            ? MenuList.map((data, index) => {
                let menuClass = data.classsChange;
                if (menuClass === "menu-title") {
                  return (
                    <li className={menuClass} key={index}>
                      {data.title}
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={` ${
                        state.active === data.title ? "mm-active" : ""
                      }`}
                      key={index}
                    >
                      {data.content && data.content.length > 0 ? (
                        <>
                          <Link
                            to={"#"}
                            className="has-arrow"
                            onClick={() => {
                              handleMenuActive(data.title);
                            }}
                          >
                            <div className="menu-icon">{data.iconStyle}</div>{" "}
                            <span className="nav-text">
                              {data.title}
                              {data.update && data.update.length > 0 ? (
                                <span className="badge badge-xs badge-danger ms-2">
                                  {data.update}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </Link>
                          <Collapse
                            in={state.active === data.title ? true : false}
                          >
                            <ul
                              className={`${
                                menuClass === "mm-collapse" ? "mm-show" : ""
                              }`}
                            >
                              {data.content &&
                                data.content.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={`${
                                        state.activeSubmenu === data.title
                                          ? "mm-active"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                      data.content.length > 0 ? (
                                        <>
                                          <Link
                                            to={data.to}
                                            className={
                                              data.hasMenu ? "has-arrow" : ""
                                            }
                                            onClick={() => {
                                              handleSubmenuActive(data.title);
                                            }}
                                          >
                                            {data.title}
                                          </Link>
                                          <Collapse
                                            in={
                                              state.activeSubmenu === data.title
                                                ? true
                                                : false
                                            }
                                          >
                                            <ul
                                              className={`${
                                                menuClass === "mm-collapse"
                                                  ? "mm-show"
                                                  : ""
                                              }`}
                                            >
                                              {data.content &&
                                                data.content.map(
                                                  (data, ind) => {
                                                    return (
                                                      <li key={ind}>
                                                        <Link
                                                          className={`${
                                                            path === data.to
                                                              ? "mm-active"
                                                              : ""
                                                          }`}
                                                          to={data.to}
                                                        >
                                                          {data.title}
                                                        </Link>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                            </ul>
                                          </Collapse>
                                        </>
                                      ) : (
                                        <Link
                                          to={data.to}
                                          className={`${
                                            data.to === path ? "mm-active" : ""
                                          }`}
                                        >
                                          {data.title}
                                        </Link>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                          </Collapse>
                        </>
                      ) : (
                        <Link to={data.to}>
                          <div className="menu-icon">{data.iconStyle}</div>{" "}
                          <span className="nav-text">{data.title}</span>
                          {data.update && data.update.length > 0 ? (
                            <span className="badge badge-xs badge-danger ms-2">
                              {data.update}
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      )}
                    </li>
                  );
                }
              })
            : MenuList2.map((data, index) => {
                let menuClass = data.classsChange;
                if (menuClass === "menu-title") {
                  return (
                    <li className={menuClass} key={index}>
                      {data.title}
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={` ${
                        state.active === data.title ? "mm-active" : ""
                      }`}
                      key={index}
                    >
                      {data.content && data.content.length > 0 ? (
                        <>
                          <Link
                            to={"#"}
                            className="has-arrow"
                            onClick={() => {
                              handleMenuActive(data.title);
                            }}
                          >
                            <div className="menu-icon">{data.iconStyle}</div>{" "}
                            <span className="nav-text">
                              {data.title}
                              {data.update && data.update.length > 0 ? (
                                <span className="badge badge-xs badge-danger ms-2">
                                  {data.update}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </Link>
                          <Collapse
                            in={state.active === data.title ? true : false}
                          >
                            <ul
                              className={`${
                                menuClass === "mm-collapse" ? "mm-show" : ""
                              }`}
                            >
                              {data.content &&
                                data.content.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={`${
                                        state.activeSubmenu === data.title
                                          ? "mm-active"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                      data.content.length > 0 ? (
                                        <>
                                          <Link
                                            to={data.to}
                                            className={
                                              data.hasMenu ? "has-arrow" : ""
                                            }
                                            onClick={() => {
                                              handleSubmenuActive(data.title);
                                            }}
                                          >
                                            {data.title}
                                          </Link>
                                          <Collapse
                                            in={
                                              state.activeSubmenu === data.title
                                                ? true
                                                : false
                                            }
                                          >
                                            <ul
                                              className={`${
                                                menuClass === "mm-collapse"
                                                  ? "mm-show"
                                                  : ""
                                              }`}
                                            >
                                              {data.content &&
                                                data.content.map(
                                                  (data, ind) => {
                                                    return (
                                                      <li key={ind}>
                                                        <Link
                                                          className={`${
                                                            path === data.to
                                                              ? "mm-active"
                                                              : ""
                                                          }`}
                                                          to={data.to}
                                                        >
                                                          {data.title}
                                                        </Link>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                            </ul>
                                          </Collapse>
                                        </>
                                      ) : (
                                        <Link
                                          to={data.to}
                                          className={`${
                                            data.to === path ? "mm-active" : ""
                                          }`}
                                        >
                                          {data.title}
                                        </Link>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                          </Collapse>
                        </>
                      ) : (
                        <Link to={data.to}>
                          <div className="menu-icon">{data.iconStyle}</div>{" "}
                          <span className="nav-text">{data.title}</span>
                          {data.update && data.update.length > 0 ? (
                            <span className="badge badge-xs badge-danger ms-2">
                              {data.update}
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      )}
                    </li>
                  );
                }
              })}
        </ul>
        <div className="copyright ">
          <p className="fs-14">
            <strong>ABSS Barbershop Admin</strong> © 2024 All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
