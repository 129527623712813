import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBarberHomeInfo, putBarberHomeInfo } from "./barberHomeAPI";

// Get barber home info
export const getAsyncBarberHomeInfo = createAsyncThunk(
  "barber-home/getInfo",
  async () => {
    const response = await getBarberHomeInfo();
    return response;
  }
);

// Put barber home info
export const putAsyncBarberHomeInfo = createAsyncThunk(
  "barber-home/postInfo",
  async (data) => {
    const response = await putBarberHomeInfo(data);
    return response;
  }
);

const initialState = {
  // Data
  barberHomeInfo: {},
  // Status
  fetchStatus: "idle",
  updateStatus: "idle",
  // Errors
  fetchError: null,
  updateError: null,
};

const barberHomeSlice = createSlice({
  name: "barber-home",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get barber home info
      .addCase(getAsyncBarberHomeInfo.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncBarberHomeInfo.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.barberHomeInfo = action.payload;
      })
      .addCase(getAsyncBarberHomeInfo.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Put barber home info
      .addCase(putAsyncBarberHomeInfo.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(putAsyncBarberHomeInfo.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.barberHomeInfo = action.payload;
      })
      .addCase(putAsyncBarberHomeInfo.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      });
  },
});

export default barberHomeSlice.reducer;
