import { get, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

// Create map link
export async function createMapLink(data) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/map-links",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    response.body.json();
    console.log("POST call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Map link created successfully!",
    });
    return response;
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Get all map links
export async function getMapLinks() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/map-links",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// Update map link
export async function updateMapLink(data) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/map-links",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    response.body.json();
    console.log("POST call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Map link updated successfully!",
    });
    return response;
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}