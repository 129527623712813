import { get, post, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

// Create employee Special working hours
export async function createEmployeeSpecialHours({ data, employeeId }) {
  console.log(data);
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      // path: `/employees/${employeeId}/workinghours`,
      path: `/employees/${employeeId}/special-workinghours`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log("POST call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Working hours added successfully!",
    });
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Get employee Special working hours
export async function getEmployeeSpecialHours(employeeId) {
  // console.log(employeeId);
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${employeeId}/special-workinghours`,
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response.body.json());
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// Update employee Special working hours
export async function UpdateEmployeeSpecialHours({data, specialWorkingHoursId, employeeId}) {
  console.log(data);
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${employeeId}/special-workinghours/${specialWorkingHoursId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log("POST call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Working hours updated successfully!",
    });
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}