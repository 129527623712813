import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setExpenseCategory } from "../../store/expenseTracker/ExpenseCategory";
import {
  fetchAsyncGetExpense,
  fetchAsyncGetTotalExpense,
} from "../../store/discovery/discoverySlice";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import OrderSummary from "../components/Dashboard/Home/OrderSummary";

const Discovery = () => {
  const dispatch = useDispatch();
  // Get All Expense
  useEffect(() => {
    dispatch(fetchAsyncGetExpense());
    dispatch(fetchAsyncGetTotalExpense());
  }, [dispatch]);
  const data = useSelector((state) => state.Discovery.expense);
  const expenseStatus = useSelector((state) => state.Discovery.expenseStatus);
  const totalExpense = useSelector((state) => state.Discovery.totalExpense);
  // const totalExpenseStatus = useSelector(
  //   (state) => state.Discovery.totalExpenseStatus
  // );

  console.log(data)

  let options = {};
  let series = [];

  if (totalExpense && totalExpense.ExpenseTotals) {
    options = {
      labels: Object.keys(totalExpense.ExpenseTotals),
      colors: ["#FF0000", "#00FF00", "#0000FF", "#8C90FC"],
    };
    series = Object.values(totalExpense.ExpenseTotals);
  }
  // Define your colors
  // const colors = ["#FF0000", "#00FF00", "#0000FF", "#8C90FC"];

  const colors = [
    "rgba(255, 0, 0, 0.4)",
    "rgba(0, 255, 0, 0.4)",
    "rgba(0, 0, 255, 0.4)",
    "rgba(140, 144, 252, 0.4)",
  ];

  // Map each title to a color
  const titleColorMap =
    totalExpense && totalExpense.ExpenseTotals
      ? Object.keys(totalExpense.ExpenseTotals).reduce((acc, title, index) => {
          acc[title] = colors[index % colors.length];
          return acc;
        }, {})
      : {};
  let total = 0;
  if (totalExpense && totalExpense.ExpenseTotals) {
    options = {
      labels: Object.keys(totalExpense.ExpenseTotals),
    };
    series = Object.values(totalExpense.ExpenseTotals);
    total = series.reduce((a, b) => a + b, 0);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row justify-content-center">
                <OrderSummary />
              </div>
              <div>
                <div className="row align-items-center mt-5">
                  <div className="col-6">
                    <h4 className=" text-lg-end">Recent Expense</h4>
                  </div>
                  <div className="col-6 text-end">
                    <Dropdown>
                      <Dropdown.Toggle variant="primary">
                        Select A Category
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link
                            onClick={() =>
                              dispatch(setExpenseCategory("Employees"))
                            }
                            to="../employees-tracker"
                          >
                            Employee
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link
                            onClick={() =>
                              dispatch(setExpenseCategory("Utility"))
                            }
                            to="../rent-utilities"
                          >
                            Rent Or Utilities
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link
                            onClick={() =>
                              dispatch(setExpenseCategory("Products"))
                            }
                            to="../product-tracker"
                          >
                            Product
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link
                            onClick={() =>
                              dispatch(setExpenseCategory("Other"))
                            }
                            to="../other-expense"
                          >
                            Other Expense{" "}
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="mt-5">
                  {expenseStatus === "loading" ? (
                    <Loading status={expenseStatus} />
                  ) : (
                    <>
                      {data.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            backgroundColor: titleColorMap[item?.expenseType],
                          }}
                          className="card-body my-2 border border-2 border-dark rounded-2 "
                        >
                          <div className="row justify-content-between">
                            <div className="col-6 d-flex">
                              <div className="text-black col-6">
                                {item?.title}
                              </div>
                            </div>
                            <div className="col-6 text-end text-black">
                              ${item?.amount}
                            </div>
                            <hr className="my-3" />
                            <div className="col-6 text-black">
                              {item?.expenseType}
                            </div>
                            <div className="col-6 text-end text-black">
                              {item?.expenseDate}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="text-center">
                  <Link to={"../expense-history"} className="btn btn-primary mt-3">
                    See All Expenses
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discovery;
