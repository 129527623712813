import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { IoIosCloseCircle } from "react-icons/io";
import { FaCirclePlus } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { createAsyncWorkingHours, fetchAsyncWorkingHours } from "../../../store/workingHoursSlice";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const openCloseOptions = [
  { value: "Open", label: "Open" },
  { value: "Close", label: "Close" },
];

const OpeningHours = (props) => {
  const dispatch = useDispatch();
  const workingHours = useSelector((state) => state.WorkingHours.workingHours);
  const status = useSelector((state) => state.WorkingHours.status);
  const [timeSlots, setTimeSlots] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [errors, setErrors] = useState({});

  const isFirstRender = useRef(true);
  useEffect(() => {
    const fetchAndSetWorkingHours = async () => {
      if (isFirstRender.current) {
        await dispatch(fetchAsyncWorkingHours());
        isFirstRender.current = false;
      }
      if (Array.isArray(workingHours)) {
        const initializedState = {};
        const initializedOptions = {};
        daysOfWeek.forEach((day) => {
          const dayEntries = workingHours.filter(
            (entry) => entry.dayOfWeek === day
          );
          initializedState[day] = dayEntries.flatMap(
            (entry) => entry.barberTimeSlots
          );
          initializedOptions[day] =
            dayEntries.length > 0 &&
            dayEntries.flatMap((entry) => entry.barberTimeSlots).length > 0
              ? "Open"
              : "Close";
        });
        setTimeSlots(initializedState);
        setSelectedOptions(initializedOptions);
      }
    };
    fetchAndSetWorkingHours();
  }, [dispatch, workingHours]);

  const [additionalTimes, setAdditionalTimes] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = 0; // Initialize with 0 additional fields for each day
      return acc;
    }, {})
  );

  const handleChange = (selectedOption, day) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [day]: selectedOption.value,
    }));

    // If a day is set to "Close", clear its timeSlots
    if (selectedOption.value === "Close") {
      setTimeSlots((prev) => ({
        ...prev,
        [day]: [],
      }));
    } else {
      // Ensure there's at least one time slot for an "Open" day
      setTimeSlots((prev) => ({
        ...prev,
        [day]: prev[day]?.length ? prev[day] : [{ startTime: "", endTime: "" }],
      }));
    }
  };

  const handleTimeChange = (day, index, field, value) => {
    // Update the time slot first
    const updatedSlots = timeSlots[day].map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );

    // Validate after setting the new value
    validateTimeSlots(
      day,
      index,
      updatedSlots[index].startTime,
      updatedSlots[index].endTime
    );

    // Set the updated slots only if there's no overlap
    if (!errors[day]) {
      setTimeSlots((prev) => ({
        ...prev,
        [day]: updatedSlots,
      }));
    }
  };

  const handleAddTimeSlot = (day) => {
    if (timeSlots[day].length < 3) {
      // Ensure we don't add more than 2 additional slots
      const updatedSlots = [...timeSlots[day], { startTime: "", endTime: "" }];
      setTimeSlots((prev) => ({ ...prev, [day]: updatedSlots }));
    }
  };

  const handleRemoveTimeSlot = (day, index) => {
    setTimeSlots((prev) => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index),
    }));
    setAdditionalTimes((prev) => ({
      ...prev,
      [day]: prev[day] > 0 ? prev[day] - 1 : 0,
    }));
  };

  const validateTimeSlots = (day, index, startTime, endTime) => {
    const slots = [...timeSlots[day]];
    // Temporarily set the new times to check against other slots
    slots[index] = { ...slots[index], startTime, endTime };

    let hasOverlap = false;
    for (let i = 0; i < slots.length; i++) {
      for (let j = 0; j < slots.length; j++) {
        if (i !== j) {
          if (
            (slots[i].startTime < slots[j].endTime &&
              slots[i].startTime >= slots[j].startTime) ||
            (slots[i].endTime > slots[j].startTime &&
              slots[i].endTime <= slots[j].endTime)
          ) {
            hasOverlap = true;
            break;
          }
        }
      }
      if (hasOverlap) break;
    }

    setErrors((prev) => ({ ...prev, [day]: hasOverlap }));
  };

  // Post the updated working hours to the server
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Construct the new payload format
    const payload = daysOfWeek.reduce((acc, day) => {
      // Only include days that are marked as "Open" and have at least one valid time slot
      if (
        selectedOptions[day] === "Open" &&
        timeSlots[day] &&
        timeSlots[day].some((slot) => slot.startTime && slot.endTime)
      ) {
        acc.push({
          dayOfWeek: day,
          timeSlots: timeSlots[day]
            .filter((slot) => slot.startTime && slot.endTime)
            .map((slot) => ({
              startTime: slot.startTime,
              endTime: slot.endTime,
            })),
        });
      }
      return acc;
    }, []);

    // Make sure there's something to send
    if (payload.length > 0) {
      try {
        await dispatch(createAsyncWorkingHours({data: payload}));
        // Notify the user of success, reset form state, close modal, etc.
      } catch (error) {
        // Handle any errors, e.g., show error message
        console.error("Failed to update Barbershop hours", error);
      }
    } else {
      // Optionally handle the case where there's nothing to submit, e.g., show a message
    }
    dispatch(fetchAsyncWorkingHours());
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Working Hours
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {daysOfWeek.map((day, dayIndex) => (
            <Form.Group key={day}>
              <div className="row justify-content-between">
                <div className="col-12 col-md-2">
                  <Form.Label className="mt-4">{day}</Form.Label>
                </div>
                <div className="col-12 col-md-10 g-3">
                  <div className="d-flex gap-2">
                    <Select
                      value={{
                        label: selectedOptions[day] || "Close", // Fallback to "Close" to ensure a value is selected
                        value: selectedOptions[day] || "Close",
                      }}
                      onChange={(selectedOption) =>
                        handleChange(selectedOption, day)
                      }
                      options={openCloseOptions}
                      className="opening-hours-select"
                    />
                    {selectedOptions[day] === "Open" && (
                      <>
                        <div className="d-flex flex-column">
                          <div>
                            {timeSlots[day]?.map((slot, index) => (
                              <div
                                key={index}
                                className="d-flex gap-2 mb-2 align-items-center"
                              >
                                <input
                                  className="working-hours-input"
                                  type="time"
                                  value={slot.startTime}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      day,
                                      index,
                                      "startTime",
                                      e.target.value
                                    )
                                  }
                                />
                                <span>-</span>
                                <input
                                  className="working-hours-input"
                                  type="time"
                                  value={slot.endTime}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      day,
                                      index,
                                      "endTime",
                                      e.target.value
                                    )
                                  }
                                />
                                {timeSlots[day].length > 1 && (
                                  <IoIosCloseCircle
                                    onClick={() =>
                                      handleRemoveTimeSlot(day, index)
                                    }
                                    className="fs-1 ms-2 text-danger cursor-pointer"
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                          {errors[day] && (
                            <div className="text-danger">
                              Time slots cannot overlap.
                            </div>
                          )}
                        </div>
                        {timeSlots[day].length < 3 && ( // Limit to the original + 2 additional slots
                          <FaCirclePlus
                            onClick={() => handleAddTimeSlot(day)}
                            className="fs-2 text-primary cursor-pointer mt-2 d-inline-block"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Form.Group>
          ))}
          <button
            disabled={Object.values(errors).some((error) => error)}
            className="btn btn-primary mt-3"
          >
            {status === "loading" ? (
              <div className="d-flex align-items-center justify-content-center ">
                <span className="me-2">Initiated</span>
                <Spinner animation="border" size="sm" />
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default OpeningHours;
