



import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBarberRevenue } from './revenueAPI';

// get history
export const fetchAsyncBarberRevenueData = createAsyncThunk(
  "revenue/fetchAsyncRevenue",
  async () => {
    const revenue = await getBarberRevenue();
    return revenue;
  }
);


const initialState = {
  barberRevenue: [],
  status: 'idle',
  error: null
};

const revenueSlice = createSlice({
  name: 'barberRevenues',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncBarberRevenueData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAsyncBarberRevenueData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.barberRevenue = action.payload;
      })
      .addCase(fetchAsyncBarberRevenueData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default revenueSlice.reducer;