import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FetchSingleExpense } from "../../../store/expenseTracker/ExpenseSlice";
import { useSelector } from "react-redux";

const ViewExpenseModal = (props) => {
  const singleId = props.selectedId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchSingleExpense(singleId));
  }, [dispatch, singleId]);
  const singleExpense = useSelector((state) => state.Expense.singleExpense);
  const status = useSelector((state) => state.Expense.singleExpenseStatus);
  console.log(singleExpense);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Expense Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === "loading" ? (
          <div>
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            {/* <h4 className="mb-3">Expense Details</h4> */}

            <p className="fs-5">
              <span className="fw-bold">Amount:</span> {singleExpense?.amount}
            </p>
            <p className="fs-5">
              <span className="fw-bold">Date:</span>{" "}
              {singleExpense?.expenseDate}
            </p>
            <p className="fs-5">
              <span className="fw-bold">Title:</span> {singleExpense?.title}
            </p>
            <p className="fs-5">
              <span className="fw-bold">Description:</span>{" "}
              {singleExpense?.description}
            </p>
          </div>
        )}
        {/* <div className="text-center">
          <h4 className="mb-3">Expense Details</h4>

          <p className="fs-5">
            <span className="fw-bold">Amount:</span> {singleExpense?.amount}
          </p>
          <p className="fs-5">
            <span className="fw-bold">Date:</span> {singleExpense?.expenseDate}
          </p>
          <p className="fs-5">
            <span className="fw-bold">Title:</span> {singleExpense?.title}
          </p>
          <p className="fs-5">
            <span className="fw-bold">Description:</span>{" "}
            {singleExpense?.description}
          </p>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default ViewExpenseModal;
