import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  UpdateEmployeeSpecialHours,
  createEmployeeSpecialHours,
  getEmployeeSpecialHours,
} from "./employeeSpecialHoursAPI";

// Add employeeSpecialHours
export const createAsyncEmployeeSpecialHours = createAsyncThunk(
  "employeeSpecialHours/createEmployeeSpecialHours",
  async (data) => {
    console.log(data);
    const response = await createEmployeeSpecialHours(data);
    return response;
  }
);

// Update employeeSpecialHours
export const updateAsyncEmployeeSpecialHours = createAsyncThunk(
  "employeeSpecialHours/updateEmployeeSpecialHours",
  async (data) => {
    console.log(data);
    const response = await UpdateEmployeeSpecialHours(data);
    return response;
  }
);

// Fetch employeeSpecialHours
export const fetchEmployeeSpecialWorkingHours = createAsyncThunk(
  "employeeSpecialHours/fetchEmployeeSpecialHours",
  async (employeeId) => {
    console.log(employeeId);
    const response = await getEmployeeSpecialHours(employeeId);
    return response;
  }
);

const initialState = {
  employeeSpecialWorkingHours: [],
  loading: "idle",
  error: null,
};

const employeeSpecialHoursSlice = createSlice({
  name: "employeeSpecialHours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add employeeSpecialHours
      .addCase(createAsyncEmployeeSpecialHours.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(
        createAsyncEmployeeSpecialHours.fulfilled,
        (state, { payload }) => {
          state.loading = "succeeded";
        }
      )
      .addCase(createAsyncEmployeeSpecialHours.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      // Fetch employeeSpecialHours
      .addCase(fetchEmployeeSpecialWorkingHours.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchEmployeeSpecialWorkingHours.fulfilled, (state, action) => {
        state.employeeSpecialWorkingHours = action.payload;
        state.loading = "succeeded";
      })
      .addCase(fetchEmployeeSpecialWorkingHours.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      // Update employeeSpecialHours
      .addCase(updateAsyncEmployeeSpecialHours.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(
        updateAsyncEmployeeSpecialHours.fulfilled,
        (state, { payload }) => {
          state.employeeSpecialWorkingHours = payload;
          state.loading = "succeeded";
        }
      )
      .addCase(updateAsyncEmployeeSpecialHours.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export default employeeSpecialHoursSlice.reducer;
