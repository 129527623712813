import { get, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

// barbershop social get Data
export async function getBarberSocialInfo() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/social",
    });
    const response = await restOperation.response;
    // console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// barberShop social put Data
export async function putBarberSocialInfo({ facebook, instagram, tiktok }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/social",
      options: { body: { facebook, instagram, tiktok } },
    });
    const response = await restOperation.response.body.json();
    // console.log("Put call succeeded: ", response);
    return response;
  } catch (error) {
    console.log("Put call failed: ", error);
  }
}
