import React, { useState } from "react";
import { Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SmsCounter from "sms-counter";
import Swal from "sweetalert2";
import { fetchAsyncComposeMessage } from "../../../store/messages/composeSlice";
import Select from "react-select";

const options = [
  { value: "1", label: "Phone Number" },
  { value: "2", label: "All Contact" },
];

const ComposeMessage = () => {
  const dispatch = useDispatch();
  // all state
  const [message, setMessage] = useState("");
  const [smsInfo, setSmsInfo] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  // handles for message count
  const handleInputChange = (event) => {
    const newMessage = event.target.value;
    setMessage(newMessage);

    const smsCount = SmsCounter.count(newMessage, true);
    setSmsInfo(smsCount);
  };

  //   handle for validate phone number
  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value;

    const formattedPhoneNumber = inputPhoneNumber.replace(/^\+/, "");

    const lebanesePhoneNumberPattern = /^(961|0)?(0|1|3|4|7|8)\d{7}$/;

    setPhoneNumber(inputPhoneNumber);

    if (lebanesePhoneNumberPattern.test(formattedPhoneNumber)) {
      setValidationMessage("");
      setIsButtonDisabled(false);
    } else {
      setValidationMessage("Enter a valid Lebanese phone number");
      setIsButtonDisabled(true);
    }
  };

  // handle select change
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    setPhoneNumber("");
    setValidationMessage("");

    setIsButtonDisabled(selectedOption.value === "1");
  };

  const handleSubmitData = async () => {
    try {
      if (selectedOption.value === "2") {
        const confirmResult = await Swal.fire({
          title: "Are you sure?",
          text: "This will send the message to all contacts.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, send it!",
        });

        if (!confirmResult.isConfirmed) {
          return;
        }
      }

      const data = {
        phones: [phoneNumber],
        body: message,
      };
      await dispatch(fetchAsyncComposeMessage({ data }));
      
      // Reset the form after sending the message
      setMessage("");
      setPhoneNumber("");
      setValidationMessage("");
      setIsButtonDisabled(true);
      setSmsInfo(null);
      // Show the success toaster
      Swal.fire({
        position: "top-end",
        title: "Message sent successfully",
        showConfirmButton: false,
        icon: "success",
        timer: 2000,
        toast: true,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        title: "Message sent failed",
        showConfirmButton: false,
        icon: "error",
        timer: 2000,
        toast: true,
      });
    }
  };

  return (
    <>
      <Tab.Container defaultActiveKey="Import">
        <Tab.Content>
          <div
            role="toolbar"
            className="toolbar d-flex justify-content-between align-content-center ms-1 ms-sm-0"
          >
            <div className="saprat ps-3">
              <div className="mail-tools ms-0">
                <h5 className="text-primary mb-0 mt-1">Write a new message</h5>
              </div>
            </div>
            <div className="align-content-center mt-2 me-3">
              <Select
                options={options}
                className="custom-react-drop-btn align-content-center"
                defaultValue={options[0]}
                isSearchable={false}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <div className="email-right-box ms-0 mt-2"></div>
          <div className="compose-wrapper" id="compose-content">
            <div className="compose-content">
              <form action="#">
                {selectedOption.value === "1" && (
                  <div className="form-group mb-3">
                    <input
                      type="number"
                      className="form-control bg-transparent"
                      placeholder="Phone number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                    <small className="form-text text-danger">
                      {validationMessage}
                    </small>
                  </div>
                )}
                <div className="form-group mb-3"></div>
                <div className="form-group mb-3">
                  <textarea
                    id="email-compose-editor"
                    className="textarea_editor form-control bg-transparent"
                    rows="5"
                    placeholder="Enter text ..."
                    value={message}
                    onChange={handleInputChange}
                  ></textarea>
                  {smsInfo && (
                    <div className="row justify-content-between">
                      <small className="col form-text">
                        Character Count: {smsInfo.length}
                      </small>
                      <small className="col form-text text-center">
                        Remaining Character: {smsInfo.remaining}
                      </small>
                      <small className="col form-text text-end">
                        SMS Count: {smsInfo.messages}
                      </small>
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className="text-left mt-3 mb-5">
              {selectedOption.value === "2" && (
                <div className="mb-2">
                  {smsInfo && (
                    <small className="col form-text text-end">
                      Total SMS Count for All Contact: {smsInfo.messages * 5}
                    </small>
                  )}
                </div>
              )}
              <button
                className="btn btn-primary btn-sl-sm me-2"
                type="button"
                disabled={isButtonDisabled}
                onClick={() => handleSubmitData()}
              >
                <span className="me-2">
                  <i className="fa fa-paper-plane"></i>
                </span>
                Send
              </button>
              <Link
                to="/inbox-page"
                className="btn btn-danger light btn-sl-sm"
                type="button"
              >
                <span className="me-2">
                  <i className="fa fa-times" />
                </span>
                Discard
              </Link>
            </div>
          </div>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default ComposeMessage;
