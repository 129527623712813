import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CreateAsyncExpense } from "../../../../store/expenseTracker/ExpenseSlice";

const EmployeesThree = ({ employeeName, setGoSteps }) => {
  const now = new Date();
  const date = now.toISOString().substring(0, 10);
  const [amount, setAmount] = useState();
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const category = useSelector((state) => state.ExpenseCategory.category);


  const handleSubmit = () => {
    const data = {
      title: employeeName,
      amount: amount,
      expenseDate: date,
      description: description,
      expenseType: category,
    };
    try {
      dispatch(CreateAsyncExpense(data));
    } catch (error) {
      console.log(error.message);
    }
    navigate("/discovery-page");
    console.log(data);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 ">
          <label htmlFor="title" /> Amount
          <input
            onChange={(e) => setAmount((e.target.value))}
            type="number"
            className="form-control"
            id="title"
          />
        </div>
        <div className="col-12 mt-4">
          <label htmlFor="description">Description</label>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            className="form-control"
            id="description"
          />
        </div>
        {/* <div className="col-12 col-lg-6 mt-4">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            className="form-control"
            id="date"
            defaultValue={date}
          />
        </div> */}
      </div>
      <div className="text-end toolbar toolbar-bottom p-2 mt-3">
        <button
          className="btn btn-secondary sw-btn-prev me-1"
          onClick={() => setGoSteps(0)}
        >
          Prev
        </button>
        <button
          className="btn btn-primary sw-btn-next ms-1"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default EmployeesThree;
