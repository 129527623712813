import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import Select from "react-select";
import {
  fetchAllInventoryHistory,
  fetchInventoryHistoryOfIn,
  fetchInventoryHistoryOfOut,
} from "../../../store/inventory/inventoryHistorySlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BsTypeH1 } from "react-icons/bs";

const tableData = [
  {
    id: "1",
    name: "James Sitepu",
    location: "Park London",
    amount: "214",
    status: "On Delivery",
    statuscolor: "primary",
  },
  {
    id: "2",
    name: "Marquez Silaban",
    location: "Park, Orange St",
    amount: "301",
    status: "Canceled",
    statuscolor: "danger ",
  },
  {
    id: "3",
    name: "Joseph David",
    location: "Center Park St",
    amount: "250",
    status: "Delivered",
    statuscolor: "success",
  },
  {
    id: "4",
    name: "Richard Elijah",
    location: "Maharashtra (India) ",
    amount: "325",
    status: "On Delivery",
    statuscolor: "primary",
  },
  {
    id: "5",
    name: "Robert Silaban",
    location: "Bavaria (Germany)",
    amount: "230",
    status: "Canceled",
    statuscolor: "danger",
  },
  {
    id: "6",
    name: "James John",
    location: "Sao Paulo (Brazil)",
    amount: "401",
    status: "Delivered",
    statuscolor: "success",
  },
  {
    id: "7",
    name: "James Sitepu",
    location: "Tokyo (Japan)",
    amount: "245",
    status: "Canceled",
    statuscolor: "danger",
  },
  {
    id: "8",
    name: "Marquez Silaban",
    location: "Alberta (Canada)",
    amount: "260",
    status: "Delivered",
    statuscolor: "success",
  },
  {
    id: "9",
    name: "Joseph David",
    location: "Yucatán (Mexico)",
    amount: "325",
    status: "On Delivery",
    statuscolor: "primary",
  },
  {
    id: "10",
    name: "Richard Elijah",
    location: "Lombardy (Italy)",
    amount: "340",
    status: "Canceled",
    statuscolor: "danger",
  },
  {
    id: "11",
    name: "Joseph David",
    location: "Center Park St",
    amount: "250",
    status: "Delivered",
    statuscolor: "success",
  },
  {
    id: "12",
    name: "Richard Elijah",
    location: "Maharashtra (India) ",
    amount: "325",
    status: "On Delivery",
    statuscolor: "primary",
  },
  {
    id: "13",
    name: "Robert Silaban",
    location: "Bavaria (Germany)",
    amount: "230",
    status: "Canceled",
    statuscolor: "danger",
  },
  {
    id: "14",
    name: "James John",
    location: "Sao Paulo (Brazil)",
    amount: "401",
    status: "Delivered",
    statuscolor: "success",
  },
  {
    id: "15",
    name: "James Sitepu",
    location: "Tokyo (Japan)",
    amount: "245",
    status: "Canceled",
    statuscolor: "danger",
  },
  {
    id: "16",
    name: "Marquez Silaban",
    location: "Alberta (Canada)",
    amount: "260",
    status: "Delivered",
    statuscolor: "success",
  },
];

const options = [
  { value: "1", label: "Daily" },
  { value: "2", label: "Week" },
  { value: "3", label: "Month" },
];

const InventoryHistory = () => {
  const [addActive, setActive] = useState("All");
  const dispatch = useDispatch();

  // Fetch all inventory history data from the API

  useEffect(() => {
    dispatch(fetchAllInventoryHistory());
    dispatch(fetchInventoryHistoryOfIn());
    dispatch(fetchInventoryHistoryOfOut());
  }, [dispatch]);

  const allInventoryHistory = useSelector(
    (state) => state.InventoryHistory.inventoryHistory
  );
  const inventoryHistoryIn = useSelector(
    (state) => state.InventoryHistory.inventoryHistoryIn
  );
  const inventoryHistoryOut = useSelector(
    (state) => state.InventoryHistory.inventoryHistoryOut
  );

  const [tableBlog, setTableBlog] = useState(allInventoryHistory);

  useEffect(() => {
    if (addActive === "All") {
      setTableBlog(allInventoryHistory);
    } else if (addActive === "In") {
      setTableBlog(inventoryHistoryIn.product);
    } else if (addActive === "Out") {
      setTableBlog(inventoryHistoryOut.inventory);
    }
  }, [addActive, allInventoryHistory, inventoryHistoryIn, inventoryHistoryOut]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 10;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = tableBlog?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(tableBlog?.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [newOrder, setNewOrder] = useState(false);

  const [search, setSearch] = useState("");

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-4 flex-wrap">
        <ul className="revnue-tab nav nav-tabs" id="myTab">
          <li
            className="nav-item"
            onClick={() => {
              setActive("All");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "All" ? "active" : ""}`}
            >
              All
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("In");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "In" ? "active" : ""}`}
            >
              In
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("Out");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "Out" ? "active" : ""}`}
            >
              Out
            </Link>
          </li>
        </ul>

        <input
          type="text"
          className="form-control w-25"
          placeholder="Filter Search"
          aria-label="Search"
          aria-describedby="button-addon2"
          onChange={(e) => setSearch(e.target.value)}
        />

        <div className="d-flex align-items-center">
          <Dropdown className="me-2">
            <Dropdown.Toggle variant="primary">New Order</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Admin</Dropdown.Item>
              <Dropdown.Item>Employee</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Select
            options={options}
            className="custom-react-drop-btn"
            defaultValue={options[0]}
            isSearchable={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="tab-content">
            <div className="tab-pane fade active show">
              <div className="card mt-2">
                <div className="card-body p-0">
                  <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                    <div
                      id="orderlist-table"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="empoloyees-tbl"
                        className="table dataTable no-footer"
                      >
                        <thead>
                          <tr>
                            <th className="d-flex align-items-center sorting_asc_15">
                              <div className="form-check custom-checkbox ms-0"></div>
                            </th>
                            <th>Date</th>
                            <th>Product</th>
                            <th>Name</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addActive === "In" ? (
                            records
                              ?.filter((item) => {
                                if (search === "") {
                                  return item;
                                } else {
                                  const searchLower = search.toLowerCase();
                                  return (
                                    item?.description
                                      .toLowerCase()
                                      .includes(searchLower) ||
                                    item?.product?.name
                                      .toLowerCase()
                                      .includes(searchLower)
                                  );
                                }
                              })
                              .map((item, index) => {

                                console.log("item", item);
                                return (
                                  <tr key={index}>
                                    <td className="sorting_25">
                                      <div className="color-box"></div>
                                    </td>
                                    <td>
                                      <span>
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleDateString()}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{item?.name}</span>
                                    </td>
                                    <td>
                                      <span>{item.barber?.name}</span>
                                    </td>
                                    <td>
                                      <span>{item.stock}</span>
                                    </td>
                                  </tr>
                                );
                              })
                          ) : (
                            records
                              ?.filter((item) => {
                                if (search === "") {
                                  return item;
                                } else {
                                  const searchLower = search.toLowerCase();
                                  return (
                                    item?.description
                                      .toLowerCase()
                                      .includes(searchLower) ||
                                    item?.product?.name
                                      .toLowerCase()
                                      .includes(searchLower)
                                  );
                                }
                              })
                              .map((item, index) => {
                                
                                console.log("item", item);
                                return (
                                  <tr key={index}>
                                    <td className="sorting_25">
                                      <div className="color-box"></div>
                                    </td>
                                    <td>
                                      <span>
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleDateString()}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{item?.product?.name}</span>
                                    </td>
                                    <td>
                                      <span>{item.barber?.name}</span>
                                    </td>
                                    <td>
                                      <span>{item.quantity}</span>
                                    </td>
                                  </tr>
                                );
                              })
                          )}
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {lastIndex - recordsPage + 1} to{" "}
                          {records.length < lastIndex
                            ? records.length
                            : lastIndex}{" "}
                          of {records.length} entries
                        </div>
                        {/* <div className="dataTables_info">
                          Showing {lastIndex - recordsPage + 1} to{" "}
                          {tableData.length < lastIndex
                            ? tableData.length
                            : lastIndex}{" "}
                          of {tableData.length} entries
                        </div> */}
                        <div
                          className="dataTables_paginate paging_simple_numbers justify-content-center"
                          id="example2_paginate"
                        >
                          <Link
                            className="paginate_button previous disabled"
                            to="#"
                            onClick={prePage}
                          >
                            <i className="fa-solid fa-angle-left" />
                          </Link>
                          <span>
                            {number.map((n, i) => (
                              <Link
                                className={`paginate_button ${
                                  currentPage === n ? "current" : ""
                                } `}
                                key={i}
                                onClick={() => changeCPage(n)}
                              >
                                {n}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className="paginate_button next"
                            to="#"
                            onClick={nextPage}
                          >
                            <i className="fa-solid fa-angle-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={newOrder} onHide={setNewOrder} centered>
        <div className="modal-content">
          <div className="modal-header ">
            <h5 className="modal-title">Add Order</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setNewOrder(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-label">Order ID</label>
              <input
                type="number"
                className="form-control mb-3"
                id="exampleInputEmail1"
                placeholder="Order Id"
              />
              <label className="form-label">Gmail</label>
              <input
                type="email"
                className="form-control mb-3"
                id="exampleInputEmail2"
                placeholder=" Email"
              />
              <label className="form-label">Amount</label>
              <input
                type="number"
                className="form-control mb-3"
                id="exampleInputEmail3"
                placeholder="Amount"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger light"
              onClick={() => setNewOrder(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InventoryHistory;
