import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCompleted } from "./completeAPI";

// get todays completed
export const fetchCompleted = createAsyncThunk(
  "completed/fetchCompleted",
  async () => {
    const response = await getCompleted();
    return response;
  }
);

const initialState = {
  completed: [],
  status: "idle",
  error: null,
};

const completeSlice = createSlice({
  name: "complete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get todays completed
      .addCase(fetchCompleted.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompleted.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.completed = action.payload;
      })
      .addCase(fetchCompleted.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default completeSlice.reducer;
