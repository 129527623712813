import React, { useEffect, useState } from 'react';
import { createAsyncServiceForEmployee, deleteAsyncServiceForEmployee, fetchAsyncServicesByEmployee } from '../../../store/employeeServices/employeeServicesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAsyncAllCategory } from '../../../store/category/categorySlice';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';

const EmployeeServices = ({id}) => {
    const dispatch = useDispatch();

      // all category are fetching here to get services
  useEffect(() => {
    dispatch(fetchAsyncAllCategory());
    dispatch(fetchAsyncServicesByEmployee(id));
  }, [dispatch, id]);

  // employee services
  const employeeServices = useSelector(
    (state) => state.EmployeeServices.employeeServices
  );

  const allCategory = useSelector((state) => state.Category.allCategory);

  // modal functionality
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [assignedServices, setAssignedServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalSearchTerm, setModalSearchTerm] = useState("");

  // Function to handle "Add service" button click and open the modal
  const handleAddServiceClick = (category) => {
    const serviceIds = employeeServices.services.map((service) => service.id);

    const filteredCategory = category.map((categoryItem) => {
      const filteredServices = categoryItem.services?.filter(
        (service) => !serviceIds.includes(service.id)
      );

      return { ...categoryItem, services: filteredServices };
    });

    setSelectedCategory(filteredCategory);

    if (
      filteredCategory.every(
        (cat) => !cat.services || cat.services.length === 0
      )
    ) {
      Swal.fire({
        position: "top-end",
        title: "All services are added",
        showConfirmButton: false,
        icon: "info",
        timer: 2000,
        toast: true,
      });
    } else {
      setModalOpen(true);
    }
  };

  const handleAddService = (serviceId) => {
    dispatch(async (dispatch) => {
      await dispatch(createAsyncServiceForEmployee({ id: id, serviceId: serviceId }));
      // Show SweetAlert notification
      Swal.fire({
        position: "top-end",
        title: "Service added",
        showConfirmButton: false,
        icon: "success",
        timer: 2000,
        toast: true,
      });
      setAssignedServices([...assignedServices, serviceId]);
      dispatch(fetchAsyncServicesByEmployee(id));
    });
  };

  // delete services function
  const handleDeleteService = async (serviceId) => {
    const result = await Swal.fire({
      title: "Are you sure for delete?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd6b55",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    });

    let data = { id, serviceId };
    if (result.isConfirmed) {
      try {
        await dispatch(deleteAsyncServiceForEmployee(data));
        // The service deletion was successful
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } catch (error) {
        Swal.fire("Error", "An error occurred while deleting.", "error");
        console.error("Error deleting service:", error);
      }
    }
  };

  // Filtered table data based on search term
  const filteredTableData = employeeServices?.services?.filter((item) => {
    if (searchTerm === "") {
      return true;
    } else {
      const searchLower = searchTerm.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchLower) ||
        item.category?.name.toLowerCase().includes(searchLower)
      );
    }
  });

    return (
        <>
            {/* EMPLOYEE SERVICES  */}
            <div className="card-body">
                  <div className="pb-2">
                    <h3 className="text-center pb-4">Edit services</h3>
                    <div className="d-flex justify-content-between align-content-center mt-3">
                      <input
                        type="text"
                        className="form-control w-50 w-lg-25"
                        placeholder="Search here..."
                        aria-label="Search"
                        aria-describedby="button-addon2"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <div>
                        <button
                          className="btn btn-primary me-2"
                          onClick={() => handleAddServiceClick(allCategory)}
                        >
                          Add service
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="tab-content">
                        <div className="tab-pane fade active show">
                          <div className="card mt-2">
                            <div className="card-body p-0">
                              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                <div
                                  id="orderlist-table"
                                  className="dataTables_wrapper no-footer px-3"
                                >
                                  {searchTerm &&
                                  filteredTableData?.length === 0 ? (
                                    <p className="text-danger ms-2 mt-4">
                                      No search result found!
                                    </p>
                                  ) : (
                                    <table
                                      id="empoloyees-tbl"
                                      className="table dataTable no-footer"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Services</th>
                                          <th>Category</th>
                                          <th className="text-end">Delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {filteredTableData?.map((item) => (
                                          <tr key={item?.id}>
                                            <td>
                                              <span>{item.name}</span>
                                            </td>
                                            <td>
                                              <span>{item.category?.name}</span>
                                            </td>
                                            <td className="text-end text-danger fs-3">
                                              <button
                                                className="btn btn-outline-danger"
                                                onClick={() =>
                                                  handleDeleteService(item.id)
                                                }
                                              >
                                                <FiTrash2 />
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --------- MODAL -------- */}
                <Modal
                  show={isModalOpen}
                  size={"lg"}
                  onHide={() => setModalOpen(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Services</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="mb-4">
                      {/* Search input for modal */}
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search here..."
                        aria-label="Search"
                        aria-describedby="button-addon2"
                        value={modalSearchTerm}
                        onChange={(e) => setModalSearchTerm(e.target.value)}
                      />
                    </div>
                    {selectedCategory?.map(
                      (cat) =>
                        cat.services &&
                        cat.services.length > 0 && (
                          <div className="mb-3" key={cat.id}>
                            <h4>Category: {cat.name}</h4>
                            <table className="table">
                              <thead>
                                <th>Service Name</th>
                                <th className="text-end">Action</th>
                              </thead>
                              <tbody className="mt-2">
                                {cat.services
                                  .filter(
                                    (ser) => !assignedServices.includes(ser.id)
                                  )
                                  .filter((ser) => {
                                    if (modalSearchTerm === "") {
                                      return true;
                                    } else {
                                      const searchLower =
                                        modalSearchTerm.toLowerCase();
                                      return ser.name
                                        .toLowerCase()
                                        .includes(searchLower);
                                    }
                                  })
                                  .map((ser) => (
                                    <tr key={ser.id}>
                                      <td>{ser.name}</td>
                                      <td className="text-end">
                                        <button
                                          className="btn btn-primary justify-content-end"
                                          onClick={() =>
                                            handleAddService(ser.id)
                                          }
                                        >
                                          +
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )
                    )}
                  </Modal.Body>
                </Modal>
        </>
    );
};

export default EmployeeServices;