import { get } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";


// Get all expenses
export async function getRecentExpense() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/expenses/recent",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response)
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// Get total expense
export async function getTotalExpense() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/expenses/total",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response)
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}