import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAsyncBarberAboutInfo,
  putAsyncBarberAboutInfo,
} from "../../../store/barberAbout/barberAboutSlice";
import Swal from "sweetalert2";

const GeneralAbout = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);

  const [initialTitle, setInitialTitle] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [initialImageURL, setInitialImageURL] = useState("");

  // Get barber About info
  useEffect(() => {
    dispatch(getAsyncBarberAboutInfo());
  }, [dispatch]);
  const barberAboutInfo = useSelector(
    (state) => state.BarberAbout.barberAboutSlice
  );

  // Convert image to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.split("base64,")[1]);
      };
      reader.onerror = (error) => {
        console.error("Error converting file to base64!", error);
        reject(error);
      };
    });
  };

  const handleSubmitInfo = async () => {
    try {
      setLoading(true);
      let base64Image = "";
      if (imageFile) {
        base64Image = await convertToBase64(imageFile);
      }
      if (
        title === initialTitle &&
        description === initialDescription &&
        base64Image === initialImageURL
      ) {
        return;
      }
      const changes = {};
      if (title !== initialTitle) {
        changes.title = title;
      }
      if (description !== initialDescription) {
        changes.description = description;
      }
      if (base64Image !== "") {
        changes.image = base64Image;
      }
      // Dispatch API call with the changed data
      await dispatch(putAsyncBarberAboutInfo(changes))
        .then((updatedData) => {
          if (updatedData) {
            setTitle(updatedData.title || title);
            setDescription(updatedData.description || description);
            setImageURL(updatedData.url || imageURL);
          }
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your changes have been saved!",
          });
        })
        .catch((error) => {
          console.error("Error posting data:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while saving your changes!",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error posting data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving your changes!",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (barberAboutInfo) {
      setTitle(barberAboutInfo.title);
      setDescription(barberAboutInfo.description);
      setImageURL(barberAboutInfo.url);

      // Set initial values for comparison
      setInitialTitle(barberAboutInfo.title);
      setInitialDescription(barberAboutInfo.description);
      setInitialImageURL(barberAboutInfo.url);
    }
  }, [barberAboutInfo]);

  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <h5>About us</h5>
          <p className="text-muted">
            Your location name abss is displayed across many areas including on
            your online pages and messages to client{" "}
          </p>
        </div>
        <div className="col-md-9">
          <h5>Title</h5>
          <input
            value={title}
            placeholder="Add a title"
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            className="form-control"
          />
          <h5 className="mt-3">Description</h5>
          <textarea
            rows={3}
            value={description}
            placeholder="Add a description"
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            className="form-control"
          />
          <h5 className="mt-3">Upload a photo</h5>
          <div className="d-flex align-items-center ">
            <img
              // src={barberAboutInfo?.url}
              src={imageURL}
              alt="Barbershop"
              className="mr-2 border rounded-1 p-2 me-4"
              style={{ height: "100px", width: "100px" }}
            />
            <input
              value={image}
              type="file"
              onChange={(e) => {
                setImageFile(e.target.files[0]);
                setImage(e.target.value);
              }}
              className="form-control"
            />
          </div>
          <button
            onClick={() => {
              Swal.fire({
                position: "top-end",
                title: "Wait a moment, please",
                showConfirmButton: false,
                icon: "info",
                timer: 2000,
                toast: true,
              });
              handleSubmitInfo();
            }}
            className="btn btn-outline-primary rounded-5 mt-4"
          >
            {loading === true ? (
              <div className="d-flex align-items-center justify-content-center ">
                <span className="me-2">Initiated</span>
                <Spinner animation="border" size="sm" />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      {/* About  */}
      <Card.Footer className="mt-3" />
    </div>
  );
};

export default GeneralAbout;
