import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  category: "",
  paymentType: "",
  status: "idle",
  error: null,
}


const ExpenseCategorySlice = createSlice({
  name: "expenseCategory",
  initialState,
  reducers: {
    setExpenseCategory: (state, { payload }) => {
      console.log(payload)
      state.category = payload
    },
    setPaymentType: (state, { payload }) => {
      console.log(payload)
      state.paymentType = payload
    },
  },
})

export const { setExpenseCategory, setPaymentType } = ExpenseCategorySlice.actions
export default ExpenseCategorySlice.reducer