import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import AllMessage from "./AllMessage";
import { Nav, Tab } from "react-bootstrap";
import UnreadMessage from "./UnreadMessage";
import SingleMessage from "./SingleMessage";
import ComposeMessage from "./ComposeMessage";
import {
  fetchAsyncMessages,
  fetchAsyncUnreadMessages,
} from "../../../store/messages/messagesSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { decrease, increase } from "../../../store/paginationSlice";
import SentMessages from "./SentMessages";

const Inbox = () => {
  const path = useLocation().pathname;
  const singleMessageId = useParams().id;
  const [allMessage, setAllMessage] = useState(true);
  const [singleMessage, setSingleMessage] = useState(false);
  const [unreadMessage, setUnreadMessage] = useState(false);
  const [composeMessage, setComposeMessage] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  // const [page, setPage] = useState(1);

  useEffect(() => {
    if (path === "/all-message" || path === "/inbox-page") {
      setAllMessage(true);
      setSingleMessage(false);
      setUnreadMessage(false);
      setComposeMessage(false);
      setSentMessage(false);
    }
    if (path === "/sent-message") {
      setAllMessage(false);
      setSentMessage(true);
      setSingleMessage(false);
      setUnreadMessage(false);
      setComposeMessage(false);
    }
    if (path === "/unread-message") {
      setAllMessage(false);
      setSingleMessage(false);
      setSentMessage(false);
      setUnreadMessage(true);
      setComposeMessage(false);
    }
    if (path === `/single-message/${singleMessageId}`) {
      setAllMessage(false);
      setSingleMessage(true);
      setSentMessage(false);
      setUnreadMessage(false);
      setComposeMessage(false);
    }
    if (path === "/compose-page") {
      setAllMessage(false);
      setSingleMessage(false);
      setUnreadMessage(false);
      setSentMessage(false);
      setComposeMessage(true);
    }
  }, [path, singleMessageId]);

  // Fetch Page Number
  const page = useSelector((state) => state.Pagination.page);

  // Fetch all unread messages
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAsyncUnreadMessages(page));
    dispatch(fetchAsyncMessages(page));
  }, [dispatch, page]);

  const allUnreadMessage = useSelector((state) => state.Messages.unreadMessage);
  const totalReadUnreadMessages = useSelector(
    (state) => state.Messages.allMessages
  );

  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 15;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  return (
    <>
      <div className="container p-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-0 h-auto">
              <div className="card-body py-0 pe-0">
                <div className="row gx-0">
                  {/* Inbox left Sidebar */}
                  <div className="col-xl-3 col-xxl-4 col-lg-4">
                    <div className="email-left-box dlab-scroll  pt-3 ps-0">
                      <div className="p-0 pe-2">
                        <Link
                          to="/compose-page"
                          className="btn text-white btn-block"
                        >
                          <i className="fa-solid fa-plus me-2"></i>Compose
                          Message
                        </Link>
                      </div>
                      <div className="mail-list rounded ">
                        <Link
                          to="/inbox-page"
                          className={`list-group-item ${
                            path === "/inbox-page" ||
                            path === "/all-message" ||
                            path === "/unread-message"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="fa-regular fa-envelope align-middle"></i>
                          Inbox
                          <span className="badge badge-purple badge-sm float-end rounded">
                            {allUnreadMessage?.total}
                          </span>
                        </Link>
                        <Link
                          to="/sent-message"
                          className={`list-group-item ${
                            path === "/sent-message" ? "active" : ""
                          }`}
                        >
                          <i className="fa-regular fa-paper-plane align-middle"></i>
                          Sent
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Inbox right sidebar */}
                  <div className="col-xl-9 col-xxl-8 col-lg-8">
                    {singleMessage ? (
                      <SingleMessage singleMessageId={singleMessageId} />
                    ) : composeMessage ? (
                      <ComposeMessage />
                    ) : (
                      <>
                        {/* Top navbar */}
                        {allMessage || unreadMessage ? (
                          <div role="toolbar" className="toolbar ms-1 ms-sm-0">
                            <div className="saprat">
                              <div className="d-flex align-items-center">
                                <div className="btn-group">
                                  <div className="form-check custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="checkbox1"
                                      // onClick={() => chackboxFun("all")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="checkbox1"
                                    ></label>
                                  </div>
                                </div>
                                <Nav
                                  as="ul"
                                  className="nav nav-pills"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <Nav.Item
                                    as="li"
                                    className="nav-item btn-group"
                                    role="presentation"
                                  >
                                    <Nav.Link
                                      as={Link}
                                      to="/all-message"
                                      type="button"
                                      className="btn effect mx-2 nav-link"
                                      id="pills-home-tab"
                                      eventKey="Import"
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.8798 1.66667H4.21313C3.55032 1.6674 2.91485 1.93102 2.44617 2.3997C1.97748 2.86839 1.71386 3.50385 1.71313 4.16667V15.8333C1.71386 16.4962 1.97748 17.1316 2.44617 17.6003C2.91485 18.069 3.55032 18.3326 4.21313 18.3333H15.8798C16.5426 18.3326 17.1781 18.069 17.6468 17.6003C18.1154 17.1316 18.3791 16.4962 18.3798 15.8333V4.16667C18.3791 3.50385 18.1154 2.86839 17.6468 2.3997C17.1781 1.93102 16.5426 1.6674 15.8798 1.66667ZM4.21313 3.33334H15.8798C16.1007 3.33356 16.3126 3.42143 16.4688 3.57766C16.625 3.73389 16.7129 3.94573 16.7131 4.16667V10.8333H14.6591C14.3847 10.8331 14.1145 10.9008 13.8725 11.0303C13.6306 11.1597 13.4244 11.3471 13.2724 11.5755L12.1005 13.3333H7.99243L6.82056 11.5755C6.66853 11.3471 6.46237 11.1597 6.22042 11.0303C5.97848 10.9008 5.70826 10.8331 5.43384 10.8333H3.3798V4.16667C3.38002 3.94573 3.46789 3.73389 3.62412 3.57766C3.78035 3.42143 3.99219 3.33356 4.21313 3.33334ZM15.8798 16.6667H4.21313C3.99219 16.6664 3.78035 16.5786 3.62412 16.4223C3.46789 16.2661 3.38002 16.0543 3.3798 15.8333V12.5H5.43384L6.60572 14.2578C6.75774 14.4863 6.96391 14.6736 7.20585 14.8031C7.4478 14.9326 7.71802 15.0002 7.99243 15H12.1005C12.3749 15.0002 12.6451 14.9326 12.8871 14.8031C13.129 14.6736 13.3352 14.4863 13.4872 14.2578L14.6591 12.5H16.7131V15.8333C16.7129 16.0543 16.625 16.2661 16.4688 16.4223C16.3126 16.5786 16.1007 16.6664 15.8798 16.6667Z"
                                          fill="#666666"
                                        />
                                      </svg>
                                      All messages
                                    </Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item
                                    as="li"
                                    className="nav-item btn-group"
                                    role="presentation"
                                  >
                                    <Nav.Link
                                      as={Link}
                                      to="/unread-message"
                                      className="btn ms-2 effect nav-link"
                                      id="pills-contact-tab"
                                      eventKey="Promotion"
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.5594 3.68912H16.0558C15.855 3.68912 15.66 3.76101 15.5071 3.89074L14.2974 4.92116L13.0951 3.89239C12.9414 3.76101 12.7464 3.68912 12.5448 3.68912H2.44958C1.49765 3.68912 0.723389 4.46338 0.723389 5.4153V8.40824C0.723389 9.08335 1.15969 9.6659 1.83479 9.89232C2.18267 10.0088 2.4157 10.3311 2.4157 10.6955C2.4157 11.0616 2.1835 11.3838 1.83644 11.4987C1.17043 11.7201 0.723389 12.3167 0.723389 12.9828V16.5847C0.723389 17.5366 1.49765 18.3109 2.44958 18.3109H12.5448C12.7472 18.3109 12.943 18.2382 13.0967 18.106L14.2974 17.0731L15.5055 18.1076C15.6592 18.239 15.8542 18.3109 16.0558 18.3109H19.5511C20.5022 18.3109 21.2765 17.5366 21.2765 16.5847V12.9828C21.2765 12.306 20.8402 11.7234 20.1634 11.4987C19.8172 11.3838 19.5842 11.0616 19.5842 10.6955C19.5842 10.3319 19.818 10.0088 20.1659 9.89232C20.841 9.6659 21.2765 9.08335 21.2765 8.40824V5.40704C21.2765 4.46008 20.5064 3.68912 19.5594 3.68912ZM19.5842 8.3033C18.5703 8.66275 17.8919 9.6188 17.8919 10.6955C17.8919 11.7739 18.567 12.7274 19.5834 13.0894L19.5511 16.6186H16.3681L14.8461 15.3155C14.5288 15.0444 14.0611 15.0461 13.7437 15.3171L12.2324 16.6178L2.4157 16.5847V13.0894C3.42959 12.7324 4.108 11.7763 4.108 10.6955C4.108 9.62128 3.43373 8.66771 2.41652 8.30247L2.44958 5.38143H12.2324L13.7454 6.67627C14.0619 6.94648 14.5263 6.94813 14.8444 6.67792L16.3648 5.3839L19.5842 5.40704V8.3033Z"
                                          fill="#666666"
                                        />
                                        <path
                                          d="M14.3337 9.46841C13.866 9.46841 13.4876 9.84687 13.4876 10.3146V11.6854C13.4876 12.1531 13.866 12.5316 14.3337 12.5316C14.8014 12.5316 15.1799 12.1531 15.1799 11.6854V10.3146C15.1799 9.84687 14.8014 9.46841 14.3337 9.46841ZM10.7359 9.32546L10.0162 9.22051L9.69477 8.56937C9.69477 8.56937 9.69477 8.56937 9.69477 8.56855C9.47001 8.11407 9.01554 7.83147 8.50817 7.83147C8.00081 7.83147 7.54633 8.11407 7.32158 8.56937L7.00014 9.22051L6.28206 9.32463C5.78048 9.39735 5.37063 9.74192 5.21363 10.2245C5.05663 10.7071 5.18471 11.2277 5.54829 11.5813L6.06804 12.0887L5.94575 12.8043C5.85981 13.305 6.06143 13.8008 6.47212 14.0991C6.88243 14.397 7.41676 14.4352 7.86447 14.1999L8.50817 13.8611L9.15023 14.1991C9.59892 14.4354 10.1319 14.3974 10.5442 14.0991C10.9549 13.8008 11.1565 13.305 11.0706 12.8051L10.9483 12.0887L11.4689 11.5813C11.8325 11.226 11.9597 10.7062 11.8027 10.2237C11.6457 9.7411 11.2359 9.39735 10.7359 9.32546ZM9.62949 11.0112C9.31714 11.3144 9.17419 11.7524 9.24773 12.1837L9.27665 12.3539L9.12626 12.2746C8.74101 12.0704 8.28108 12.0695 7.89256 12.2729L7.73969 12.3531L7.76862 12.1829C7.84216 11.7524 7.6992 11.3144 7.38851 11.0128L7.26456 10.8922L7.43478 10.8674C7.8653 10.8054 8.23797 10.5352 8.43133 10.1435L8.50817 9.98817L8.58502 10.1443C8.77838 10.5352 9.15105 10.8054 9.57991 10.8665L9.75179 10.8922L9.62949 11.0112Z"
                                          fill="#666666"
                                        />
                                      </svg>
                                      Unread
                                    </Nav.Link>
                                    {allUnreadMessage?.total > 0 && (
                                      <span className="unread-messages text-center">
                                        {allUnreadMessage?.total}
                                      </span>
                                    )}
                                  </Nav.Item>
                                </Nav>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="email-right-box">
                          <Tab.Container defaultActiveKey="Import">
                            {/* Conditional Component Rendering */}
                            {allMessage ? (
                              <AllMessage />
                            ) : unreadMessage ? (
                              <UnreadMessage />
                            ) : composeMessage ? (
                              <ComposeMessage />
                            ) : sentMessage ? (
                              <SentMessages />
                            ) : (
                              <></>
                            )}
                          </Tab.Container>
                        </div>
                        {/* Footer */}
                        {/* {allMessage || unreadMessage || sentMessage ? (
                          <div className="row mt-1">
                            <div className="col-12 ps-3">
                              <div className="d-sm-flex text-center justify-content-between align-items-center">
                                {allMessage ? (
                                  <div className="dataTables_info ">
                                    Showing {activePag.current * sort + 1} to{" "}
                                    {data.length >
                                    (activePag.current + 1) * sort
                                      ? (activePag.current + 1) * sort
                                      : data.length}{" "}
                                    of {totalReadUnreadMessages?.total} entries
                                  </div>
                                ) : (
                                  <div className="dataTables_info ">
                                    Showing {activePag.current * sort + 1} to{" "}
                                    {data.length >
                                    (activePag.current + 1) * sort
                                      ? (activePag.current + 1) * sort
                                      : data.length}{" "}
                                    of {allUnreadMessage?.total} entries
                                  </div>
                                )}
                                
                                <div className="pagination-gutter pagination-primary pagination-sm no-bg align-content-center mt-2">
                                  <ul className="d-flex gap-3 align-content-center justify-content-center">
                                    <li className="page-item page-indicator">
                                      <button
                                        onClick={() => dispatch(decrease())}
                                        className="btn btn-primary"
                                      >
                                        <FaArrowLeft />
                                      </button>
                                    </li>
                                    <li className="page-item page-indicator">
                                      <button className="btn btn-primary">
                                        {page}
                                      </button>
                                    </li>
                                      
                                    <li className="page-item page-indicator">
                                      <button
                                        onClick={() => dispatch(increase())}
                                        className="btn btn-primary"
                                      >
                                        <FaArrowRight />
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )} */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inbox;
