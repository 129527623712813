import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { fetchAsyncProduct } from "../../../store/inventory/inventorySlice";
import { useSelector } from "react-redux";
import { createAsyncInventoryProduct } from "../../../store/inventoryUser/inventoryUserSlice";

const formatOptionLabel = ({ name, url, stock, price }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <img
      src={url}
      alt={name}
      style={{ width: "200px", marginRight: "10px", borderRadius: "5px" }}
    />
    <div className="mx-auto">
      <p>Product Name: {name}</p>
      <p>Current Stock: {stock}</p>
      <p>Price: ${price}</p>
    </div>
  </div>
);

const InventoryUser = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const [quantityValue, setQuantityValue] = useState("");
  const [description, setDescription] = useState("");
  const [quantityTouched, setQuantityTouched] = useState(false);
  const [descriptionTouched, setDescriptionTouched] = useState(false);

  console.log("selectedOption", selectedOption);

  useEffect(() => {
    dispatch(fetchAsyncProduct());
  }, [dispatch]);

  const inventoryData = useSelector((state) => state.Inventory.inventory);

  // Function to handle quantity input change
  const handleQuantityChange = (e) => {
    setQuantityValue(e.target.value);
    setQuantityTouched(true);
  };

  // Function to handle description input change
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionTouched(true);
  };

  const isSubmitDisabled =
    !selectedOption ||
    !descriptionTouched ||
    !description.trim() ||
    !quantityTouched ||
    !quantityValue.trim() ||
    parseInt(quantityValue) <= 0 ||
    parseInt(quantityValue) > (selectedOption?.stock || 0);

  let validationMessage = "";
  if (!selectedOption) {
    validationMessage = "Please select a product";
  } else if (!quantityValue.trim()) {
    validationMessage = "Please write the quantity";
  } else if (!description.trim()) {
    validationMessage = "Please write the description";
  }

  const handleSubmit = async () => {
    if (selectedOption) {
      // Validate quantity
      const parsedQuantity = parseInt(quantityValue);
      if (parsedQuantity <= 0) {
        console.error("Quantity must be greater than 0");
        return;
      }
      if (parsedQuantity > selectedOption.stock) {
        console.error("Quantity exceeds available stock");
        return;
      }

      const data = {
        quantity: quantityValue,
        description: description,
      };
      console.log("data", data);
      await dispatch(
        createAsyncInventoryProduct({ productId: selectedOption.id, data })
      );
    } else {
      console.error("No product selected");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Inventory</h4>
            </div>
            <div className="card-body">
              <label htmlFor="amount">Select a Product</label>
              <Select
                className="my-3"
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={inventoryData}
                formatOptionLabel={formatOptionLabel}
              />
              <div className="row">
                <div className="col-12 mt-4">
                  <label>Quantity</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    value={quantityValue}
                    onChange={handleQuantityChange}
                  />
                  {(parseInt(quantityValue) === 0 ||
                    parseInt(quantityValue) > (selectedOption?.stock || 0)) && (
                    <p style={{ color: "red" }}>
                      {parseInt(quantityValue) === 0
                        ? "Quantity can't be 0"
                        : "Quantity cannot exceed available stock"}
                    </p>
                  )}
                </div>
                <div className="col-12 mt-4">
                  <label htmlFor="description">Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </div>

                <div className="text-end mt-3">
                  {validationMessage && (
                    <p style={{ color: "red" }}>{validationMessage}</p>
                  )}
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryUser;
