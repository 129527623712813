import { del, get, post, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

export async function postExpense(data) {
  console.log(data);
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/expenses",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "expenses created successfully!",
    });
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

export async function getAllExpense() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/expenses",
    });
    const response = await restOperation.response;
    // console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

export async function getExpenseByType(type) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/expenses/by-type/${type}`,
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Get single expense
export async function getSingleExpense(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/expenses/${id}`,
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Update expense
export async function updateExpense({ data, singleId }) {
  console.log(data, singleId);
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/expenses/${singleId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log("put call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "expenses updated successfully!",
    });
    return response.body.json();
  } catch (error) {
    console.log("put call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Delete expense
export async function deleteExpenses(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/expenses/${id}`,
    });
    const response = await restOperation.response;
    console.log("DELETE call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "expenses deleted successfully!",
    });
    return response;
  } catch (error) {
    console.log("DELETE call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}


export async function postCalculation({ data, employeeId }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${employeeId}/salary-percentage`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log("post call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("post call failed: ", error);
  }
}

export async function expensedProducts({ data, productId }) {
  try {
    const response = await post({
      apiName: amplifyConfigs.apiName,
      path: `/expenses/products/${productId}`,
      options: {
        body: data,
      },
    });
    console.log("Percentage POST call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    throw error;
  }
}
