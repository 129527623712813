import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllServing } from "./servingAPI";


export const fetchAllServing = createAsyncThunk(
  "serving/fetchAllServing",
  async () => {
    const response = await getAllServing();
    return response;
  }
);


const initialState = {
  serving: {},
  status: "idle",
  error: null,
};



const servingSlice = createSlice({
  name: "serving",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllServing.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllServing.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.serving = action.payload;
      })
      .addCase(fetchAllServing.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },

});

export default servingSlice.reducer;



