import { get, post, put, del } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

// Get all categories
export async function getCategories() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/categories",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {}
}

// Get category by id
export async function getCategoryById(categoryId) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${categoryId}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {}
}

// Create a new category
export async function createCategories(data) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/categories",
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {
    console.log("POST call failed: ", error);
  }
}

// Update a category
export async function updateCategory({ id, name, image }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${id}`,
      options: {
        body: { name, image },
      },
    });
    await restOperation.response;
  } catch (error) {
    console.error("PUT call failed: ", error);
  }
}

// Delete a category
export async function deleteCategory(categoryId) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${categoryId}`,
    });
    await restOperation.response;
  } catch (error) {
    console.log("DELETE call failed: ", error);
  }
}


