import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import OpeningHours from "../components/ScheduleModals/OpeningHours";
import HoursByDate from "../components/ScheduleModals/HoursByDate";

const Waitlist = () => {
  const [isSwitchActive, setIsSwitchActive] = useState(false);
  const [showOpeningHours, setShowOpeningHours] = useState(false);
  const [showHoursByDate, setShowHoursByDate] = useState(false);

  return (
    <>
      <Card className="m-3">
        <Card.Header as="h5">Waitlist</Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-md-3">
              <h5>Waitlist hours</h5>
              <p className="text-muted">
                Control who can join your waitlist and during which times.{" "}
              </p>
            </div>
            <div className="col-md-12">
              <h5>Waitlist hours</h5>
              <div className="row">
                <div className="col-3 col-sm-2 col-md-2">
                  <p className="m-0">Mon</p>
                  <p className="m-0">Tue</p>
                  <p className="m-0">Web</p>
                  <p className="m-0">Thu</p>
                  <p className="m-0">Fri</p>
                  <p className="m-0">Sat</p>
                  <p className="m-0">Sun</p>
                </div>
                <div className="col-6 col-sm-6 col-md-6">
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                  <p className="m-0">10.00am - 10.00pm</p>
                </div>
              </div>
              <Button
                disabled={isSwitchActive}
                onClick={() => setShowOpeningHours(true)}
                variant="primary mt-2 rounded-5"
              >
                Set waitlist hours
              </Button>
              <OpeningHours
                show={showOpeningHours}
                onHide={() => setShowOpeningHours(false)}
              />

              <h5 className="mt-3 mb-2">Specific dates</h5>

              <Button
                disabled={isSwitchActive}
                onClick={() => setShowHoursByDate(true)}
                variant="primary mt-2 rounded-5"
              >
                Set hours by date
              </Button>
              <HoursByDate
                show={showHoursByDate}
                onHide={() => setShowHoursByDate(false)}
              ></HoursByDate>
              <Form className="py-2">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Same as Opening Hours"
                  onChange={() => setIsSwitchActive(!isSwitchActive)}
                />
              </Form>
            </div>
          </div>
          <Card.Footer className="mt-3" />
        </Card.Body>
      </Card>
    </>
  );
};

export default Waitlist;
