import { get, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";


export async function getTodaysWaitlist() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/waitlists/today",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// Get all the waitlist
export async function getAllWaitlist() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/waitlists",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// Update waitlist status
export async function updateWaitlistStatus({itemId, data}) {
  console.log("itemId", itemId, "data", data)
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/waitlists/${itemId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log(response)
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// get serving waitlist
export async function getServingWaitlist() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/servings",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// get Completed waitlist
export async function getCompletedWaitlist() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/waitlists/today-completed",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}