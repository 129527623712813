import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkEmail,
  createEmployee,
  deleteEmployee,
  getEmployeeById,
  getEmployees,
  getUserProfile,
  updateEmployee,
} from "./employeeAPI";

// get all employee
export const fetchAsyncAllEmployee = createAsyncThunk(
  "employee/fetchEmployee",
  async () => {
    const employees = await getEmployees();
    return employees;
  }
);

// get employee by id
export const fetchAsyncEmployeeById = createAsyncThunk(
  "employee/fetchEmployeeById",
  async (id) => {
    const employee = await getEmployeeById(id);
    return employee;
  }
);

// create employee
export const createAsyncEmployee = createAsyncThunk(
  "employee/createEmployee",
  async (data) => {
    const response = await createEmployee(data);
    return response;
  }
);

// delete employee
export const deleteAsyncEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (id) => {
    const response = await deleteEmployee(id);
    return response;
  }
);

// update employee
export const updateAsyncEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async (data) => {
    const response = await updateEmployee(data);
    return response;
  }
);

// Email validation
export const checkAsyncEmail = createAsyncThunk(
  "employee/checkEmail",
  async (email) => {
    const response = await checkEmail(email);
    return response;
  }
);

// User Profile
export const fetchAsyncUserProfile = createAsyncThunk(
  "employee/fetchUserProfile",
  async () => {
    const response = await getUserProfile();
    return response;
  }
);

const initialState = {
  // Data
  allEmployee: [],
  singleEmployee: {},
  // Status
  fetchStatus: "idle",
  fetchSingleStatus: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  // Errors
  fetchError: null,
  createError: null,
  updateError: null,
  deleteError: null,
  // Email
  email: null,
  emailStatus: "idle",
  emailError: null,
  // User Profile
  userProfile: {},
  userProfileStatus: "idle",
};
const EmployeeSlice = createSlice({
  name: "Employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get All Employee
      .addCase(fetchAsyncAllEmployee.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(fetchAsyncAllEmployee.fulfilled, (state, { payload }) => {
        state.allEmployee = payload;
        state.fetchStatus = "success";
      })
      .addCase(fetchAsyncAllEmployee.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Get Single Employee
      .addCase(fetchAsyncEmployeeById.pending, (state) => {
        state.fetchSingleStatus = "loading";
        state.fetchError = null;
      })
      .addCase(fetchAsyncEmployeeById.fulfilled, (state, { payload }) => {
        state.singleEmployee = payload;
        state.fetchSingleStatus = "success";
      })
      .addCase(fetchAsyncEmployeeById.rejected, (state, action) => {
        state.fetchSingleStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Create Employee
      .addCase(createAsyncEmployee.pending, (state) => {
        state.createStatus = "loading";
        state.createError = null;
      })
      .addCase(createAsyncEmployee.fulfilled, (state, { payload }) => {
        state.createStatus = "success";
        state.allEmployee.push(payload);
      })
      .addCase(createAsyncEmployee.rejected, (state, action) => {
        state.createStatus = "failed";
        state.createError = action.error.message;
      })
      // Update Employee
      .addCase(updateAsyncEmployee.pending, (state) => {
        state.status = "loading";
        state.updateError = null;
      })
      .addCase(updateAsyncEmployee.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.allEmployee = state.allEmployee.map((employee) =>
          employee.id === payload.id ? payload : employee
        );
      })
      .addCase(updateAsyncEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.updateError = action.error.message;
      })
      // Delete Employee
      .addCase(deleteAsyncEmployee.pending, (state) => {
        state.status = "loading";
        state.deleteError = null;
      })
      .addCase(deleteAsyncEmployee.fulfilled, (state, { payload }) => {
        console.log("payload", payload);
        state.status = "success";
        state.allEmployee = state.allEmployee.filter(
          (employee) => employee.id !== payload.id
        );
      })
      .addCase(deleteAsyncEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.deleteError = action.error.message;
      })
      // Email Validation
      .addCase(checkAsyncEmail.pending, (state) => {
        state.emailStatus = "loading";
        state.emailError = null;
      })
      .addCase(checkAsyncEmail.fulfilled, (state, { payload }) => {
        state.emailStatus = "success";
        state.email = payload;
      })
      .addCase(checkAsyncEmail.rejected, (state, action) => {
        state.emailStatus = "failed";
        state.emailError = action.error.message;
      })
      // User Profile
      .addCase(fetchAsyncUserProfile.pending, (state) => {
        state.userProfileStatus = "loading";
      })
      .addCase(fetchAsyncUserProfile.fulfilled, (state, { payload }) => {
        state.userProfileStatus = "success";
        state.userProfile = payload;
      })
      .addCase(fetchAsyncUserProfile.rejected, (state, action) => {
        state.userProfileStatus = "failed";
      });
  },
});

export const getStatus = (state) => state.Employee.status;
export default EmployeeSlice.reducer;
