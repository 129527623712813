import React from "react";
import { Button, Modal } from "react-bootstrap";

const dummyData = [
  { employeeName: "John Doe", status: "online" },
  { employeeName: "Jane Smith", status: "away" },
  { employeeName: "Bob Johnson", status: "online" },
  { employeeName: "Alice Brown", status: "away" },
  { employeeName: "Chris Wilson", status: "online" },
];

const EmployeeStatusModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showModal}
      onHide={props.handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Employee Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <tbody>
            {dummyData.map((employee, index) => (
              <tr key={index}>
                <td>
                  <div className="avatar-circle text-center d-flex align-items-center justify-content-center">
                    {employee.employeeName.charAt(0)}
                  </div>
                </td>
                <td className="text-start">{employee.employeeName}</td>
                <td className="text-end">
                  <span
                    className={`status-circle ${
                      employee.status === "online" ? "online" : "away"
                    }`}
                  ></span>
                  <select
                    value={employee.status}
                    onChange={(e) => console.log(e.target.value)}
                    className="px-2 rounded-3"
                    style={{
                      lineHeight: "40px",
                      color: "#7e7e7e",
                      paddingLeft: " 15px",
                    }}
                  >
                    <option value="online">Online</option>
                    <option value="away">Away</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeStatusModal;
