import React, { useState } from "react";
import { BiSolidBellRing } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import CardDetailsOffCanvas from "./CardDetailsOffCanvas";
import {
  fetchServiceWaitlist,
  updateAsyncWaitlistStatus,
} from "../../../store/waitlist/waitlistSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { updateAsyncReservationStatus } from "../../../store/reservation/reservationSlice";

import { CiCalendarDate } from "react-icons/ci";
import { IoTimeOutline } from "react-icons/io5";
import { fetchCompleted } from "../../../store/complete/completeSlice";
import { createCustomerSms } from "../../../store/customerSms/customerSmsSlice";

let today = new Date();
let date = new Date(today.getTime() - (today.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
console.log(date);

const Card = ({ item, name }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [itemId, setItemId] = useState("");
  const handleCardClick = (id) => {
    setItemId(id);
    setShow(true);
  };

  const handleStatus = (itemId, event) => {
    event.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (item.status === "Confirmed") {
          dispatch(async (dispatch, getState) => {
            await dispatch(
              updateAsyncReservationStatus({
                itemId: itemId,
                data: { status: "Serving" },
              })
            );
            dispatch(fetchServiceWaitlist());
          });
        } else if (item.status === "Serving") {
          if (item.servingType === "reservation") {
            dispatch(async (dispatch, getState) => {
              await dispatch(
                updateAsyncReservationStatus({
                  itemId: itemId,
                  data: { status: "Completed" },
                })
              );
              dispatch(fetchServiceWaitlist());
              dispatch(fetchCompleted());
            });
          } else if (item.servingType === "waitlist") {
            dispatch(async (dispatch, getState) => {
              await dispatch(
                updateAsyncWaitlistStatus({
                  itemId: itemId,
                  data: { status: "Completed" },
                })
              );
              dispatch(fetchServiceWaitlist());
              dispatch(fetchCompleted());
            });
          } else {
            console.log("Please check service type");
          }
        } else if (item.status === "Waiting") {
          dispatch(async (dispatch, getState) => {
            await dispatch(
              updateAsyncWaitlistStatus({
                itemId: itemId,
                data: { status: "Serving" },
              })
            );
            dispatch(fetchServiceWaitlist());
          });
        }
      }
    });
  };

  const handleSendSms = (item, event) => {
    event.stopPropagation();
    const data = {
      customerName: item?.name,
      phone: item?.customer?.phone,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send SMS?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(createCustomerSms(data));
      }
    });
  };

  // Convert to 12 hour
  function convertTo12Hour(time) {
    let date = new Date();
    date.setHours(time.split(":")[0]);
    date.setMinutes(time.split(":")[1]);
    date.setSeconds(time.split(":")[2]);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes + " " + ampm;
  }

  return (
    <>
      <div className="mt-4">
        <div
          onClick={() => handleCardClick(item?.id)}
          className="bg-white rounded-3 p-3 cursor-pointer"
        >
          <div className="row justify-content-between align-items-center">
            <h4 className="mb-0 col-8 col-lg-6">
              {item.status === "Waiting"
                ? item.name
                : item.status === "Serving"
                ? item.customerName
                : item.name}
            </h4>
            <div className="col-4 col-lg-6 text-end">
              {name === "completed" ? (
                ""
              ) : name === "serving" ? (
                <>
                  <FaCheckCircle
                    onClick={(event) => handleStatus(item?.id, event)}
                    className="text-success ms-1 fs-2 cursor-pointer"
                  ></FaCheckCircle>
                </>
              ) : name === "waitlist" ? (
                // Add your JSX for the "waitlist" condition here
                <>
                  <BiSolidBellRing
                    onClick={(event) => handleSendSms(item, event)}
                    className="fs-2 cursor-pointer"
                  />
                  <FaCheckCircle
                    onClick={(event) => handleStatus(item?.id, event)}
                    className="text-success ms-1 fs-2 cursor-pointer"
                  ></FaCheckCircle>
                </>
              ) : (
                <>
                  {item.reservationDate === date? (
                    <FaCheckCircle
                      onClick={(event) => handleStatus(item?.id, event)}
                      className="text-success ms-1 fs-2 cursor-pointer"
                    ></FaCheckCircle>
                  ) : null}
                </>
              )}
            </div>

            <p className="my-2 visit-service">
              {item.status === "Confirmed"
                ? item.service.name
                : item.status === "Waiting"
                ? item.service.name
                : item.status === "Completed"
                ? item.service.name
                : item.serviceName}
            </p>
            <div>
              <div>
                <p className="visit-employee d-inline-block mb-0">
                  {item.status === "Serving"
                    ? item?.employeeName
                    : item?.employee?.name}
                </p>
              </div>
            </div>
            {name === "booking" ? (
              <div className="d-inline-flex align-content-center justify-content-between mt-3 mb-0">
                <div className="d-flex align-content-center justify-content-center">
                  <span>
                    <CiCalendarDate className="fs-4 me-1" />
                  </span>
                  <p>{item?.reservationDate}</p>
                </div>
                <div className="d-flex align-content-center justify-content-center">
                  <span>
                    <IoTimeOutline className="fs-4 me-1" />
                  </span>
                  <p>{convertTo12Hour(item?.reservationTime)}</p>
                </div>
              </div>
            ) : name === "waitlist" ? (
              <div className="d-flex mt-3">
                <span>
                  <IoTimeOutline className="fs-4 me-1" />
                </span>
                <p>{item?.waitingTime} Minutes</p>
              </div>
            ) : null}
          </div>
        </div>
        {/* OffCanvas */}
        <CardDetailsOffCanvas
          show={show}
          item={item}
          itemId={itemId}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default Card;
