import { get } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

// get Revenue(Donut Chart)
export async function getRevenue() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/revenue",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error.message);
  }
}

// Get monthly revenue
export async function getMonthlyRevenue() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/revenue/monthly",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// Get total analytics
export async function getTotalAnalytics() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/data/cart",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}


