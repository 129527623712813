import React, { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import cameraIcon from "../../../images/photo-camera.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getAsyncBarberShopInfo,
  putAsyncBarberShopInfo,
} from "../../../store/barberInfo/barberInfoSlice";
import GeneralProfile from "./GeneralProfile";
import GeneralHome from "./GeneralHome";
import GeneralAbout from "./GeneralAbout";
import GeneralSocial from "./GeneralSocial";
import GeneralService from "./GeneralService";
import GeneralContact from "./GeneralContact";
import GeneralGallery from "./GeneralGallery";
import Loading from "../../components/Loading";
import GeneralWorkingHours from "./GeneralWorkingHours";
import TemplateMessage from "./TemplateMessage";
import Swal from "sweetalert2";
import {
  createAsyncMap,
  fetchMapLink,
} from "../../../store/mapLink/mapLinkSlice";

const General = () => {
  const dispatch = useDispatch();
  const [businessName, setBusinessName] = useState("");
  const [image, setImage] = useState("" || cameraIcon);
  const [base64, setBase64] = useState("");
  const [loading, setLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [mapLink, setMapLink] = useState("");
  const [mapIframe, setMapIframe] = useState("");

  useEffect(() => {
    dispatch(getAsyncBarberShopInfo());
    dispatch(fetchMapLink());
  }, [dispatch]);
  const barberInfo = useSelector((state) => state.BarberInfo.barberInfo);
  const mapLinkData = useSelector((state) => state.Map.mapLink);
  const status = useSelector((state) => state.BarberInfo.fetchStatus);

  useEffect(() => {
    if (mapLinkData) {
      setMapLink(mapLinkData.mapLink);
      setMapIframe(mapLinkData.ifremLink);
    }
  }, [mapLinkData]);

  const submitName = async () => {
    try {
      setLoading(true);
      await dispatch(putAsyncBarberShopInfo({ name: businessName }));
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your info has been updated successfully.",
      });
    } catch (error) {
      console.error("Error updating info:", error);
    } finally {
      setLoading(false);
    }
  };

  const submitLogo = async () => {
    try {
      if (!base64) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please choose a photo for the logo!",
        });
        return;
      }

      setLogoLoading(true);
      await dispatch(putAsyncBarberShopInfo({ logo: base64 }));

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Logo has been updated successfully.",
      });

      setBase64("");
    } finally {
      setLogoLoading(false);
    }
  };

  // Update state when the data is fetched
  useEffect(() => {
    if (barberInfo) {
      setBusinessName(barberInfo.name);
      setImage(barberInfo.url);
    }
  }, [barberInfo]);

  // Handle Image Upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileNameClean = reader.result.split("base64,")[1];
        setBase64(fileNameClean);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("No file selected");
    }
  };

  // Map link
  const postMapLink = async (e) => {
    e.preventDefault();
    try {
      const data = {
        mapLink: mapLink,
        ifremLink: mapIframe,
      };
      await dispatch(createAsyncMap(data));
    } catch (error) {
      console.error("Error posting map link:", error);
    }
  };

  return (
    <>
      <Card className="m-3">
        <Card.Header as="h5">General setting</Card.Header>
        {status === "loading" ? (
          <Loading status={status} />
        ) : (
          <Card.Body>
            <div className="row">
              <div className="col-md-3">
                <h5>Location</h5>
                <p className="text-muted">
                  Your location name abss is displayed across many areas
                  including on your online pages and messages to client
                </p>
              </div>
              <div className="col-md-9">
                <form onSubmit={postMapLink}>
                  <h5>Location URL</h5>
                  <div className="row align-items-center g-3">
                    <div className="col-12 col-md-8">
                      <input
                        value={mapLink}
                        onChange={(e) => setMapLink(e.target.value)}
                        className="form-control rounded-1"
                        type="text"
                      />
                    </div>
                  </div>
                  <h5 className="mt-4">Location iframe</h5>
                  <div className="row align-items-center g-3">
                    <div className="col-12 col-md-8">
                      <input
                        value={mapIframe}
                        onChange={(e) => setMapIframe(e.target.value)}
                        className="form-control rounded-1"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mt-2">
                    <Button type="submit" variant="primary rounded-5">
                      Save
                    </Button>
                  </div>
                </form>
                <h5 className="mt-4">Company Logo</h5>
                <p className="text-muted">
                  Your company logo is displayed across many areas including on
                  your online pages and messages to clients
                </p>
                <div className="d-flex align-items-center mb-4">
                  <img
                    src={image}
                    alt="company logo"
                    className="mr-2 border rounded-1 p-2 me-4"
                    style={{ height: "100px", width: "100px" }}
                  />
                  <input
                    onChange={handleFileChange}
                    className="form-control w-25"
                    type="file"
                  ></input>
                  <button
                    onClick={() => submitLogo()}
                    className="btn btn-primary rounded-5 ms-4"
                  >
                    {logoLoading === true ? (
                      <div className="d-flex align-items-center justify-content-center ">
                        <span className="me-2">Initiated</span>
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
            <Card.Footer className="mt-3" />
            <GeneralWorkingHours />

            <Card.Footer className="mt-3" />
            <div className="row">
              <div className="col-md-3">
                <h5>Business info</h5>
                <p className="text-muted">
                  Your location name abss is displayed across many areas
                  including on your online pages and messages to client
                </p>
              </div>
              <div className="col-md-9">
                <h5>Business Name</h5>
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="form-control"
                />
                <h5 className="mt-2">Business Email</h5>
                <p>mahmoud@abss.tech</p>
                <h5>Business Phone</h5>
                <p>+961 3 787 666</p>
                <button
                  onClick={() => {
                    submitName();
                  }}
                  className="btn btn-outline-primary rounded-5"
                >
                  {loading === true ? (
                    <div className="d-flex align-items-center justify-content-center ">
                      <span className="me-2">Initiated</span>
                      <Spinner animation="border" size="sm" />
                    </div>
                  ) : (
                    "Save Business info"
                  )}
                </button>
              </div>
            </div>
            <Card.Footer className="mt-3" />
            <GeneralProfile />
            <GeneralHome />
            <GeneralAbout />
            <GeneralService />
            <GeneralGallery />
            <GeneralContact />
            <GeneralSocial />
            <TemplateMessage />
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default General;
