import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post, put } from "aws-amplify/api";
import { amplifyConfigs } from "../config";
import Swal from "sweetalert2";
import { boolean } from "yup";

// Create working hours
export const createAsyncWorkingHours = createAsyncThunk(
  "workingHours/createWorkingHours",
  async ({data}) => {
    console.log("Data", data)
    try {
      const restOperation = post({
        apiName: amplifyConfigs.apiName,
        path: "/barbers/working-hour",
        options: {
          body: data,
        },
      });
      const response = await restOperation.response;
      console.log("POST call succeeded: ", response);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Working Hours created successfully!",
      });
      return response.body.json();
    } catch (error) {
      console.log("POST call failed: ", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
    }
  }
);

// Get working hours
export const fetchAsyncWorkingHours = createAsyncThunk(
  "workingHours/fetchWorkingHours",
  async () => {
    try {
      const restOperation = get({
        apiName: amplifyConfigs.apiName,
        path: "/barbers/working-hour",
      });
      const response = await restOperation.response;
      return response.body.json();
    } catch (error) {
      console.log("Get call failed: ", error);
    }
  }
);

// Open and Close Barbershop
export const openCloseBarbershop = createAsyncThunk(
  "workingHours/openCloseBarbershop",
  async (data) => {
    try {
      const restOperation = put({
        apiName: amplifyConfigs.apiName,
        path: "/barbers/shop-open",
        options: {
          body: data,
        },
      });
      const response = await restOperation.response;
      console.log("POST call succeeded: ", response);
      return response.body.json();
    } catch (error) {
      console.log("POST call failed: ", error);
    }
  }
);



const initialState = {
  workingHours: [],
  createWorkingHours: "",
  barbershopOpenOrClose: boolean,
  status: "idle",
  error: null,
};

const workingHoursSlice = createSlice({
  name: "workingHours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAsyncWorkingHours.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAsyncWorkingHours.fulfilled, (state, { payload }) => {
        state.createWorkingHours = payload;
        state.status = "success";
      })
      .addCase(createAsyncWorkingHours.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAsyncWorkingHours.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncWorkingHours.fulfilled, (state, { payload }) => {
        // Here, you can add a condition to check if the payload is not empty or null
        // before assigning it to state.workingHours
        if (payload) {
          state.workingHours = payload;
        }
        state.status = "success";
      })
      .addCase(fetchAsyncWorkingHours.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Open and Close Barbershop
      .addCase(openCloseBarbershop.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(openCloseBarbershop.fulfilled, (state, { payload }) => {
        state.barbershopOpenOrClose = payload;
        state.status = "success";
      })
      .addCase(openCloseBarbershop.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default workingHoursSlice.reducer;
