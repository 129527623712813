import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  FetchSingleExpense,
  UpdateAsyncExpense,
  deleteAsyncExpense,
} from "../../../store/expenseTracker/ExpenseSlice";
import { useSelector } from "react-redux";

const ExpenseHistoryModal = (props) => {
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const singleId = props.selectedId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchSingleExpense(singleId));
  }, [dispatch, singleId]);
  const singleExpense = useSelector((state) => state.Expense.singleExpense);
  const status = useSelector((state) => state.Expense.singleExpenseStatus);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      amount,
      date,
      title,
      description,
    };
    dispatch(UpdateAsyncExpense({ data, singleId }));
  };

  // Delete expense
  const handleDelete = () => {
    dispatch(deleteAsyncExpense(singleId));
    props.onHide();
  };

  useEffect(() => {
    if (singleExpense) {
      setAmount(singleExpense.amount);
      setDate(singleExpense.expenseDate);
      setTitle(singleExpense.title);
      setDescription(singleExpense.description);
    }
  }, [singleExpense]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Expense History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === "loading" ? (
          <div>
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <form id="formSubmit" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-12 col-lg-6">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="col-12 col-lg-6">
                <label>Amount</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="col-12 mt-2">
                <label>Title</label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-12 mt-2">
                <label>Description</label>
                <textarea
                  type="text"
                  id="description"
                  className="form-control"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </form>
        )}
        {/* <form id="formSubmit" onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <label htmlFor="Date" />
              <input
                type="date"
                className="form-control"
                placeholder="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="col-12 col-lg-6">
              <label htmlFor="Amount" />
              <input
                type="text"
                className="form-control"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="col-12">
              <label htmlFor="title" />
              <input
                type="text"
                id="title"
                className="form-control"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="col-12">
              <label htmlFor="description" />
              <textarea
                type="text"
                id="description"
                className="form-control"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
        </form> */}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <button
          id="handleFormSubmit"
          className="btn btn-danger"
          onClick={() => handleDelete()}
        >
          Delete
        </button>
        <button
          id="handleFormSubmit"
          form="formSubmit"
          type="submit"
          className="btn btn-primary"
          onClick={props.onHide}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExpenseHistoryModal;
