import { get, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

// barbershop info get Data
export async function getBarberShopInfo() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/info",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// barberShop Info post Data
export async function putBarberShopInfo({ name, logo }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/info",
      options: { body: { name, logo } }
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Put call failed: ", error);
  }
}
