import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Swal from "sweetalert2";
import {
  deleteAsyncReservation,
  deleteAsyncWaitlist,
} from "../../../store/bookingAndWaitlist/bookingAndWaitlistSlice";
import { useDispatch } from "react-redux";
import { fetchTodaysWaitlist } from "../../../store/waitlist/waitlistSlice";
import { fetchTodaysReservation } from "../../../store/reservation/reservationSlice";

const CardDetailsOffCanvas = ({ show, handleClose, item }) => {
  const dispatch = useDispatch();

  const handleCancel = async () => {
    const willCancel = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });

    if (willCancel.isConfirmed) {
      try {
        if (item.status === "Waiting") {
          handleClose();
          Swal.fire({
            position: "top-end",
            title: "Wait a moment",
            showConfirmButton: false,
            icon: "info",
            timer: 2000,
            toast: true,
          });
          await dispatch(deleteAsyncWaitlist(item.id));
          await dispatch(fetchTodaysWaitlist());
        } else {
          handleClose();
          Swal.fire({
            position: "top-end",
            title: "Wait a moment",
            showConfirmButton: false,
            icon: "info",
            timer: 2000,
            toast: true,
          });
          await dispatch(deleteAsyncReservation(item.id));
          await dispatch(fetchTodaysReservation());
        }
        // handleClose();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        {/* <Offcanvas.Title>{item?.name}</Offcanvas.Title> */}
        <Offcanvas.Title>
          {item.status === "Confirmed" ? item.name : item.customerName}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <hr />
      <Offcanvas.Body>
        <div className="d-flex gap-5">
          <div>
            <p>Name</p>
            <p>Status</p>
            <p>Phone</p>
            {item.status === "Confirmed" && <p>Price</p>}
            <p>Service Duration</p>
            {/* <p>Staff</p> */}
            <p>Service</p>
          </div>
          <div>
            <p>
              {item.status === "Waiting"
                ? item.name
                : item.status === "Serving"
                ? item.customerName
                : item.name}
            </p>
            <p>{item?.status}</p>
            <p>
              {item.status === "Serving"
                ? item.customerPhoneNumber
                : item?.customer?.phone}
            </p>
            {item?.status === "Confirmed" && (
              <p>$ {item?.service?.reservationPriceInDollar}</p>
            )}
            <p>
              {item.status === "Serving"
                ? item.serviceDuration
                : item?.service?.durationInMinutes}{" "}
              Minutes
            </p>
            {/* <p>{item?.employee?.name}</p> */}
            <p>
              {item.status === "Confirmed"
                ? item.service.name
                : item.status === "Waiting"
                ? item.service.name
                : item.status === "Completed"
                ? item.service.name
                : item.serviceName}
            </p>
          </div>
        </div>
      </Offcanvas.Body>
      <hr />
      <div className="d-flex justify-content-between px-3 pb-3">
        <button
          className="btn btn-light text-black"
          onClick={() => handleCancel()}
        >
          {item.status === "Waiting" ? "Cancel Waitlist" : "Cancel Reservation"}
        </button>
        <button className="btn btn-success">Serve</button>
      </div>
    </Offcanvas>
  );
};

export default CardDetailsOffCanvas;
