import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import {
  fetchAsyncGetExpense,
  fetchAsyncGetTotalExpense,
} from "../../../../store/discovery/discoverySlice";
import Loading from "../../Loading";

const OrderSummary = () => {
  const dispatch = useDispatch();

  // Get All Expense
  useEffect(() => {
    dispatch(fetchAsyncGetExpense());
    dispatch(fetchAsyncGetTotalExpense());
  }, [dispatch]);
  const totalExpense = useSelector((state) => state.Discovery.totalExpense);
  const status = useSelector((state) => state.Discovery.expenseStatus);

  let options = {};
  let series = [];

  if (totalExpense && totalExpense.ExpenseTotals) {
    options = {
      labels: Object.keys(totalExpense.ExpenseTotals),
    };
    series = Object.values(totalExpense.ExpenseTotals);
  }

  // Define your colors
  const colors = ["#008FFB", "#00E396", "#FEB019", "#FF4560"];
  // Map each title to a color
  const titleColorMap =
    totalExpense && totalExpense.ExpenseTotals
      ? Object.keys(totalExpense.ExpenseTotals).reduce((acc, title, index) => {
          acc[title] = colors[index % colors.length];
          return acc;
        }, {})
      : {};
  let total = 0;
  if (totalExpense && totalExpense.ExpenseTotals) {
    options = {
      labels: Object.keys(totalExpense.ExpenseTotals),
    };
    series = Object.values(totalExpense.ExpenseTotals);
    total = series.reduce((a, b) => a + b, 0);
  }

  return (
    <div className="card">
      <div className="card-header border-0 pb-0">
        <h3 className="h-title">Order Summary</h3>
      </div>
      <div className="card-body ">
        {status === "loading" ? (
          <Loading />
        ) : (
          <div className="row">
            <div className="col-xl-6">
              <div className="position-relative" id="AllProject">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="donut"
                  width="100%"
                  className="d-flex justify-content-center"
                />
                <div className="position-absolute chart-text">
                  <p className="fw-bold fs-3">${totalExpense.total}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              {totalExpense &&
                totalExpense.ExpenseTotals &&
                Object.entries(totalExpense.ExpenseTotals).map(
                  ([title, amount], index) => (
                    <div className="mb-4" key={index}>
                      <div className="progress">
                        <div
                          className={`progress-bar linear`}
                          style={{
                            width: `${(amount / total) * 100}%`,
                            height: "13px",
                            background: titleColorMap[title],
                          }}
                        >
                          <span className="sr-only">{`${
                            (amount / total) * 100
                          }% Complete`}</span>
                        </div>
                      </div>
                      <span className="d-flex align-items-center mt-2">
                        <span>
                          <svg
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              y="0.420288"
                              width="13"
                              height="13"
                              rx="4"
                              fill={titleColorMap[title]}
                            />
                          </svg>
                          <span className="mb-0 ms-2 text-dark">{title}</span>
                        </span>
                        <span className="ms-auto font-w600">${amount}</span>
                      </span>
                    </div>
                  )
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
