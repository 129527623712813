
export const amplifyConfigs = {
  userPoolId: 'eu-central-1_2I9ClaGVt',
  userPoolClientId: '7a7857veemqv1e3v1k7kctv0qn',
  apiName: 'live-clients-api',
  apiURL: 'https://2wi9j8ors3.execute-api.eu-central-1.amazonaws.com/live',
  apiRegion: 'undefined',
  identityPoolId: 'eu-central-1:3fe294f7-2954-4198-a72c-3c70fffe34f4',
  AppSync: {
    GraphQL: {
      endpoint: 'https://xnuogii7vzaurlfmigqbntb724.appsync-api.eu-central-1.amazonaws.com/graphql',
      region: 'eu-central-1',
      defaultAuthMode: 'API_KEY',
      apiKey: 'da2-36ikssg5zfeodjmyqojukeglvu',
    }
  }
};
