

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchContact = createAsyncThunk(
  "contact/fetchContact",
  async () => {
    try {
      const res = await axios.get(
"https://6ebf3qyj84.execute-api.us-east-1.amazonaws.com/test/barbershop/contact",
        {
          headers: {
            barberId: "03ab1d12-53fa-49b1-8a48-6a1cba17d96f",
          },
        }
      );
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error.message);
    }
  }
)


const initialState = {
  contact : [],
  status : "idle",
  error : null
}


const contactSlice = createSlice({
  name : "contact",
  initialState,
  reducers : {},
  extraReducers : (builder) => {
    builder
      .addCase(fetchContact.pending, (state) => {
        state.status = "loading"
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.contact = action.payload
      })
      .addCase(fetchContact.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  }
})

export default contactSlice.reducer