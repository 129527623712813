import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import CalendarModal from "./CalendarModal";
import interactionPlugin from "@fullcalendar/interaction"; // for clickable events

const HoursByDate = (props) => {
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const myEvents = [
      {
        id: "1",
        title: "9:00 AM - 5:00 PM",
        start: "2024-03-07",
        end: "2024-03-07",
        eventClassNames: ["custom-pointer-cursor"],
        color: "gray", // Green for available times
        extendedProps: {
          description: "Open for appointments",
          type: "open",
        },
      },
      {
        id: "2",
        title: "9:00 AM - 5:00 PM",
        start: "2024-03-08",
        end: "2024-02-03",
        eventClassNames: ["custom-pointer-cursor"],
        color: "gray", // Red for closed times
        extendedProps: {
          description: "Closed all day",
          type: "closed",
        },
      },
    ];
    setEvents(myEvents);
  }, []);

  const handleDateClick = (arg) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const clickedDate = new Date(arg.date);
    if(clickedDate < today) {
      console.log("This date is in the past!");
    } else {
      console.log("Clicked on: " + arg.dateStr);
    }
  };

  const handleEventClick = (e) => {
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const clickedDate = new Date(e.event._instance.range.start);
    if(clickedDate < today) {
      console.log("This date is in the past!");
    } else {
      setSelectedEvent(e.event);
      setModalOpen(true);
    }
  };

  return (
    <Modal
      fullscreen={true}
      {...props}
      // size="full-screen"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Calendar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            editable={false}
            events={events}
            eventClick={handleEventClick}
          />
          {modalOpen && (
            <CalendarModal
              show={modalOpen}
              event={selectedEvent}
              onHide={() => setModalOpen(false)}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HoursByDate;
