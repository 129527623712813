import { amplifyConfigs } from "../../config";
import { get, post, put } from "aws-amplify/api";

export async function getBarberServiceInfo() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/service-header",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

export async function putBarberServiceInfo({ image }) {
  
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/service-header",
      options: { body: {image} }
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Put call failed: ", error);
  }
}
