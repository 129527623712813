import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarModal from "../../components/ScheduleModals/CalendarModal";
import { useDispatch } from "react-redux";
import { fetchEmployeeSpecialWorkingHours } from "../../../store/employeeWorkingHours/employeeSpecialHoursSlice";
import { useSelector } from "react-redux";

const EmployeeSpecialWorkingHoursModal = (props) => {
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const employeeId = props.employeeId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployeeSpecialWorkingHours(employeeId));
  }, [dispatch, employeeId]);

  const specialWorkingHours = useSelector(
    (state) => state.EmployeeSpecialWorking.employeeSpecialWorkingHours
  );
  console.log("specialWorkingHours", specialWorkingHours);

  useEffect(() => {
    if (specialWorkingHours !== undefined) {
      const myEvents = specialWorkingHours?.map((event) => {
        return {
          id: event.id,
          title: `${event.startTime} - ${event.endTime}`,
          start: event.date,
          end: event.date,
          eventClassNames: ["custom-pointer-cursor"],
          color: "gray",
          extendedProps: {
            description: "Special Working Hours",
            type: "special",
          },
        };
      });
      setEvents(myEvents);
    }
  }, [specialWorkingHours]);

  const handleEventClick = (e) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const clickedDate = new Date(e.event._instance.range.start);
    if (clickedDate < today) {
      console.log("This date is in the past!");
    } else {
      setSelectedEvent(e.event);
      setModalOpen(true);
    }
    
  };

  const OpenModalForSpecialWorkingTime = () => {
    console.log("Add Special Working Hours");
    setSelectedEvent(null); // Reset selected event, assuming null is a safe default
    setModalOpen(true);
  };

  return (
    <Modal
      fullscreen={true}
      {...props}
      // size="full-screen"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Calendar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            editable={false}
            events={events}
            eventClick={handleEventClick}
          />
          {modalOpen && (
            <CalendarModal
              show={modalOpen}
              employeeId={employeeId}
              event={selectedEvent}
              onHide={() => {
                setModalOpen(false);
                setSelectedEvent(null); // Ensure clean state for next operation
              }}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={OpenModalForSpecialWorkingTime}>
          Add Special Working Hours
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeSpecialWorkingHoursModal;
