import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
import ExpenseHistoryModal from "./ExpenseHistoryModal";
import AddNewExpense from "./AddNewExpense";
import { useDispatch } from "react-redux";
import {
  FetchAllAsyncExpense,
  FetchExpenseByType,
} from "../../../store/expenseTracker/ExpenseSlice";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import ViewExpenseModal from "./ViewExpenseModal";
import Swal from "sweetalert2";

const options = [
  { value: "1", label: "Daily" },
  { value: "2", label: "Week" },
  { value: "3", label: "Month" },
];

const ExpenseHistory = () => {
  const dispatch = useDispatch();
  const [addActive, setActive] = useState("All");
  const [selectedId, setSelectedId] = useState("");
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    if (addActive === "All") {
      dispatch(FetchAllAsyncExpense()).then((data) => {
        setFetchedData(data.payload);
      });
    } else if (addActive === "Employees") {
      dispatch(FetchExpenseByType(addActive)).then((data) => {
        setFetchedData(data.payload.expenses);
      });
    } else if (addActive === "Utility") {
      dispatch(FetchExpenseByType(addActive)).then((data) => {
        setFetchedData(data.payload.expenses);
      });
    } else if (addActive === "Products") {
      dispatch(FetchExpenseByType(addActive)).then((data) => {
        setFetchedData(data.payload.expenses);
      });
    } else if (addActive === "Other") {
      dispatch(FetchExpenseByType(addActive)).then((data) => {
        setFetchedData(data.payload.expenses);
      });
    }
  }, [dispatch, addActive]);

  const expenseByType = useSelector((state) => state.Expense.expenseByType);
  const singleExpense = expenseByType.expenses;
  const allExpense = useSelector((state) => state.Expense.expense);
  const status = useSelector((state) => state.Expense.status);

  //Modal
  const [modalShow, setModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 10;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage
  const npage = Math.ceil(fetchedData?.length / recordsPage);
  const paginatedData = fetchedData?.slice(firstIndex, lastIndex);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [newOrder, setNewOrder] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");

  const handleHistoryModal = (item) => {
    if (item?.expenseType === "Products") {
      Swal.fire({
        title: "Cannot Edit",
        text: "You cannot edit a Product.",
        icon: "error",
      });
    } else {
      setSelectedId(item.id);
      setModalShow(true);
    }
  };

  const handleViewModal = (id) => {
    setSelectedId(id);
    setViewModalShow(true);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-4 flex-wrap">
        <ul className="revnue-tab nav nav-tabs" id="myTab">
          <li className="nav-item" onClick={() => setActive("All")}>
            <Link
              to={"#"}
              className={`nav-link ${addActive === "All" ? "active" : ""}`}
            >
              All
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActive("Employees")}>
            <Link
              to={"#"}
              className={`nav-link ${
                addActive === "Employees" ? "active" : ""
              }`}
            >
              Employees
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActive("Utility")}>
            <Link
              to={"#"}
              className={`nav-link ${addActive === "Utility" ? "active" : ""}`}
            >
              Utility
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActive("Products")}>
            <Link
              to={"#"}
              className={`nav-link ${addActive === "Products" ? "active" : ""}`}
            >
              Products
            </Link>
          </li>
          <li className="nav-item" onClick={() => setActive("Other")}>
            <Link
              to={"#"}
              className={`nav-link ${addActive === "Other" ? "active" : ""}`}
            >
              Other
            </Link>
          </li>
        </ul>
        <input
          type="text"
          className="form-control w-25"
          placeholder="Filter Search"
          aria-label="Search"
          aria-describedby="button-addon2"
          onChange={(e) => setSearch(e.target.value)}
        />

        <div className="d-flex align-items-center">
          <Link
            to={"#"}
            className="btn btn-primary me-2"
            onClick={() => setShowModal(true)}
          >
            + New Order
          </Link>
          <AddNewExpense show={showModal} onHide={() => setShowModal(false)} />
          <Select
            options={options}
            className="custom-react-drop-btn"
            defaultValue={options[0]}
            isSearchable={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="tab-content">
            <div className="tab-pane fade active show">
              <div className="card mt-2">
                <div className="card-body p-0">
                  <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                    <div
                      id="orderlist-table"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="empoloyees-tbl"
                        className="table dataTable no-footer"
                      >
                        <thead>
                          <tr>
                            <th className="d-flex align-items-center sorting_asc_15">
                              <div className="form-check custom-checkbox ms-0"></div>
                            </th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {status === "loading" ? (
                            <td colSpan="6">
                              <Loading status={status} />
                            </td>
                          ) : (
                            <>
                              {paginatedData?.length > 0 ? (
                                paginatedData?.filter((item) =>
                                    item.title
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  )
                                  .map((item, index) => {
                                    const getExpenseColor = () => {
                                      switch (item.expenseType) {
                                        case "Products":
                                          return "#0000FF";
                                        case "Employees":
                                          return "#FF0000";
                                        case "Utility":
                                          return "#00FF00";
                                        case "Other":
                                          return "#8C90FC";
                                        default:
                                          return "#FF8D0E";
                                      }
                                    };
                                    const expenseColor = getExpenseColor();
                                    return (
                                      <tr key={index}>
                                        <td className="sorting_25">
                                          <div
                                            className="color-box"
                                            style={{
                                              backgroundColor: expenseColor,
                                            }}
                                          ></div>
                                        </td>
                                        <td>
                                          <span>{item.expenseDate}</span>
                                        </td>
                                        <td>
                                          <span>{item.title}</span>
                                        </td>
                                        <td>
                                          <span>{item.expenseType}</span>
                                        </td>
                                        <td>
                                          <span>{item.amount}</span>
                                        </td>
                                        <td>
                                          <span className="d-flex gap-3 ">
                                            <div
                                              className="cursor-pointer"
                                              onClick={() =>
                                                handleHistoryModal(item)
                                              }
                                            >
                                              {item.expenseType ===
                                                "Products" || (
                                                <AiFillEdit className="fs-2 text-primary" />
                                              )}
                                            </div>
                                            <div
                                              className="cursor-pointer"
                                              onClick={() =>
                                                handleViewModal(item.id)
                                              }
                                            >
                                              <AiFillEye className="fs-2 text-secondary" />
                                            </div>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td colSpan="6">No data found</td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      {/* Modal Section */}
                      <ExpenseHistoryModal
                        show={modalShow}
                        selectedId={selectedId}
                        onHide={() => setModalShow(false)}
                      />
                      <ViewExpenseModal
                        show={viewModalShow}
                        selectedId={selectedId}
                        onHide={() => setViewModalShow(false)}
                      />

                      <div className="d-sm-flex text-center justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {lastIndex - recordsPage + 1} to{" "}
                          {fetchedData?.length < lastIndex
                            ? fetchedData?.length
                            : lastIndex}{" "}
                          of {fetchedData?.length} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers justify-content-center"
                          id="example2_paginate"
                        >
                          <Link
                            className="paginate_button previous disabled"
                            to="#"
                            onClick={prePage}
                          >
                            <i className="fa-solid fa-angle-left" />
                          </Link>
                          <span>
                            {number.map((n, i) => (
                              <Link
                                className={`paginate_button ${
                                  currentPage === n ? "current" : ""
                                } `}
                                key={i}
                                onClick={() => changeCPage(n)}
                              >
                                {n}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className="paginate_button next"
                            to="#"
                            onClick={nextPage}
                          >
                            <i className="fa-solid fa-angle-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={newOrder} onHide={setNewOrder} centered>
        <div className="modal-content">
          <div className="modal-header ">
            <h5 className="modal-title">Add Order</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setNewOrder(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-label">Order ID</label>
              <input
                type="number"
                className="form-control mb-3"
                id="exampleInputEmail1"
                placeholder="Order Id"
              />
              <label className="form-label">Gmail</label>
              <input
                type="email"
                className="form-control mb-3"
                id="exampleInputEmail2"
                placeholder=" Email"
              />
              <label className="form-label">Amount</label>
              <input
                type="number"
                className="form-control mb-3"
                id="exampleInputEmail3"
                placeholder="Amount"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger light"
              onClick={() => setNewOrder(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExpenseHistory;
