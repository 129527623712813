import React, { useState } from "react";
import { signIn, confirmSignIn } from "aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { passwordSchema } from "../components/ValidationSchema";

const ConfirmPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [newPasswordTouched, setNewPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const username = new URLSearchParams(location.search).get("username");
  const password = new URLSearchParams(location.search).get("password");

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);
    setNewPasswordTouched(true);
    setPasswordMatch(newPasswordValue === confirmPassword && newPasswordValue !== '' && confirmPassword !== '');
    setButtonDisabled(newPasswordValue !== confirmPassword || newPasswordValue === '' || confirmPassword === '');
  };
  
  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setConfirmPasswordTouched(true);
    setPasswordMatch(newPassword === confirmPasswordValue && newPassword !== '' && confirmPasswordValue !== '');
    setButtonDisabled(newPassword !== confirmPasswordValue || newPassword === '' || confirmPasswordValue === '');
  };

  const onLogin = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      navigate(`/login`);
      return;
    }

    try {
      await passwordSchema.validate({ password: newPassword });
    } catch (error) {
      Swal.fire("Validation Error", error.message, "error");
      return;
    }

    if (newPassword !== confirmPassword) {
      Swal.fire("Validation Error", "Password fields do not match", "error");
      return;
    }

    try {
      await signIn({ username, password });

      const confirmedUser = await confirmSignIn({
        challengeResponse: newPassword,
      });

      if (
        confirmedUser &&
        confirmedUser.isSignedIn &&
        confirmedUser.nextStep.signInStep === "DONE"
      ) {
        Swal.fire("Success", "User signed in successfully", "success");
        navigate(`/`);
      }
    } catch (error) {
      Swal.fire("Confirmation Error", error.message, "error");
    }
  };

  return (
    <div className="page-wraper">
      <div className="authincation ">
        <div className="container ">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-12 h-100 d-flex align-items-center">
              <div className="authincation-content mx-auto style-3">
                <div className="h-100">
                  <div className="auth-form">
                    <h4 className="main-title text-center pb-4">
                      Please Enter A New Password
                    </h4>
                    <form onSubmit={onLogin}>
                      <div className="form-group mb-3 pb-3">
                        <label className="font-w600">New Password</label>
                        <input
                          type="password"
                          className="form-control solid"
                          value={newPassword}
                          onChange={handleNewPasswordChange}
                        />

                        <label className="font-w600">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control solid"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                        />
                      </div>
                      {(newPasswordTouched || confirmPasswordTouched) && (
                        <div className="mb-3 pb-3">
                          <p
                            className={
                              passwordMatch ? "text-success" : "text-danger"
                            }
                          >
                            {passwordMatch
                              ? "Password matched"
                              : "Password doesn't match"}
                          </p>
                        </div>
                      )}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block rounded"
                          disabled={buttonDisabled}
                        >
                          Sign Me In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPassword;