import { del, get, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

// Get all Services by employee id
export async function getServicesByEmployeeId(employeeId) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${employeeId}/services`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed", error);
  }
}

// post service to an employee
export async function addServicesForEmployee({ id, serviceId }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${id}/services/${serviceId}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
  }
}

// Delete a Service to an employee
export async function removeServicesForEmployee({ id, serviceId }) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${id}/services/${serviceId}`,
    });
    await restOperation.response;
  } catch (error) {
    console.log("DELETE call failed: ", error);
  }
}

export async function getEmployeeByserviceId(serviceId) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/services/${serviceId}/employees`,
    });
    const response = await restOperation.response;
    console.log(response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed", error);
  }
}
