import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchAsyncBarberMonthlyRevenueData } from "../../../../store/daysAndMonthlyRevenue/dailyAndMonthlyRevenueSlice";
import { useSelector } from "react-redux";
import { FaEquals } from "react-icons/fa";

const options = {
  chart: {
    height: 300,
    type: "area",
    group: "social",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    offsetX: -16,
  },
  colors: ["var(--primary)", "#FF3D3D"],

  dataLabels: {
    enabled: false,
  },

  stroke: {
    width: [0, 0],
    colors: ["var(--primary)", "#FF6A59"],
    curve: "straight",
  },
  legend: {
    show: false,
    tooltipHoverFormatter: function (val, opts) {
      return (
        val +
        " - " +
        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
        ""
      );
    },
  },
  xaxis: {
    categories: [
      "Mon",
      "Tus",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
    ],
    labels: {
      style: {
        colors: "#3E4954",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 100,
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      offsetX: -16,
      minWidth: 40,
      style: {
        colors: "#3E4954",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 100,
      },
    },
    axisTicks: {
      show: false,
      borderType: "solid",
      color: "#78909C",
      width: 6,
      offsetX: 0,
      offsetY: 0,
    },
  },
  fill: {
    type: "solid",
    opacity: 0.9,
  },
  grid: {
    borderColor: "#f1f1f1",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  responsive: [
    {
      breakpoint: 575,
      options: {},
    },
  ],
};

const RevenueChart = () => {
  const chartRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncBarberMonthlyRevenueData());
  }, [dispatch]);

  const barberMonthlyRevenueData = useSelector(
    (state) => state.MonthlyRevenue.barberMonthlyRevenue
  );
  // console.log(barberMonthlyRevenueData, "barberMonthlyRevenueData");

  const [chartData, setChartData] = useState({
    waitlistData: [],
    reservationData: [],
  });

  useEffect(() => {
    if (barberMonthlyRevenueData) {
      const waitlistData = barberMonthlyRevenueData?.waitlistRevenue?.map(
        (item) => item.revenue
      );
      const reservationData = barberMonthlyRevenueData?.reservationRevenue?.map(
        (item) => item.revenue
      );

      setChartData({
        waitlistData,
        reservationData,
      });

      // Update chart series here
      chartRef.current.chart.ctx.updateSeries([
        {
          name: "Waitlist Revenue",
          type: "area",
          data: waitlistData,
        },
        {
          name: "Reservation Revenue",
          type: "area",
          data: reservationData,
        },
      ]);
    }
  }, [barberMonthlyRevenueData]);

  return (
    <Tab.Container defaultActiveKey={"Daily"}>
      <div className="card-header border-0 pb-0">
        <h3 className="h-title">Revenue</h3>
      </div>
      <div className="card-body pb-0">
        <div className="d-flex flex-wrap">
          <span className="d-flex align-items-center me-2">
            <svg
              className="me-2"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.108032"
                y="0.5"
                width="13"
                height="13"
                rx="4"
                fill="#1921FA"
              />
            </svg>
            Waitlist
            <h5 className="mb-0 mx-2">
              ${barberMonthlyRevenueData?.totalWaitlistRevenue || 0}
            </h5>
          </span>
          <span className="application d-flex align-items-center ms-me-5 ms-0">
            <svg
              className="me-2"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.108032"
                y="0.5"
                width="13"
                height="13"
                rx="4"
                fill="#FF3D3D"
              />
            </svg>
            Reservation
            <h5 className="mb-0 mx-2">
              ${barberMonthlyRevenueData?.totalReservationRevenue || 0}
            </h5>
          </span>
        </div>
        <div id="activityz">
          <ReactApexChart
            options={options}
            // series={series}
            series={[
              {
                name: "Waitlist Revenue",
                type: "area",
                data: chartData.waitlistData,
              },
              {
                name: "Reservation Revenue",
                type: "area",
                data: chartData.reservationData,
              },
            ]}
            ref={chartRef}
            type="area"
            height={300}
          />
        </div>
      </div>
    </Tab.Container>
  );
};
export default RevenueChart;
