import React, { useEffect, useState } from "react";
import { FaStar, FaTimes, FaTrash } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsyncGalleryImage,
  getAsyncGalleryImages,
  postAsyncGalleryImage,
  updateAsyncGalleryImage,
} from "../../store/gallery/gallerySlice";
import Swal from "sweetalert2";
import Loading from "../components/Loading";
import { Spinner } from "react-bootstrap";

const fileTypes = ["JPG", "PNG", "GIF"];

const Gallery = () => {
  // All states
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loadingImages, setLoadingImages] = useState({});
  const [deletLoading, setDeleteLoading] = useState({});
  // Modal Image Function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const openModal = (image) => {
    setSelectedImage(image);
  };

  // Function to convert file to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileNameClean = reader.result.split("base64,")[1];
        resolve(fileNameClean);
      };
      reader.onerror = (error) => {
        console.error("Error converting file to base64!", error);
        reject(error);
      };
    });
  };
  // Handle Image Upload
  const handleChange = async (file) => {
    // console.log(file[0]);
    if (file) {
      for (let i = 0; i < file.length; i++) {
        try {
          setLoadingImages((prevLoadingImages) => ({
            ...prevLoadingImages,
            [i]: true,
          }));
          // console.log(file[i]);
          let base = await convertToBase64(file[i]);
          await dispatch(
            postAsyncGalleryImage({ image: base, isFavorite: false })
          );
          Swal.fire({
            position: "top-end",
            title: "Image uploaded successfully!",
            showConfirmButton: false,
            icon: "success",
            timer: 2000,
            toast: true,
          });
        } catch (error) {
          Swal.fire("Error", "An error occurred while uploading.", "error");
        } finally {
          setLoadingImages((prevLoadingImages) => ({
            ...prevLoadingImages,
            [i]: false,
          }));
        }
      }
    }
  };

  // Get All Images
  useEffect(() => {
    dispatch(getAsyncGalleryImages());
  }, [dispatch]);
  const allImages = useSelector((state) => state.Gallery.allImages);
  const status = useSelector((state) => state.Gallery.fetchStatus);

  // Delete Image
  const deleteImage = async (id, index) => {
    const result = await Swal.fire({
      title: "Are you sure for delete?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd6b55",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        setDeleteLoading((prevLoadingImages) => ({
          ...prevLoadingImages,
          [index]: true,
        }));
        await dispatch(deleteAsyncGalleryImage(id));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        // dispatch(fetchasy(id));
      } catch (error) {
        Swal.fire("Error", "An error occurred while deleting.", "error");
        console.error("Error deleting service:", error);
      } finally {
        setDeleteLoading((prevLoadingImages) => ({
          ...prevLoadingImages,
          [index]: false,
        }));
      }
    }
  };

  // Favorite Image
  const handleFavorite = async (id, index) => {
    const galleryItem = allImages.find((item) => item.id === id);
    const updatedGalleryItem = {
      ...galleryItem,
      isFavorite: !galleryItem?.isFavorite,
    };

    const result = await Swal.fire({
      title: "Are you sure you want to change favorite status?",
      text: "This will toggle the favorite status of this image.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, change it!",
    });

    if (result.isConfirmed) {
      try {
        setLoadingImages((prevLoadingImages) => ({
          ...prevLoadingImages,
          [index]: true,
        }));
        await dispatch(updateAsyncGalleryImage(updatedGalleryItem));
        Swal.fire("Changed!", "Favorite status has been changed.", "success");
        dispatch(getAsyncGalleryImages());
      } catch (error) {
        Swal.fire(
          "Error",
          "An error occurred while changing favorite status.",
          "error"
        );
        console.error("Error updating image:", error);
      } finally {
        setLoadingImages((prevLoadingImages) => ({
          ...prevLoadingImages,
          [index]: false,
        }));
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Upload Image</h4>
              </div>
              <div className="card-body mx-auto">
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  multiple
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Light Gallery</h4>
              </div>
              <div className="card-body pb-1">
                <div id="gallery" className="row g-3 px-2 pb-5">
                  {status === "loading" ? (
                    <Loading status={status} />
                  ) : (
                    <>
                      {[...allImages]
                        ?.sort((a, b) => b.isFavorite - a.isFavorite)
                        .map((gallery, index) => (
                          <div
                            key={index}
                            className=" col-10 col-md-6 col-lg-4 col-xl-3  mx-auto"
                          >
                            <div
                              className="de-image-hover rounded mx-auto"
                              onClick={() => {
                                openModal(gallery?.url);
                                handleShow();
                              }}
                            >
                              <span className="dih-overlay"></span>
                              <img
                                src={gallery?.url}
                                className="rounded-2 mx-auto gallery-image"
                                alt=""
                              />
                            </div>
                            <div className="d-flex justify-content-between py-2">
                              <button
                                onClick={() =>
                                  handleFavorite(gallery?.id, index)
                                }
                                className={`btn  ${
                                  gallery?.isFavorite
                                    ? "btn btn-warning"
                                    : "custom-favBtn"
                                }`}
                              >
                                {loadingImages[index] === true ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <FaStar />
                                )}
                              </button>
                              <button
                                onClick={() => deleteImage(gallery?.id, index)}
                                navigate={"/gallery-page"}
                                className="btn btn-danger"
                              >
                                {deletLoading[index] === true ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <FaTrash />
                                )}
                              </button>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
              {/* Modal Section */}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className="extra-large-modal">
                  {selectedImage && (
                    <img
                      className="justify-content-center extra-large-modal img-fluid"
                      style={{ width: "100%", objectFit: "cover" }}
                      src={selectedImage}
                      alt="Selected"
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn header-btn btn-primary text-black"
                    onClick={handleClose}
                  >
                    <FaTimes />
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Gallery;
