import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createAsyncEmployeeSpecialHours,
  fetchEmployeeSpecialWorkingHours,
  updateAsyncEmployeeSpecialHours,
} from "../../../store/employeeWorkingHours/employeeSpecialHoursSlice";

const CalendarModal = ({ event, onHide, employeeId, ...props }) => {
  console.log(props.event);
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [date, setDate] = useState("");
  const getTodayDateLocal = () => {
    const now = new Date();
    const timeOffsetInMS = now.getTimezoneOffset() * 60000;
    const localDate = new Date(now - timeOffsetInMS);
    return localDate.toISOString().split('T')[0];
};

const today = getTodayDateLocal();

  useEffect(() => {
    if (event) {
      const eventDate = event._instance.range.start
        .toISOString()
        .substring(0, 10);
      setDate(eventDate);

      // Assuming the title is always in the format "HH:MM - HH:MM"
      const timePattern = /(\d{2}:\d{2}) - (\d{2}:\d{2})/;
      const match = event._def.title.match(timePattern);

      if (match) {
        const startTime = match[1]; // Extracted start time from title
        const endTime = match[2]; // Extracted end time from title

        setStartTime(startTime);
        setEndTime(endTime);
      } else {
        console.log("Time format in title is not as expected.");
        // Handle case where the title does not match the expected format
      }
    }
  }, [event]);

  const handleSubmit = async () => {
    let data = { startTime, endTime };

    if (event && event.id) {
      console.log(event);
      // If there's an event id, we're updating
      // dispatch(
      //   updateAsyncEmployeeSpecialHours({
      //     employeeId: employeeId,
      //     specialWorkingHoursId: event.id,
      //     data,
      //   })
      // );
    } else {
      // Otherwise, we're adding a new special working hour
      data = { ...data, date }; // Include date for create operation
      await dispatch(createAsyncEmployeeSpecialHours({ data, employeeId }));
    }

    onHide(); // Hide modal after submission
    dispatch(fetchEmployeeSpecialWorkingHours(employeeId));
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Calendar Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicDate">
            <div className="row align-items-center">
              {/* <div className="col-md-3">
                <Form.Label>Date</Form.Label>
              </div> */}
              <div className="col-md-12">
                <div className="d-flex align-items-center gap-5">
                  <input
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    min={today} // Set the min attribute to today's date
                    type="date"
                    className="form-control"
                  />

                  <>
                    <div>
                      <input
                        type="time"
                        className="form-control"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                      />
                    </div>
                    <span>-</span>
                    <div>
                      <input
                        type="time"
                        className="form-control"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarModal;
