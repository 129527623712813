import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncAllCategory } from "../../../store/category/categorySlice";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";

const Category = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAsyncAllCategory());
  }, [dispatch]);
  const categories = useSelector((state) => state.Category.allCategory);
  const status = useSelector((state) => state.Category.fetchStatus);

  return (
    <div className="container">
      <Link className="text-white" to="/category/add">
        <button className={`btn btn-primary`}>create a new category</button>
      </Link>
      <div className="row justify-content-center mt-3">
        {status === "loading" ? (
          <Loading status={status} />
        ) : (
          <>
            {categories &&
              categories.map((category, index) => (
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-10">
                  {category && (
                    <Link to={`/singleCategory/${category.id}`}>
                      <div className="card card-profile">
                        <div className="admin-user m-auto">
                          <div className="user-details justify-content-center">
                            <div className="title">
                              <div className="img-wrraper">
                                <img
                                  src={category.url}
                                  alt="category"
                                  className="rounded-circle"
                                />
                              </div>
                              <Link to={"#"}>
                                <h4 className="mt-3">{category.name}</h4>
                              </Link>
                              <h6 className="mt-2">
                                Services Number: {category.services?.length}
                              </h6>
                            </div>
                            <p className=""></p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Category;
