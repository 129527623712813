import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postInInventoy } from "./inventoryUserAPI";

export const createAsyncInventoryProduct = createAsyncThunk(
  "productInventory/addInInventory",
  async (data) => {
    // console.log("productId", productId)
    console.log("data", data)
    const employees = await postInInventoy(data);
    return employees;
  }
);

const initialState = {
  productInventory: [],
  status: "idle",
  error: null,
};

const InventoryUserSlice = createSlice({
  name: "InventoryUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Inventory
      .addCase(createAsyncInventoryProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAsyncInventoryProduct.fulfilled, (state, { payload }) => {
        state.productInventory = payload;
        state.status = "succeeded";
      })
      .addCase(createAsyncInventoryProduct.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      });
  },
});

export default InventoryUserSlice.reducer;
