const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const {
  getRevenue,
  getMonthlyRevenue,
  getTotalAnalytics,
} = require("./analyticsAPI");

// Get Revenue
export const fetchAsyncRevenue = createAsyncThunk(
  "analytics/fetchRevenue",
  async () => {
    const response = await getRevenue();
    return response;
  }
);

// get monthly revenue
export const fetchAsyncMonthlyRevenue = createAsyncThunk(
  "analytics/fetchMonthlyRevenue",
  async () => {
    const response = await getMonthlyRevenue();
    return response;
  }
);

// Total analytics
export const fetchAsyncTotalAnalytics = createAsyncThunk(
  "analytics/fetchTotalAnalytics",
  async () => {
    const response = await getTotalAnalytics();
    return response;
  }
);

const initialState = {
  revenue: {},
  monthlyRevenue: {},
  totalAnalytics: {},
  status: "idle",
  error: null,
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // revenue
      .addCase(fetchAsyncRevenue.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncRevenue.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.revenue = action.payload;
      })
      .addCase(fetchAsyncRevenue.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // monthly revenue
      .addCase(fetchAsyncMonthlyRevenue.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncMonthlyRevenue.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.monthlyRevenue = action.payload;
      })
      .addCase(fetchAsyncMonthlyRevenue.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // total analytics
      .addCase(fetchAsyncTotalAnalytics.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncTotalAnalytics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.totalAnalytics = action.payload;
      })
      .addCase(fetchAsyncTotalAnalytics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default analyticsSlice.reducer;
