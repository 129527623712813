import React from "react";
import { Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import CustomClearIndicator from "../../components/PluginsMenu/Select2/MultiSelect";
import { Range } from "react-range";

const AddNewExpense = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Expense
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-10 mb-3">
            <div className="example rangeDatePicker">
              <p className="mb-1">Date Range Pick</p>
              <DateRangePicker
                initialSettings={{
                  startDate: "10/5/2022",
                  endDate: "3/6/2022",
                }}
              >
                <input
                  type="text"
                  className="form-control input-daterange-timepicker"
                />
              </DateRangePicker>
            </div>
          </div>
          <div className="col-10 mb-3">
            <div>
              <label>Choose A Name</label>
              <CustomClearIndicator></CustomClearIndicator>
            </div>
          </div>
          <div className="col-10 mb-3">
            <div>
              <label>Choose A Service</label>
              <CustomClearIndicator></CustomClearIndicator>
            </div>
          </div>
          <div className="col-10 mb-3">
            <button className="btn btn-primary">Save</button>
          </div>
          <div className="col-10 mb-3">
            {/* <Range
              step={0.1}
              min={0}
              max={100}
              values={this.state.values}
              onChange={(values) => this.setState({ values })}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "6px",
                    width: "100%",
                    backgroundColor: "#ccc",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "42px",
                    width: "42px",
                    backgroundColor: "#999",
                  }}
                />
              )}
            /> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewExpense;
