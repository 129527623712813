import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHistory, getReservationHistory, getWaitlistHistory } from "./historyAPI";


// get history
export const fetchAsyncHistory = createAsyncThunk(
  "employeeServices/fetchAsyncHistory",
  async () => {
    const services = await getHistory();
    return services;
  }
);

// Waitlist history
export const fetchAsyncWaitlistHistory = createAsyncThunk(
  "employeeServices/fetchAsyncWaitlistHistory",
  async () => {
    const services = await getWaitlistHistory();
    return services;
  }
);

// Reservation history
export const fetchAsyncReservationHistory = createAsyncThunk(
  "employeeServices/fetchAsyncReservationHistory",
  async () => {
    const services = await getReservationHistory();
    return services;
  }
);



const initialState = {
  history: [],
  waitlistHistory: [],
  reservationHistory: [],
  status: "idle",
  error: null,
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // History
      .addCase(fetchAsyncHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.history = action.payload;
      })
      .addCase(fetchAsyncHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Waitlist history
      .addCase(fetchAsyncWaitlistHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncWaitlistHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.waitlistHistory = action.payload;
      })
      .addCase(fetchAsyncWaitlistHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Reservation history
      .addCase(fetchAsyncReservationHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncReservationHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reservationHistory = action.payload;
      })
      .addCase(fetchAsyncReservationHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});


export default historySlice.reducer;