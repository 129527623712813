import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import InventoryCardModal from "./InventoryCardModal";
import { useDispatch } from "react-redux";
import {
  fetchAsyncProduct,
} from "../../../store/inventory/inventorySlice";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";

const InventoryCard = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  
  const handleAddInventory = () => {
    setModalShow(true);
  };

  const handleEditInventory = (item) => {
    setSelectedItem(item);
    setEditModalShow(true);
  };

  const handleSaveInventory = (formData) => {
    // // Add logic to save inventory data
    // dispatch(createAsyncProduct(formData));
    
  };

  useEffect(() => {
    dispatch(fetchAsyncProduct());
  }, [dispatch]);

  const inventoryData = useSelector((state) => state.Inventory.inventory);
  
  const status = useSelector((state) => state.Inventory.status);

  const filteredInventory = inventoryData?.filter((item) => {
    return (
      item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.price?.toString().includes(searchTerm) ||
      item?.stock?.toString().includes(searchTerm)
    );
  });

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="col-4 text-center">
              <button
                className="btn btn-primary"
                onClick={() => setModalShow(true)}
              >
                Add Inventory
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center gap-3">
        {status === "loading" ? (
          <Loading status={status} />
        ) : (
          <>
            {filteredInventory &&
              filteredInventory?.map((item) => (
                <>
                  <Card
                    key={item.id}
                    className="col-12 col-md-4 col-lg-3 main-card-custom"
                  >
                    <Card.Img className="card-image-custom" src={item.url} />
                    <Card.Body className="card-body-custom">
                      <h3>{item.name}</h3>
                      <Card.Text>
                        <p className="mb-0">
                          <span className="fw-bold">Price Per Unit:</span> $
                          {item.price}
                        </p>
                        <p>
                          <span className="fw-bold">Qnt inside the stock:</span>{" "}
                          {item.stock}
                        </p>
                      </Card.Text>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleEditInventory(item)}
                      >
                        Edit
                      </button>
                    </Card.Body>
                  </Card>
                </>
              ))}
          </>
        )}
      </div>

      {/* Add Inventory Modal */}
      <InventoryCardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        mode="add"
        onSave={handleSaveInventory}
      />

      {/* Edit Inventory Modal */}
      {selectedItem && (
        <InventoryCardModal
          selectedItem={selectedItem}
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          mode="edit"
          onSave={handleSaveInventory}
        />
      )}
    </div>
  );
};

export default InventoryCard;
