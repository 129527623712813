import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../constant/theme";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAsyncAllEmployee } from "../../../store/employee/EmployeeSlice";
import Loading from "../../components/Loading";

const AllEmployee = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAsyncAllEmployee());
  }, [dispatch]);
  const employees = useSelector((state) => state.Employee.allEmployee);
  const status = useSelector((state) => state.Employee.fetchStatus);
  return (
    <div className="container my-2">
      <div>
        <div className="card-footer text-start">
          <Link className="text-white" to="/employee/add">
            <button className={`btn btn-primary`}>Add Employee</button>
          </Link>
        </div>
      </div>
      <div className="row justify-content-center mt-2">
        {status === "loading" ? (
          <Loading status={status} />
        ) : (
          <>
            {employees &&
              employees.map((emp, index) => (
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-10">
                  <Link className="text-dark" to={`/employee/${emp?.id}`}>
                    <div className="card card-profile">
                      <div className="admin-user m-auto justify-content-center ">
                        <div className="img-wrraper">
                          <div className="">
                            <img
                              src={emp?.url || IMAGES.User}
                              alt=""
                              className="rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="user-details">
                          <div className="title">
                            <Link to={"#"}>
                              <h4>{emp?.name}</h4>
                            </Link>
                            <h6>{emp?.title}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AllEmployee;
