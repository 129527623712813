import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

const images = [{}, {}, {}, {}, {}];

const AddNewModal = (props) => {
  const [selectedImages, setSelectedImages] = useState([]);

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        className="custom-modal-size"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Trending Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            {images.map((image, index) => (
              <div
                key={index}
                className="col-12 col-md-4 col-lg-3"
                onClick={() => {
                  if (selectedImages.includes(index)) {
                    setSelectedImages(
                      selectedImages.filter((i) => i !== index)
                    );
                  } else {
                    setSelectedImages([...selectedImages, index]);
                  }
                }}
              >
                <div className="de-image-hover ">
                  <span className="dih-overlay"></span>
                  {selectedImages.includes(index) && (
                    <div className="selected-mark">
                      <FaCheck className="bg-success display-5 p-2 rounded-circle" />
                    </div>
                  )}
                  <img
                    className="img-fluid"
                    src="https://images.unsplash.com/photo-1585747860715-2ba37e788b70?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </div>
                <h5 className="text-center py-3">Image Name</h5>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Add Image</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNewModal;
