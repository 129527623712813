import {get, put} from "aws-amplify/api";
import { amplifyConfigs } from "../../config";



// Get All Data from Contact
export async function getContact() {
  try {
    const restOperation = get({ 
      apiName: amplifyConfigs.apiName,
      path: "/barbers/contact",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// put Data from Contact
export async function updateContact(data) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/contact",
      options: {
        body: data,
      },
    });
    await restOperation.response;
  } catch (error) {
    console.log("Put call failed: ", error);
  }
}