import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createAsyncProduct,
  deleteAsyncProduct,
  fetchAsyncProduct,
  updateAsyncProduct,
} from "../../../store/inventory/inventorySlice";
import { IoMdTrash } from "react-icons/io";
import Swal from "sweetalert2";

const InventoryCardModal = ({ show, onHide, mode, onSave, selectedItem }) => {
  const id = selectedItem?.id;
  console.log(id);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (mode === "edit" && onSave) {
      setFormData(onSave);
    }
  }, [mode, onSave]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result.split("base64,")[1];
          setFormData((prevData) => ({
            ...prevData,
            [name]: base64Data,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = () => {
    if (mode === "add" && !formData) {
      console.error("formData is undefined");
      return;
    }
  
    if (mode === "add") {
      dispatch(createAsyncProduct(formData));
    } else if (mode === "edit") {
      dispatch(updateAsyncProduct({ id, data: formData }));
      console.log("update mode");
    }
  
    onSave(formData);
    onHide();
  };
  

  const handleDelete = () => {
    const productId = selectedItem?.id;
  
    if (productId) {
      Swal.fire({
        title: "Are you sure you want to delete this item?",
        text: "Once deleted, you will not be able to recover this item!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked "Yes, delete it!"
          dispatch(deleteAsyncProduct(productId));
          Swal.fire("Deleted!", "Your item has been deleted.", "success");
          onHide();
        } else {
          // User clicked "Cancel" or closed the dialog
          Swal.fire("Cancelled", "Your item is safe :)", "info");
        }
      });
    } else {
      console.error("Unable to delete. Product ID is undefined.");
    }
  };
  

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === "add" ? "Add Inventory" : "Edit Inventory"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-2" controlId="image">
            <Form.Label>Upload inventory image</Form.Label>
            <Form.Control
              type="file"
              placeholder="Enter image URL"
              name="image"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              name="name"
              value={
                formData?.name !== undefined
                  ? formData.name
                  : selectedItem?.name
              }
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter description"
              name="description"
              value={
                formData?.description !== undefined
                  ? formData.description
                  : selectedItem?.description
              }
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter price"
              name="price"
              value={
                formData?.price !== undefined
                  ? formData.price
                  : selectedItem?.price
              }
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="stock">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter quantity"
              name="stock"
              value={
                formData?.stock !== undefined
                  ? formData.stock
                  : selectedItem?.stock
              }
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        {
          mode === "edit" && <Button variant="danger" onClick={handleDelete}>
          <IoMdTrash className="fs-4" />
          </Button>
        }
        <Button variant="primary" onClick={() => {
              Swal.fire({
                position: "top-end",
                title: "Wait a moment, please",
                showConfirmButton: false,
                icon: "info",
                timer: 2000,
                toast: true,
              });
              handleSave();
            }}>
          {mode === "add" ? "Add Inventory" : "Edit Inventory"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InventoryCardModal;
