import { get, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";


// Get todays reservation
export async function getTodaysReservation() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/reservations",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// Update reservation status
export async function updateReservationStatus({itemId, data}) {  
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/reservations/${itemId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log(response)
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// get serving reservation
export async function getServingReservation() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/servings",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// get Completed reservation
export async function getCompletedReservation() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/reseravtions/today-completed",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}