import React, { useEffect, useState } from "react";
import { Offcanvas, Form, Button, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select, { components } from "react-select";
import { fetchAsyncAllCategory } from "../../../../store/category/categorySlice";
import { useSelector } from "react-redux";
import { fetchAsyncEmployeeByServiceId } from "../../../../store/employeeServices/employeeServicesSlice";
import {
  createAsyncReservation,
  fetchAsyncAvailableTimes,
} from "../../../../store/bookingAndWaitlist/bookingAndWaitlistSlice";
import { fetchTodaysReservation } from "../../../../store/reservation/reservationSlice";
import Swal from "sweetalert2";

const BookingOffCanvas = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [serviceSelected, setServiceSelected] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [fetchEmployees, setFetchEmployees] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [dateSelectDisabled, setDateSelectDisabled] = useState(true);
  const [loadingTimes, setLoadingTimes] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    dispatch(fetchAsyncAllCategory());

    if (selectedDate !== "" && selectedEmployeeId !== null) {
      setLoadingTimes(true);
      dispatch(
        fetchAsyncAvailableTimes({
          employeeId: selectedEmployeeId,
          date: selectedDate,
        })
      ).then(() => setLoadingTimes(false));
    }
  }, [dispatch, selectedServiceId, selectedEmployeeId, selectedDate]);

  useEffect(() => {
    if (selectedServiceId !== null) {
      setFetchEmployees(true);
      dispatch(fetchAsyncEmployeeByServiceId(selectedServiceId))
        .then(() => setFetchEmployees(false))
        .catch(() => setFetchEmployees(false));
    }
  }, [dispatch, selectedServiceId]);

  const allCategory = useSelector((state) => state.Category.allCategory);
  const allEmployees = useSelector(
    (state) => state.EmployeeServices.servicesGivenEmpoyees
  );
  const availableTimes = useSelector(
    (state) => state.BookingAndWaitlist.availabelTimes
  );
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value;

    const formattedPhoneNumber = inputPhoneNumber.replace(/^\+/, "");

    const lebanesePhoneNumberPattern = /^(961|0)?(0|1|3|4|7|8)\d{7}$/;

    setPhoneNumber(inputPhoneNumber);

    if (lebanesePhoneNumberPattern.test(formattedPhoneNumber)) {
      setPhoneError("");
    } else {
      setPhoneError("Enter a valid Lebanese phone number");
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleServiceChange = (selectedOption) => {
    setServiceName(selectedOption.value);
    setServiceSelected(true);
    setSelectedServiceId(selectedOption.id);

    setEmployeeName("");
    setSelectedEmployeeId(null);
    setDateSelectDisabled(true);
  };

  const handleEmployeeChange = (selectedOption) => {
    setEmployeeName(selectedOption.label);
    setSelectedEmployeeId(selectedOption.value);
    setDateSelectDisabled(false);
  };

  let options = [];
  allCategory?.forEach((category) => {
    category?.services?.forEach((service) => {
      options.push({
        value: service.name,
        label: service.name,
        id: service.id,
      });
    });
  });

  const employees = allEmployees?.map((employee) => ({
    value: employee.id,
    label: employee.name,
  }));

  const isFormValid = () => {
    return (
      name !== "" &&
      phoneNumber !== "" &&
      serviceName !== "" &&
      employeeName !== "" &&
      selectedDate !== "" &&
      selectedTime !== null
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name,
      phone: phoneNumber,
      reservationDate: selectedDate,
      reservationTime: selectedTime,
    };

    await dispatch(
      createAsyncReservation({
        employeeId: selectedEmployeeId,
        serviceId: selectedServiceId,
        data: data,
      })
    );
    dispatch(fetchTodaysReservation());
  };

  // Custom dropdown indicator with spinner
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {fetchEmployees ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ms-2"
          />
        ) : (
          props.children
        )}
      </components.DropdownIndicator>
    );
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add Reservation</Offcanvas.Title>
      </Offcanvas.Header>
      <hr />
      <Offcanvas.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={handleNameChange}
            />
            <Form.Label className="mt-3">Phone Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              isInvalid={phoneError}
            />
            <Form.Control.Feedback type="invalid">
              {phoneError}
            </Form.Control.Feedback>

            <Form.Label className="mt-3">Service Name</Form.Label>
            <Select
              options={options}
              className="w-100"
              style={{
                lineHeight: "40px",
                color: "#7e7e7e",
                paddingLeft: " 15px",
              }}
              onChange={handleServiceChange}
            />
            <Form.Label className="mt-3">Employee Name</Form.Label>
            <Select
              options={employees}
              className="w-100"
              isDisabled={!serviceSelected}
              styles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: "38px",
                }),
              }}
              onChange={handleEmployeeChange}
              components={{ DropdownIndicator }}
            />
            <Form.Label className="mt-3">Select Date</Form.Label>

            <input
              type="date"
              className="form-control"
              id="date"
              value={selectedDate}
              onChange={handleDateChange}
              disabled={dateSelectDisabled}
            />
            {loadingTimes ? (
              <div className="text-center mt-2">
                <Spinner
                  animation="border"
                  role="status"
                  style={{ width: "2rem", height: "2rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <Form.Label className="mt-3">Select Time</Form.Label>
                <div className="time-container">
                  {availableTimes?.map((time, index) => {
                    // if (index < 0 ) {
                    //   return <p key={index} className="mt-3 text-danger">No available times for this day.</p>;
                    // }
                    return (
                      <div
                        key={index}
                        className={`time-slot ${
                          selectedTime === time ? "selected" : ""
                        }`}
                        onClick={() => setSelectedTime(time)}
                      >
                        <p className="mb-0">{time}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              Swal.fire({
                position: "top-end",
                title: "Wait a moment, please",
                showConfirmButton: false,
                icon: "info",
                timer: 2000,
                toast: true,
              });
              handleClose();
            }}
            disabled={!isFormValid()}
          >
            Submit
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default BookingOffCanvas;
