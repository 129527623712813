import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmployeeWorkingHoursModal from "./EmployeeWorkingHoursModal";
import EmployeeSpecialWorkingHoursModal from "./EmployeeSpecialWorkingHoursModal";
import { fetchEmployeeWorkingHours } from "../../../store/employeeWorkingHours/employeeWorkingHoursSlice";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const WorkingHoursOfEmployee = ({ employeeId }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showSpecialModal, setShowSpecialModal] = useState(false);
  const path = window.location.pathname;
  const Id = path.split("/")[2];

  useEffect(() => {
    dispatch(fetchEmployeeWorkingHours({ employeeId: Id }));
  }, [dispatch, Id]);

  const employeeWorkingHours = useSelector(
    (state) => state.EmployeeHours.employeeWorkingHours
  );
  

  // Aggregate working hours by day
  const aggregatedWorkingHours = (employeeWorkingHours || []).reduce(
    (acc, currentValue) => {
      const { dayOfWeek, timeSlots } = currentValue;
      acc[dayOfWeek] = acc[dayOfWeek] || [];
      acc[dayOfWeek].push(...timeSlots);
      return acc;
    },
    {}
  );

  // Convert to 12 hour format function remains the same
  function convertTo12Hour(time) {
    let date = new Date();
    date.setHours(time.split(":")[0]);
    date.setMinutes(time.split(":")[1]);
    // date.setSeconds(time.split(":")[2]);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }

  // Handler functions remain the same
  const handleEditClick = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleSpecialEditClick = () => setShowSpecialModal(true);
  const handleCloseSpecialModal = () => setShowSpecialModal(false);

  return (
    <>
      <h3 className="text-center">Working Hours</h3>
      <div className="row">
        <div className="col-xl-12">
          <div className="tab-content">
            <div className="tab-pane fade active show">
              <div className="card mt-2">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr className="text-center">
                          <th>Day</th>
                          <th>Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {daysOfWeek.map((day) => {
                          const timeSlots = aggregatedWorkingHours[day];
                          
                          return (
                            <tr className="text-center" key={day}>
                              <td>{day}</td>
                              <td>
                                {timeSlots
                                  ? timeSlots.map((slot, index) => (
                                      <div key={index}>
                                        {`${convertTo12Hour(
                                          slot.startTime
                                        )} - ${convertTo12Hour(slot.endTime)}`}
                                      </div>
                                    ))
                                  : "Closed"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-end me-5">
                    <button
                      className="btn btn-primary mb-2 me-3"
                      onClick={handleSpecialEditClick}
                    >
                      Special Working Hours
                    </button>
                    <button
                      className="btn btn-primary mb-2"
                      onClick={handleEditClick}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeWorkingHoursModal employeeId={Id} show={showModal} onHide={handleCloseModal} />
      <EmployeeSpecialWorkingHoursModal
        employeeId={Id}
        show={showSpecialModal}
        onHide={handleCloseSpecialModal}
      />
    </>
  );
};

export default WorkingHoursOfEmployee;
