import React, { useEffect, useState } from "react";
import Select from "react-select";

const options = [
  { value: "Fixed", label: "Fixed" },
  { value: "Percentage", label: "Percentage" },
];

const EmployeeSalaryType = ({handleSalaryType ,setGoSteps}) => {
  const [selectedOption, setSelectedOption] = useState(null);


  useEffect(() => {
    handleSalaryType(selectedOption?.value);
  }, [selectedOption, handleSalaryType]);

  return (
    <section>
      <div className="mb-4">
        <h4 className="card-title">Select a Salary Type</h4>
      </div>
      <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        style={{
          lineHeight: "40px",
          color: "#7e7e7e",
          paddingLeft: " 15px",
        }}
      />
      <div></div>
      <div className="text-end toolbar toolbar-bottom p-2">
        <button
          className="btn btn-secondary sw-btn-prev me-1"
          onClick={() => setGoSteps(0)}
        >
          Prev
        </button>
        {
          selectedOption === null ? (
            <button
              className="btn btn-primary sw-btn-next ms-1 disabled"
              onClick={() => setGoSteps(1)}
            >
              Next
            </button>
          ) : (
            <button
              className="btn btn-primary sw-btn-next ms-1"
              onClick={() => setGoSteps(2)}
            >
              Next
            </button>
          )
        }
      </div>
    </section>
  );
};

export default EmployeeSalaryType;
