import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { fetchAsyncAllEmployee } from "../../../../store/employee/EmployeeSlice";
import { useSelector } from "react-redux";

const options = [
  { value: "Salary", label: "Salary" },
  { value: "Bonus", label: "Bonus" },
];

const EmployeesTwo = ({handleEmployeeId, handleEmployeeName, setGoSteps, handleBonusOrSalary}) => {
  const [salaryType, setSalaryType] = useState("");
  const [employee, setEmployee] = useState("");

  

  useEffect(() => {
    handleEmployeeId(employee?.value);
    handleBonusOrSalary(salaryType?.value)
    handleEmployeeName(employee?.label)
  }
  , [employee, handleEmployeeId, salaryType, handleEmployeeName, handleBonusOrSalary]);

  // useEffect(() => {
  //   handleEmployeeId(employee?.value);
  // }, [employee, handleEmployeeId]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAsyncAllEmployee());
  }, [dispatch]);
  const employees = useSelector((state) => state.Employee.allEmployee);

  const optionsTwo = [
    ...employees.map((employee) => ({
      value: employee.id,
      label: employee.name,
    })),
  ];

  return (
    <section>
      <label />
      Select a Salary Type
      <Select
        className="my-2"
        defaultValue={salaryType}
        onChange={setSalaryType}
        options={options}
        style={{
          lineHeight: "40px",
          color: "#7e7e7e",
          paddingLeft: " 15px",
        }}
      />
      <label />
      Select Employee
      <Select
        className="mt-2"
        defaultValue={employee}
        onChange={setEmployee}
        options={optionsTwo}
        style={{
          lineHeight: "40px",
          color: "#7e7e7e",
          paddingLeft: " 15px",
        }}
      />
      <div className="text-end toolbar toolbar-bottom p-2">
        {
          salaryType === "" || employee === "" ? (
            <button
              className="btn btn-primary mt-3 sw-btn-next disabled"
              onClick={() => setGoSteps(0)}
            >
              Next
            </button>
          ) : (
            <button
              className="btn btn-primary mt-3 sw-btn-next"
              onClick={() => setGoSteps(1)}
            >
              Next
            </button>
          )
        }
      </div>
    </section>
  );
};

export default EmployeesTwo;
