import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBarberServiceInfo, putBarberServiceInfo } from "./barberServiceAPI";

// Get barber About info
export const getAsyncBarberServiceInfo = createAsyncThunk(
  "barber-service/getInfo",
  async () => {
    const response = await getBarberServiceInfo();
    return response;
  }
);

// Put barber About info
export const putAsyncBarberServiceInfo = createAsyncThunk(
  "barber-service/postInfo",
  async (data) => {
    const response = await putBarberServiceInfo(data);
    return response;
  }
);

const initialState = {
  // Data
  barberService: {},
  // Status
  fetchStatus: "idle",
  updateStatus: "idle",
  // Errors
  fetchError: null,
  updateError: null,
};

const barberServiceSlice = createSlice({
  name: "service",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get barber About info
      .addCase(getAsyncBarberServiceInfo.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncBarberServiceInfo.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.barberService = action.payload;
      })
      .addCase(getAsyncBarberServiceInfo.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Put barber About info
      .addCase(putAsyncBarberServiceInfo.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(putAsyncBarberServiceInfo.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.barberService = action.payload;
      })
      .addCase(putAsyncBarberServiceInfo.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      });
  },
});

export default barberServiceSlice.reducer;
