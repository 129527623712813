import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  fetchAsyncHistory,
  fetchAsyncReservationHistory,
  fetchAsyncWaitlistHistory,
} from "../../store/history/historySlice";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";

const options = [
  { value: "1", label: "Daily" },
  { value: "2", label: "Week" },
  { value: "3", label: "Month" },
];

const History = () => {
  const [addActive, setActive] = useState("All");
  const dispatch = useDispatch();
  const [dataToShow, setDataToShow] = useState([]);

  useEffect(() => {
    dispatch(fetchAsyncHistory());
    dispatch(fetchAsyncWaitlistHistory());
    dispatch(fetchAsyncReservationHistory());
  }, [dispatch]);

  const AllHistory = useSelector((state) => state.History.history);
  const WaitlistHistory = useSelector((state) => state.History.waitlistHistory);
  const ReservationHistory = useSelector(
    (state) => state.History.reservationHistory
  );

  const status = useSelector((state) => state.History.status);

  useEffect(() => {
    if (addActive === "All") {
      setDataToShow(AllHistory);
    } else if (addActive === "waitlist") {
      setDataToShow(WaitlistHistory);
    } else if (addActive === "reservation") {
      setDataToShow(ReservationHistory);
    }
  }, [addActive, AllHistory, WaitlistHistory, ReservationHistory]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 10;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const paginatedData = dataToShow?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(dataToShow?.length / recordsPage);
  const number = [...Array(npage + 1)?.keys()]?.slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [newOrder, setNewOrder] = useState(false);
  const [search, setSearch] = useState("");
  console.log("serarch", search);

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-4 flex-wrap">
        <ul className="revnue-tab nav nav-tabs" id="myTab">
          <li
            className="nav-item"
            onClick={() => {
              setActive("All");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "All" ? "active" : ""}`}
            >
              All
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("waitlist");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${addActive === "waitlist" ? "active" : ""}`}
            >
              Waitlist
            </Link>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setActive("reservation");
            }}
          >
            <Link
              to={"#"}
              className={`nav-link ${
                addActive === "reservation" ? "active" : ""
              }`}
            >
              Reservations
            </Link>
          </li>
        </ul>

        <input
          type="text"
          className="form-control w-25"
          placeholder="Filter Search"
          aria-label="Search"
          aria-describedby="button-addon2"
          onChange={(e) => setSearch(e.target.value)}
        />

        <div className="d-flex align-items-center">
          <Link
            to={"#"}
            className="btn btn-primary me-2"
            onClick={() => setNewOrder(true)}
          >
            + New Order
          </Link>
          <Select
            options={options}
            className="custom-react-drop-btn"
            defaultValue={options[0]}
            isSearchable={false}
          />
        </div>
      </div>

      {status === "loading" ? (
        <Loading status={status} />
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="tab-content">
              <div className="tab-pane fade active show">
                <div className="card mt-2">
                  <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                      <div
                        id="orderlist-table"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="empoloyees-tbl"
                          className="table dataTable no-footer"
                        >
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Customer</th>
                              <th>Service</th>
                              {/* <th>Type</th> */}
                              <th>Staff</th>
                              <th>Amount</th>
                              <th>Action</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginatedData &&
                              paginatedData
                                ?.filter((item) => {
                                  if (search === "") {
                                    return true;
                                  } else {
                                    const searchLower = search.toLowerCase();
                                    return (
                                      item?.name
                                        .toLowerCase()
                                        .includes(searchLower) ||
                                      item?.service?.name
                                        .toLowerCase()
                                        .includes(searchLower) ||
                                      item?.employee?.name
                                        .toLowerCase()
                                        .includes(searchLower)
                                    );
                                  }
                                })
                                .map((item) => (
                                  <tr key={item?.id}>
                                    <td>
                                      <span>
                                        {new Date(
                                          item.createdAt * 1000
                                        ).toLocaleDateString()}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{item?.name}</span>
                                    </td>
                                    <td>
                                      <span>{item?.service?.name}</span>
                                    </td>
                                    <td>
                                      <span>{item?.employee?.name}</span>
                                    </td>
                                    <td>
                                      <span>
                                        $ {item?.service?.waitlistPriceInDollar}
                                      </span>
                                    </td>
                                    <td>
                                      <Link to={"#"} className="btn-link me-1">
                                        View details
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                          {dataToShow?.length > 0 ? (
                            <div className="dataTables_info">
                              Showing {lastIndex - recordsPage + 1} to{" "}
                              {dataToShow?.length < lastIndex
                                ? dataToShow?.length
                                : lastIndex}{" "}
                              of {dataToShow?.length} entries
                            </div>
                          ) : (
                            <div className="dataTables_info">
                              No Entries yet
                            </div>
                          )}
                          <div
                            className="dataTables_paginate paging_simple_numbers justify-content-center"
                            id="example2_paginate"
                          >
                            <Link
                              className="paginate_button previous disabled"
                              to="#"
                              onClick={prePage}
                            >
                              <i className="fa-solid fa-angle-left" />
                            </Link>
                            <span>
                              {number.map((n, i) => (
                                <Link
                                  className={`paginate_button ${
                                    currentPage === n ? "current" : ""
                                  } `}
                                  key={i}
                                  onClick={() => changeCPage(n)}
                                >
                                  {n}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                              to="#"
                              onClick={nextPage}
                            >
                              <i className="fa-solid fa-angle-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal show={newOrder} onHide={setNewOrder} centered>
        <div className="modal-content">
          <div className="modal-header ">
            <h5 className="modal-title">Add Order</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setNewOrder(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="form-label">Order ID</label>
              <input
                type="number"
                className="form-control mb-3"
                id="exampleInputEmail1"
                placeholder="Order Id"
              />
              <label className="form-label">Gmail</label>
              <input
                type="email"
                className="form-control mb-3"
                id="exampleInputEmail2"
                placeholder=" Email"
              />
              <label className="form-label">Amount</label>
              <input
                type="number"
                className="form-control mb-3"
                id="exampleInputEmail3"
                placeholder="Amount"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger light"
              onClick={() => setNewOrder(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default History;
