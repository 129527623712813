import React, { useEffect, useState } from "react";
import {
  fetchAsyncWorkingHours,
  openCloseBarbershop,
} from "../../../store/workingHoursSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import OpeningHours from "../../components/ScheduleModals/OpeningHours";
import { Button, Form } from "react-bootstrap";
import HoursByDate from "../../components/ScheduleModals/HoursByDate";
import Swal from "sweetalert2";

const GeneralWorkingHours = () => {
  const dispatch = useDispatch();
  const [isSwitchActive, setIsSwitchActive] = useState(false);
  const [showOpeningHours, setShowOpeningHours] = useState(false);
  const [showHoursByDate, setShowHoursByDate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Fetch the working hours
  useEffect(() => {
    dispatch(fetchAsyncWorkingHours());
  }, [dispatch]);
  const workingHours = useSelector((state) => state.WorkingHours.workingHours);
  const openOrClose = useSelector((state) => state.WorkingHours.barbershopOpenOrClose);
  console.log("openOrClose", openOrClose);

  

  // Convert to 12 hour
  function convertTo12Hour(time) {
    let date = new Date();
    date.setHours(time.split(":")[0]);
    date.setMinutes(time.split(":")[1]);
    date.setSeconds(time.split(":")[2]);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes + " " + ampm;
  }

  // Aggregate working hours by day
  const aggregatedWorkingHours = (workingHours || []).reduce(
    (acc, currentValue) => {
      const { dayOfWeek, barberTimeSlots } = currentValue;
      acc[dayOfWeek] = acc[dayOfWeek] || [];
      acc[dayOfWeek].push(...barberTimeSlots);
      return acc;
    },
    {}
  );

  const handleSwitchChange = async () => {
    try {
      const willProceed = await Swal.fire({
        title: "Are you sure?",
        text: "Once submitted, you will not be able to change this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      });

      if (willProceed.isConfirmed) {
        const newIsOpen = !isOpen;
        console.log("newIsOpen", newIsOpen);
        await dispatch(openCloseBarbershop({ isOpen: newIsOpen }));
        setIsOpen(newIsOpen); // Update local state only after dispatch succeeds
        await Swal.fire(
          "Changed!",
          "The barbershop status has been updated.",
          "success"
        );
      }
    } catch (error) {
      console.error("Error updating info:", error);
      await Swal.fire("Oh noes!", "The AJAX request failed!", "error");
    }
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <h5>Opening hours</h5>
        <p className="text-muted">
          Define your barbershop's operating hours here. This schedule will be
          visible to customers, helping them know the best times to visit or
          book appointments.
        </p>
      </div>
      <div className="col-md-9">
        <h5>Opening hours</h5>
        {daysOfWeek.map((day) => {
          const timeSlots = aggregatedWorkingHours[day];
          return (
            <div className="row">
              <div key={day} className="col-md-4 col-lg-2 col-4">
                <p className="m-0">{day}</p>
              </div>
              <div className="col-md-8 col-lg-10 col-8">
                {timeSlots
                  ? timeSlots.map((slot, index) => (
                      <p className="m-0" key={index}>
                        {`${convertTo12Hour(
                          slot.startTime
                        )} - ${convertTo12Hour(slot.endTime)}`}
                      </p>
                    ))
                  : "Closed"}
              </div>
            </div>
          );
        })}
        <Button
          disabled={isSwitchActive}
          onClick={() => setShowOpeningHours(true)}
          variant="primary mt-2 rounded-5"
        >
          Set waitlist hours
        </Button>
        <OpeningHours
          workingHours={workingHours}
          show={showOpeningHours}
          onHide={() => setShowOpeningHours(false)}
        />
        <h5 className="mt-3 mb-2">Specific dates</h5>
        <Button
          disabled={isSwitchActive}
          onClick={() => setShowHoursByDate(true)}
          variant="primary mt-2 rounded-5"
        >
          Set hours by date
        </Button>
        <HoursByDate
          show={showHoursByDate}
          onHide={() => setShowHoursByDate(false)}
        ></HoursByDate>
        {/* <Form.Check
          type="switch"
          className="mt-3"
          id="custom-switch"
          label={isOpen ? "Barbershop is open" : "Barbershop is closed"}
          checked={isOpen}
          onChange={handleSwitchChange} // Call the function directly when the switch changes
        /> */}
      </div>
    </div>
  );
};

export default GeneralWorkingHours;
