



import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomer } from './customerAPI';

// get history
export const fetchAsyncCustomerHistory = createAsyncThunk(
  "employeeServices/fetchAsyncHistory",
  async () => {
    const services = await getCustomer();
    return services;
  }
);


const initialState = {
  customer: [],
  status: 'idle',
  error: null
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncCustomerHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAsyncCustomerHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.customer = action.payload;
      })
      .addCase(fetchAsyncCustomerHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default customerSlice.reducer;