import { del, get, post, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

// Get Gallery Images
export async function getGalleryImages() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/images",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Get Gallery Single Image
export async function getGallerySingleImage(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/images/${id}`,
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Post Gallery Image
export async function postGalleryImage({ image, isFavorite }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/images",
      options: { body: { image, isFavorite } },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
  }
}

// Update Gallery Image
export async function updateGalleryImage({ id, isFavorite }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/images/${id}`,
      options: {
        body: { isFavorite },
      },
    });
    const response = await restOperation.response;
    return response;
  } catch (error) {
    console.log("Put call failed: ", error);
  }
}

// Delete Gallery Image
export async function deleteGalleryImage(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/images/${id}`,
    });
    await restOperation.response;
  } catch (error) {
    console.log("DELETE call failed: ", error);
  }
}
