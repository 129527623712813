import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createProduct,
  editProduct,
  getProduct,
  removeProduct,
} from "./inventoryAPI";

export const createAsyncProduct = createAsyncThunk(
  "product/postProduct",
  async (data) => {
    const employees = await createProduct(data);
    return employees;
  }
);

export const fetchAsyncProduct = createAsyncThunk(
  "product/getInventoryProduct",
  async () => {
    const employees = await getProduct();
    return employees;
  }
);

export const updateAsyncProduct = createAsyncThunk(
  "product/updateInventoryProduct",
  async (data) => {
    const response = await editProduct(data);
    return response;
  }
);

export const deleteAsyncProduct = createAsyncThunk(
  "product/deleteInventoryProduct",
  async (id) => {
    const response = await removeProduct(id);
    return response;
  }
);




const initialState = {
  inventory: [],
  status: "idle",
  error: null,
};

const InventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Product
      .addCase(createAsyncProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAsyncProduct.fulfilled, (state, { payload }) => {
        state.inventory = [...state.inventory, payload];
        state.status = "succeeded";
      })
      .addCase(createAsyncProduct.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // Fetch Product
      .addCase(fetchAsyncProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncProduct.fulfilled, (state, { payload }) => {
        state.inventory = payload;
        state.status = "succeeded";
      })
      .addCase(fetchAsyncProduct.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // Update Product
      .addCase(updateAsyncProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAsyncProduct.fulfilled, (state, { payload }) => {
        state.inventory = state.inventory.map((inv) =>
          inv.id === payload.id ? payload : inv
        );
        state.status = "succeeded";
      })
      .addCase(updateAsyncProduct.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // Delete Product
      .addCase(deleteAsyncProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteAsyncProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.inventory = state.inventory.filter(
          (i) => i.id !== action.meta.arg.id
        );
      })
      .addCase(deleteAsyncProduct.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      });
  },
});

export default InventorySlice.reducer;
