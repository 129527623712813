import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBarberGalleryInfo, putBarberGalleryInfo } from "./barberGalleryAPI";

// Get barber gallery info
export const getAsyncBarberGalleryInfo = createAsyncThunk(
  "barber-gallery/getInfo",
  async () => {
    const response = await getBarberGalleryInfo();
    return response;
  }
);

// Put barber gallery info
export const putAsyncBarberGalleryInfo = createAsyncThunk(
  "barber-gallery/postInfo",
  async (data) => {
    const response = await putBarberGalleryInfo(data);
    return response;
  }
);

const initialState = {
  // Data
  barberGallery: {},
  // Status
  fetchStatus: "idle",
  updateStatus: "idle",
  // Errors
  fetchError: null,
  updateError: null,
};

const barberGallerySlice = createSlice({
  name: "gallery",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get barber gallery info
      .addCase(getAsyncBarberGalleryInfo.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncBarberGalleryInfo.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.barberGallery = action.payload;
      })
      .addCase(getAsyncBarberGalleryInfo.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Put barber gallery info
      .addCase(putAsyncBarberGalleryInfo.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(putAsyncBarberGalleryInfo.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.barberGallery = action.payload;
      })
      .addCase(putAsyncBarberGalleryInfo.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      });
  },
});

export default barberGallerySlice.reducer;
