import { post } from "@aws-amplify/api-rest";
import { amplifyConfigs } from "../../config";
// import Swal from "sweetalert2";

// Add Inventory User
export async function postInInventoy({productId, data}) {

    console.log("productId", productId);
    console.log("data", data)
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/inventory/products/${productId}/add`,
      options: {
        body: data,
      },
    });
    await restOperation.response;
    // Swal.fire({
    //   icon: "success",
    //   title: "Success",
    //   text: "Product edited successfully!",
    // });
    const response = await restOperation.response;
    console.log("GET call succeeded");
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}
