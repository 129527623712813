import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CreateAsyncExpense } from "../../../../store/expenseTracker/ExpenseSlice";

const RentOrUtilities = () => {
  const navigate = useNavigate();
  const now = new Date();
  const date = now.toISOString().substring(0, 10);
  // const time = now.toTimeString().substring(0, 5);
  const dispatch = useDispatch();

  // get category from store
  const category = useSelector((state) => state.ExpenseCategory.category);
  console.log(category);

  // handle submit data
  const handleSubmit = async (event) => {
    event.preventDefault();
    const title = document.getElementById("title").value;
    const amount = document.getElementById("amount").value;
    const date = document.getElementById("date").value;
    const description = document.getElementById("description").value;
    const data = {
      title: title,
      amount: amount,
      expenseDate: date,
      description: description,
      expenseType: category,
    };
    try {
      dispatch(CreateAsyncExpense(data));
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleNext = () => {
    Swal.fire({
      title: "Success",
      text: "Data saved successfully",
      icon: "success",
      confirmButtonText: "Ok",
    });
    navigate("/discovery-page");
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Rent or Utilities</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="title" /> Title
                    <input type="text" className="form-control" id="title" />
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <label htmlFor="amount" /> Amount
                    <input type="text" className="form-control" id="amount" />
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <label className="m-0" htmlFor="date">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      defaultValue={date}
                    />
                  </div>
                  {/* <div className="col-12 col-lg-6 mt-4">
                    <label className="m-0" htmlFor="time">Time</label>
                    <input
                      type="time"
                      className="form-control"
                      id="time"
                      defaultValue={time}
                    />
                  </div> */}
                  <div className="col-12 mt-4">
                    <label className="m-0" htmlFor="description">Description</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="description"
                    />
                  </div>
                  <div className="text-end mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentOrUtilities;
