import React, { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoIosPerson } from "react-icons/io";

import Card from "./Card";
import { Dropdown } from "react-bootstrap";
import TabViewCard from "./TabViewCard/TabViewCard";
import { useDispatch } from "react-redux";
import {
  fetchServiceWaitlist,
  updateAsyncWaitlistStatus,
} from "../../../store/waitlist/waitlistSlice";
import { useSelector } from "react-redux";
import { fetchUserRole } from "../../../store/userInfo/userInfoSlice";

const Serving = ({ tableView }) => {
  const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
  const dispatch = useDispatch();
  const [filteredServing, setFilteredServing] = useState([]);

  useEffect(() => {
    dispatch(fetchServiceWaitlist());
    dispatch(fetchUserRole());

  }, [dispatch]);
  const serving = useSelector((state) => state.Waitlist.serviceWaitlist);

  const handleStatus = (waitlistId) => {
    dispatch(
      updateAsyncWaitlistStatus({
        waitlistId: waitlistId,
        data: { status: "Completed" },
      })
    );
  };


  const userData = useSelector((state) => state.Employee.userProfile);
  const data = useSelector((state) => state.User.role);
  console.log(data);

  useEffect(() => {
    // Check if the logged-in user is Admin
    if (data === "admin" || data === "supervisor") {
      // If Admin, return all waitlist items
      setFilteredServing(serving);
    } else {
      // If not Admin, filter the waitlist by the logged-in employee's name
      const filter = serving.filter(
        (servingItem) => servingItem.employeeName === userData.name
      );
      setFilteredServing(filter);
    }
  }, [data, serving]);



  // Offcanvas for card details
  const [show, setShow] = useState(false);

  const [servingId, setServingId] = useState("");
  const handleCardClick = (id) => {
    setServingId(id);
    setShow(true);
  };

  // Get serving waitlist


  return (
    <div className="">
      <div className="row">
        <div className="col-12">
          <div className="bg-white rounded-3 p-3">
            <div className="row justify-content-between align-items-center">
              <div className="col-7 col-lg-6">
                <h4 className="mb-0">Serving</h4>
                <p className="mb-0">{serving?.length} Client</p>
              </div>
              <div className="col-5 col-lg-6 text-end">
                <Dropdown className="dropdown d-inline-block">
                  <Dropdown.Toggle
                    variant=""
                    className=" i-false p-0 sharp border-0 "
                  >
                    <svg
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <circle fill="#000000" cx="5" cy="12" r="2" />
                        <circle fill="#000000" cx="12" cy="12" r="2" />
                        <circle fill="#000000" cx="19" cy="12" r="2" />
                      </g>
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item className="dropdown-item" to="/widget-basic">
                      <IoIosPerson></IoIosPerson> Serving Overview
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        {isTablet || tableView ? (
          <>
            <TabViewCard name={"serving"} items={serving}></TabViewCard>
          </>
        ) : (
          <>
            {[...(filteredServing || [])]?.reverse().map((item) => (
              <Card
                item={item}
                name={"serving"}
                handleCardClick={handleCardClick}
                handleStatus={handleStatus}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Serving;
