import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBarberShopInfo, putBarberShopInfo } from "./barberInfoAPI";

// get barbershop info
export const getAsyncBarberShopInfo = createAsyncThunk(
  "barbershop/getInfo",
  async () => {
    const response = await getBarberShopInfo();
    return response;
  }
);

// Put barbershop info
export const putAsyncBarberShopInfo = createAsyncThunk(
  "barbershop/postInfo",
  async (data) => {
    const response = await putBarberShopInfo(data);
    console.log("response", response);
    return response;
  }
);

const initialState = {
  // Data
  barberInfo: {},
  // Status
  fetchStatus: "idle",
  updateStatus: "idle",
  // Errors
  fetchError: null,
  updateError: null,
};

const barbershopSlice = createSlice({
  name: "barbershop-info",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get barbershop info
      .addCase(getAsyncBarberShopInfo.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncBarberShopInfo.fulfilled, (state, action) => {
        state.fetchStatus = "succeeded";
        state.barberInfo = action.payload;
      })
      .addCase(getAsyncBarberShopInfo.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Put barbershop info
      .addCase(putAsyncBarberShopInfo.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(putAsyncBarberShopInfo.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.barberInfo = action.payload;
      })
      .addCase(putAsyncBarberShopInfo.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      });
  },
});

export default barbershopSlice.reducer;
