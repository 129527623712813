import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteTrendingImage, getTrendingImages, getTrendingSingleImage, postTrendingImage, updateTrendingImage } from "./trendingAPI";

// Get Trending Images
export const getAsyncTrendingImages = createAsyncThunk(
  "Trending/fetchImages",
  async () => {
    const response = await getTrendingImages();
    // console.log("response", response);
    return response;
  }
);

// Update Trending Image
export const updateAsyncTrendingImage = createAsyncThunk(
  "Trending/updateImage",
  async (data) => {
    const response = await updateTrendingImage(data);
    return response;
  }
);

// Post Trending Image
export const postAsyncTrendingImage = createAsyncThunk(
  "Trending/addImage",
  async (data) => {
    const response = await postTrendingImage(data);
    return response;
  }
);

// Delete Trending Image
export const deleteAsyncTrendingImage = createAsyncThunk(
  "Trending/deleteImage",
  async (id) => {
    const response = await deleteTrendingImage(id);
    return { id, ...response };
  }
);

const initialState = {
  // Data
  allImages: [],
  // Status
  fetchStatus: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  // Errors
  fetchError: null,
  createError: null,
  updateError: null,
  deleteError: null,
};

const trendingSlice = createSlice({
  name: "trending",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get Trending Images
      .addCase(getAsyncTrendingImages.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(getAsyncTrendingImages.fulfilled, (state, { payload }) => {
        state.fetchStatus = "succeeded";
        state.allImages = payload;
      })
      .addCase(getAsyncTrendingImages.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Post Trending Image
      .addCase(postAsyncTrendingImage.pending, (state) => {
        state.createStatus = "loading";
        state.createError = null;
      })
      .addCase(postAsyncTrendingImage.fulfilled, (state, { payload }) => {
        console.log("Post payload", payload)
        state.createStatus = "succeeded";
        state.allImages.push(payload);
      })
      .addCase(postAsyncTrendingImage.rejected, (state, action) => {
        state.createStatus = "failed";
        state.createError = action.error.message;
      })
      // Delete Trending Image
      .addCase(deleteAsyncTrendingImage.pending, (state) => {
        state.deleteStatus = "loading";
        state.deleteError = null;
      })
      .addCase(deleteAsyncTrendingImage.fulfilled, (state, action) => {
        state.allImages = state.allImages.filter(
          (image) => image.id !== action.payload.id
        );
        state.deleteStatus = "succeeded";
      })
      .addCase(deleteAsyncTrendingImage.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.deleteError = action.error.message;
      });
  },
});

export default trendingSlice.reducer;
