import { get } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";


// Get todays reservation
export async function getCompleted() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/completed",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}