import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getTodaysReservation,
  updateReservationStatus,
} from "./reservationAPI";

// get todays reservation
export const fetchTodaysReservation = createAsyncThunk(
  "reservation/fetchTodaysReservation",
  async () => {
    const response = await getTodaysReservation();
    return response;
  }
);

// Update reservation status
export const updateAsyncReservationStatus = createAsyncThunk(
  "reservation/updateReservationStatus",
  async (data) => {
    console.log("data", data);
    const response = await updateReservationStatus(data);
    return response;
  }
);

const initialState = {
  todaysReservation: [],
  status: "idle",
  error: null,
};

const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get todays reservation
      .addCase(fetchTodaysReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTodaysReservation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.todaysReservation = action.payload;
      })
      .addCase(fetchTodaysReservation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Update reservation status
      .addCase(updateAsyncReservationStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAsyncReservationStatus.fulfilled, (state, action) => {
        // Store the updated item
        const updatedItem = action.payload;
        // Remove the updated item from todaysReservation
        state.todaysReservation = state.todaysReservation.filter(item => item.id !== updatedItem.id);
        state.status = "succeeded";
      })
      .addCase(updateAsyncReservationStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default reservationSlice.reducer;
