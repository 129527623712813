import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  getAsyncBarberServiceInfo,
  putAsyncBarberServiceInfo,
} from "../../../store/barberService/barberServiceSlice";

const GeneralService = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // Get barber About info
  useEffect(() => {
    dispatch(getAsyncBarberServiceInfo());
  }, [dispatch]);
  const barberService = useSelector(
    (state) => state.BarberService.barberService
  );

  // Convert image to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.split("base64,")[1]);
      };
      reader.onerror = (error) => {
        console.error("Error converting file to base64!", error);
        reject(error);
      };
    });
  };

  // put barber About info
  const handleSubmitInfo = async () => {
    try {
      setLoading(true);
      if (image === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please fill in all fields!",
        });
      } else {
        let base64Image = "";
        if (imageFile) {
          base64Image = await convertToBase64(imageFile);
        }
        let data = {
          image: base64Image,
        };
        console.log("data", data);
        await dispatch(putAsyncBarberServiceInfo(data));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your changes have been saved!",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving your changes!",
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <h5>Service</h5>
          <p className="text-muted">
            Your location name abss is displayed across many areas including on
            your online pages and messages to client{" "}
          </p>
        </div>
        <div className="col-md-9">
          <h5 className="mt-3">Upload a photo</h5>
          <div className="d-flex align-items-center">
            <img
              src={barberService?.url}
              alt="company logo"
              className="mr-2 border rounded-1 p-2 me-4"
              style={{ height: "100px", width: "100px" }}
            />
            <input
              value={image}
              type="file"
              onChange={(e) => {
                setImageFile(e.target.files[0]);
                setImage(e.target.value);
              }}
              className="form-control"
            />
          </div>
          <button
            onClick={() => {
              Swal.fire({
                position: "top-end",
                title: "Wait a moment, please",
                showConfirmButton: false,
                icon: "info",
                timer: 2000,
                toast: true,
              });
              handleSubmitInfo();
            }}
            className="btn btn-outline-primary rounded-5 mt-4"
          >
            {loading === true ? (
              <div className="d-flex align-items-center justify-content-center ">
                <span className="me-2">Initiated</span>
                <Spinner animation="border" size="sm" />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      <Card.Footer className="mt-3" />
    </div>
  );
};

export default GeneralService;
