import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { CalculatePercentage, CreateAsyncExpense } from "../../../../store/expenseTracker/ExpenseSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EmployeeSalaryPercentage = ({ setGoSteps, employeeId, employeeName }) => {
  const now = new Date();
  const date = now.toISOString().substring(0, 10);
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [percentage, setPercentage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const category = useSelector((state) => state.ExpenseCategory.category);


  


  const handleCalculatePercentage = (data) => {
    Swal.fire({
      title: "Please wait...",
      text: "Until we calculate your data",
      icon: "info",
      timer: 2000,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    dispatch(CalculatePercentage({ data: { startDate, endDate, percentage }, employeeId: employeeId }));
  }
  const percentageValue = useSelector((state) => state.Expense.percentage);
  console.log("Percentage",percentageValue?.salary);

  useEffect(() => {
    setValue(percentageValue?.salary);
  }, [percentageValue]);

  console.log("value")


  const handleSubmit = () => {
    const data = {
      title: employeeName,
      amount: value,
      expenseDate: date,
      description: `This is the percentage of the salary of the employee ${employeeName}`,
      expenseType: category,
    };
    try {
      dispatch(CreateAsyncExpense(data));
    } catch (error) {
      console.log(error.message);
    }
    navigate("/discovery-page");
    console.log(data);
  };



  return (
    <div className="row justify-content-center">
      <div className="col-10 d-flex gap-5">
        <div className="w-100">
          <label htmlFor="startDate" /> Start Date
          <input
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
            className="form-control "
            id="startDate"
          />
        </div>
        <div className="w-100">
          <label htmlFor="startDate" /> End Date
          <input
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
            className="form-control"
            id="startDate"
          />
        </div>
      </div>
      <div className="col-10 mt-4">
        <label htmlFor="percentage">Percentage</label>
        <Select
          className="my-2"
          options={Array.from({length: 100}, (_, i) => ({value: i+1, label: i+1}))}
          onChange={selectedOption => setPercentage(selectedOption?.value)}
          style={{
            lineHeight: "40px",
            color: "#7e7e7e",
            paddingLeft: " 15px",
          }}
        />
      </div>
      <div className="col-10 text-end mt-3">
        <button
          onClick={() => handleCalculatePercentage()}
          className="btn btn-secondary"
        >
          Calculate
        </button>
      </div>
      <div className="col-10">
        <label htmlFor="value">Value</label>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="text"
          className="form-control"
          id="value"
        />
      </div>
      <div className="text-end toolbar toolbar-bottom p-2">
        <button
          className="btn btn-secondary sw-btn-prev me-1"
          onClick={() => setGoSteps(1)}
        >
          Prev
        </button>
        <button
          className={`btn btn-primary sw-btn-next ms-1 ${value === "" ? "disabled" : ""}`}
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default EmployeeSalaryPercentage;
