import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createAsyncCategory,
  fetchAsyncCategoryById,
  updateAsyncCategory,
} from "../../../store/category/categorySlice";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { categoryServicesSchema } from "../../components/ValidationSchema";
import { Spinner } from "react-bootstrap";

const CategoryForm = () => {
  // All Hooks
  const { id } = useParams();
  const pathname = useLocation();
  const path = pathname.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // All States
  const [editCategory, setEditCategory] = useState(false);
  const [base64, setBase64] = useState("");
  const [name, setName] = useState("");
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Fetching single category
  useEffect(() => {
    if (editCategory) {
      dispatch(fetchAsyncCategoryById(id));
    }
  }, [dispatch, editCategory, id]);
  const singleCategory = useSelector((state) => state.Category.singleCategory);
  const status = useSelector((state) => state.Category.fetchSingleStatus);

  // Condition for this form. Check if it is edit or add new.
  useEffect(() => {
    if (path === `/singleCategory/${id}/editCategory`) {
      setEditCategory(true);
      setName(singleCategory?.name);
    } else {
      setEditCategory(false);
    }
  }, [path, singleCategory, id]);

  // Handle Image Upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileNameClean = reader.result.split("base64,")[1];
        setBase64(fileNameClean);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("No file selected");
    }
  };

  // Validate field individually
  const validateField = async (name, value) => {
    try {
      await categoryServicesSchema.validateAt(name, { [name]: value });
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };
  // Handle field change
  const handleFieldChange = (name, value) => {
    setTouched((prev) => ({ ...prev, [name]: true }));
    validateField(name, value);
  };

  // Handle Form Submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name,
      id,
    };
    if (base64) {
      data.image = base64;
    }
    try {
      setLoading(true);
      await categoryServicesSchema.validate(data);
      if (editCategory) {
        await dispatch(updateAsyncCategory(data))
          .then(() => {
            Swal.fire("Updated!", "Your category has been updated.", "success");
            navigate("/category-page");
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              "There was an error updating your category.",
              "error"
            );
          });
      } else {
        await dispatch(createAsyncCategory(data))
          .then(() => {
            Swal.fire("Created!", "Your category has been created.", "success");
            navigate("/category-page");
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              "There was an error creating your category.",
              "error"
            );
          });
      }
    } catch (error) {
      console.error("Validation Error:", error);
      Swal.fire("Validation Error", error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-8">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                {editCategory ? (
                  <h6 className="title">Edit catagory</h6>
                ) : (
                  <h6 className="title">Add a new catagory</h6>
                )}
              </div>
              {status === "loading" ? (
                <>
                  <Loading status={status} />
                </>
              ) : (
                <>
                  <form onSubmit={handleFormSubmit} className="profile-form">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6 m-b30">
                          <label htmlFor="name" className="form-label">
                            Name*
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            className="form-control"
                            onChange={(e) => {
                              setName(e.target.value);
                              handleFieldChange("name", e.target.value);
                            }}
                            onBlur={() => handleFieldChange("name", name)}
                          />
                          {touched.name && errors.name && (
                            <p style={{ color: "red" }}>{errors.name}</p>
                          )}
                        </div>

                        <div className="col-sm-6 m-b30">
                          <p className="d-block d-lg-none mb-4 "></p>
                          <div>
                            <label className="form-label">
                              Upload Category Image
                            </label>
                            {!editCategory ? (
                              <input
                                className="form-control form-control-lg"
                                type="file"
                                required
                                onChange={handleFileChange}
                              />
                            ) : (
                              <input
                                className="form-control form-control-lg"
                                type="file"
                                onChange={handleFileChange}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      {loading === true ? (
                        <div className="btn btn-primary d-flex align-items-center justify-content-center">
                          <span className="me-2">Initiated</span>
                          <Spinner animation="border" size="sm" />
                        </div>
                      ) : (
                        <input
                          type="submit"
                          value={editCategory ? "Save" : "Add Category"}
                          className="btn btn-primary"
                          onClick={() => {
                            Swal.fire({
                              position: "top-end",
                              title: "Wait a moment, please",
                              showConfirmButton: false,
                              icon: "info",
                              timer: 2000,
                              toast: true,
                            });
                          }}
                        />
                      )}
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryForm;
