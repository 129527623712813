import { fetchCompleted } from "./complete/completeSlice";
import { fetchTodaysReservation } from "./reservation/reservationSlice";
import { fetchServiceWaitlist, fetchTodaysWaitlist } from "./waitlist/waitlistSlice";

export const fetchAllData= () => async (dispatch) => {
  try {
    dispatch(fetchTodaysReservation());
    dispatch(fetchTodaysWaitlist());
    dispatch(fetchServiceWaitlist());
    dispatch(fetchCompleted());
  } catch (error) {
    console.log(error);
  }
}