import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { useSelector } from "react-redux";
import loadingImg from "../../../images/user.jpg";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHader = () => {
  const data = useSelector((state) => state.Employee.userProfile);
  const userProfileStatus = useSelector(
    (state) => state.Employee.userProfileStatus
  );
  // console.log(data)

  const [toggle, setToggle] = useState(false);
  const { openMenuToggle } = useContext(ThemeContext);
  return (
    <div className="nav-header zIndexH">
      <img className="logo-image dashboard-logo" src="/logo192.png" alt="" />
      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
