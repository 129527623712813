import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createAsyncService,
  fetchAsyncServiceById,
  updateAsyncService,
} from "../../../store/categoryServices/serviceSlice";
import { serviceSchema } from "../../components/ValidationSchema";
import Loading from "../../components/Loading";
import { Spinner } from "react-bootstrap";

const ServiceForm = () => {
  // All States
  const dispatch = useDispatch();
  const { id, serviceId } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [waitlistPriceInDollar, setWaitlistPriceInDollar] = useState("");
  const [reservationPriceInDollar, setReservationPriceInDollar] = useState("");
  const [durationInMinutes, setDurationInMinutes] = useState("");
  const [editService, setEditService] = useState(false);
  const [addService, setAddService] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const pathname = useLocation();
  const path = pathname.pathname;
  const navigate = useNavigate();

  // Check if its edit or add service
  useEffect(() => {
    if (path === `/singleCategory/${id}/editService/${serviceId}`) {
      setEditService(true);
      setAddService(false);
    } else if (path === `/singleCategory/${id}/addService`) {
      setEditService(false);
      setAddService(true);
    } else {
      setEditService(false);
      setAddService(false);
    }
  }, [id, serviceId, path]);

  // Fetching single service
  useEffect(() => {
    if (editService) {
      dispatch(fetchAsyncServiceById({ id: id, serviceId: serviceId }));
    }
  }, [dispatch, id, serviceId, editService]);
  const selectedServices = useSelector((state) => state.Services.singleService);
  const status = useSelector((state) => state.Services.fetchSingleStatus);

  // Set the existing data
  useEffect(() => {
    if (editService) {
      if (selectedServices) {
        setName(selectedServices.name || "");
        setDescription(selectedServices.description || "");
        setWaitlistPriceInDollar(selectedServices.waitlistPriceInDollar || "");
        setReservationPriceInDollar(
          selectedServices.reservationPriceInDollar || ""
        );
        setDurationInMinutes(selectedServices.durationInMinutes || "");
      }
    }
  }, [selectedServices, editService]);

  // Handle Form Submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      name,
      description,
      waitlistPriceInDollar,
      reservationPriceInDollar,
      durationInMinutes,
    };
    try {
      setLoading(true);
      await serviceSchema.validate(data);
      if (editService) {
        await dispatch(updateAsyncService({ data, id, serviceId }))
          .then(() => {
            Swal.fire("Updated!", "Your Service has been updated.", "success");
            navigate(`/singleCategory/${id}`);
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              "There was an error updating your Service.",
              "error"
            );
          });
      } else {
        await dispatch(createAsyncService({ data: data, id }))
          .then(() => {
            Swal.fire("Created!", "Your Service has been created.", "success");
            navigate(`/singleCategory/${id}`);
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              "There was an error creating your Service.",
              "error"
            );
          });
      }
    } catch (error) {
      console.error("Validation Error:", error);
      Swal.fire("Validation Error", error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  // Validate field individually
  const validateField = async (name, value) => {
    try {
      // Validate the field only
      await serviceSchema.validateAt(name, { [name]: value });
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (error) {
      // Set error message if validation fails
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  // Handle field change
  const handleFieldChange = (name, value) => {
    // Set the touched state
    setTouched((prev) => ({ ...prev, [name]: true }));
    // Validate the field
    validateField(name, value);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-8">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                {editService ? (
                  <h6 className="title">Edit services</h6>
                ) : (
                  <h6 className="title">Add new services</h6>
                )}
              </div>
              {status === "loading" ? (
                <>
                  <Loading status={status} />
                </>
              ) : (
                <>
                  <form onSubmit={handleFormSubmit} className="profile-form">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6 m-b30">
                          <label htmlFor="name" className="form-label">
                            Name*
                          </label>
                          <input
                            type="text"
                            id="name"
                            value={name}
                            className="form-control"
                            onChange={(e) => {
                              setName(e.target.value);
                              handleFieldChange("name", e.target.value);
                            }}
                            onBlur={() => handleFieldChange("name", name)}
                          />
                          {touched.name && errors.name && (
                            <p style={{ color: "red" }}>{errors.name}</p>
                          )}
                        </div>

                        <div className="col-sm-6 m-b30">
                          <label htmlFor="name" className="form-label">
                            Description
                          </label>
                          <input
                            type="text"
                            id="description"
                            value={description}
                            className="form-control"
                            onChange={(e) => {
                              setDescription(e.target.value);
                              handleFieldChange("description", e.target.value);
                            }}
                            onBlur={() =>
                              handleFieldChange("description", description)
                            }
                          />
                          {touched.description && errors.description && (
                            <p style={{ color: "red" }}>{errors.description}</p>
                          )}
                        </div>
                        <div className="col-6 m-b30">
                          <label htmlFor="name" className="form-label">
                            Wait List Price
                          </label>
                          <input
                            type="number"
                            id="waitlistPriceInDollar"
                            value={waitlistPriceInDollar}
                            className="form-control"
                            onChange={(e) => {
                              setWaitlistPriceInDollar(e.target.value);
                              handleFieldChange(
                                "waitlistPriceInDollar",
                                e.target.value
                              );
                            }}
                            onBlur={() =>
                              handleFieldChange(
                                "waitlistPriceInDollar",
                                waitlistPriceInDollar
                              )
                            }
                          />
                          {touched.waitlistPriceInDollar &&
                            errors.waitlistPriceInDollar && (
                              <p style={{ color: "red" }}>
                                Wait List Price in Dollar is required and must
                                be a number
                              </p>
                            )}
                        </div>
                        <div className="col-6 m-b30">
                          <label htmlFor="name" className="form-label">
                            Reservation Price
                          </label>
                          <input
                            type="number"
                            id="reservationPriceInDollar"
                            value={reservationPriceInDollar}
                            className="form-control"
                            onChange={(e) => {
                              setReservationPriceInDollar(e.target.value);
                              handleFieldChange(
                                "reservationPriceInDollar",
                                e.target.value
                              );
                            }}
                            onBlur={() =>
                              handleFieldChange(
                                "reservationPriceInDollar",
                                reservationPriceInDollar
                              )
                            }
                          />
                          {touched.reservationPriceInDollar &&
                            errors.reservationPriceInDollar && (
                              <p style={{ color: "red" }}>
                                Reservation Price in Dollar is required and must
                                be a number
                              </p>
                            )}
                        </div>
                        <div className="col-6 m-b30">
                          <label htmlFor="time" className="form-label">
                            Duration in Minutes
                          </label>
                          <input
                            type="number"
                            id="time"
                            value={durationInMinutes}
                            className="form-control"
                            onChange={(e) => {
                              setDurationInMinutes(e.target.value);
                              handleFieldChange(
                                "durationInMinutes",
                                e.target.value
                              );
                            }}
                            onBlur={() =>
                              handleFieldChange(
                                "durationInMinutes",
                                durationInMinutes
                              )
                            }
                          />
                          {touched.durationInMinutes &&
                            errors.durationInMinutes && (
                              <p style={{ color: "red" }}>
                                Duration in Minutes is required and must be a
                                number
                              </p>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="card-footer">
                      {loading === true ? (
                        <div className="btn btn-primary d-flex align-items-center justify-content-center">
                          <span className="me-2">Initiated</span>
                          <Spinner animation="border" size="sm" />
                        </div>
                      ) : (
                        <input
                          type="submit"
                          value={editService ? "Save" : "Add Service"}
                          onClick={() => {
                            Swal.fire({
                              position: "top-end",
                              title: "Wait a moment, please",
                              showConfirmButton: false,
                              icon: "info",
                              timer: 2000,
                              toast: true,
                            });
                          }}
                          className={`btn btn-primary`}
                          disabled={loading}
                        />
                      )}
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceForm;
