import React, { lazy, Suspense, useEffect } from "react";
import { createBrowserRouter } from "react-router-dom";
import Error404 from "./pages/Error404";

import Home from "./components/Dashboard/Home/Home";
import Visit from "./pages/Visits/Visit";
import EmployeeForm from "./pages/Employee/EmployeeForm";
import Category from "./pages/Category/Category";
import AllEmployee from "./pages/Employee/AllEmployee";
import SingleCategory from "./pages/Category/SingleCategory";

//Email File
import Inbox from "./pages/Inbox/Inbox";

import General from "./pages/General/General";
import Waitlist from "./pages/Waitlist";
import Booking from "./pages/Booking";
import Services from "./pages/Services";
import Profile from "./pages/Profile";
import MainLayout from "./layouts/MainLayout";
import Login from "./pages/Login";
import ServiceForm from "./pages/Service/ServiceForm";
import CategoryForm from "./pages/Category/CategoryForm";
import { Toaster } from "react-hot-toast";
import ViewEmployee from "./pages/Employee/ViewEmployee";
import History from "./pages/History";
import Customers from "./components/Dashboard/Customers";
import ConfirmPassword from "./pages/ConfirmPassword";
import Trending from "./pages/Trending/Trending";
import Gallery from "./pages/Gallery";

// Expense Tracker Pages
import Discovery from "./pages/Discovery";
import ExpenseHistory from "./pages/ExpenseHistory/ExpenseHistory";
import OtherExpense from "./pages/ExpenseTracker/OtherExpense/OtherExpense";
import RentOrUtilities from "./pages/ExpenseTracker/RentOrUtilities/RentOrUtilities";
import Employees from "./pages/ExpenseTracker/Employee/Employees";
import ProductTracker from "./pages/ExpenseTracker/ProductTracker/ProductTracker";
import InventoryCard from "./pages/Inventory/InventoryCard";
import InventoryHistory from "./pages/Inventory/InventoryHistory";
import InventoryUser from "./pages/Inventory/InventoryUser";
import PrivateRoute from "./PrivateRoute";
import UserProfile from "./pages/UserProfile/UserProfile";

const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <MainLayout></MainLayout>
        <Toaster />
      </>
    ),
    errorElement: <Error404></Error404>,
    children: [
      { path: "/", element:<PrivateRoute allowedRoles={['admin']}> <Home /></PrivateRoute>},
      { path: "visit-page", element:<PrivateRoute allowedRoles={['admin', 'supervisor', 'user']}> <Visit /></PrivateRoute>},
      { path: "/employee/add", element:<PrivateRoute allowedRoles={['admin']}> <EmployeeForm /></PrivateRoute>},
      { path: "/employee/:id/edit", element:<PrivateRoute allowedRoles={['admin']}> <EmployeeForm /></PrivateRoute>},
      { path: "/employee/:id", element:<PrivateRoute allowedRoles={['admin']}> <ViewEmployee /></PrivateRoute>},
      { path: "employees", element:<PrivateRoute allowedRoles={['admin']}> <AllEmployee /></PrivateRoute>},
      { path: "category-page", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Category /></PrivateRoute>},
      { path: "/singleCategory/:id", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <SingleCategory /></PrivateRoute>},
      { path: "/singleCategory/:id/addService", element:<PrivateRoute allowedRoles={['admin']}> <ServiceForm /></PrivateRoute>},
      { path: "/singleCategory/:id/editCategory", element:<PrivateRoute allowedRoles={['admin']}> <CategoryForm /></PrivateRoute>},
      { path: "/category/add", element:<PrivateRoute allowedRoles={['admin']}> <CategoryForm /></PrivateRoute>},
      { path: "/singleCategory/:id/editService/:serviceId", element:<PrivateRoute allowedRoles={['admin']}> <ServiceForm /></PrivateRoute>},
      { path: "gallery-page", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Gallery /></PrivateRoute>},
      { path: "trending-page", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Trending /></PrivateRoute>},
      { path: "general-page", element:<PrivateRoute allowedRoles={['admin']}> <General /></PrivateRoute>},
      { path: "profile-page", element:<PrivateRoute allowedRoles={['admin']}> <Profile /></PrivateRoute>},
      { path: "history-page", element:<PrivateRoute allowedRoles={['admin']}> <History /></PrivateRoute>},
      { path: "customers-page", element:<PrivateRoute allowedRoles={['admin']}> <Customers /></PrivateRoute>},
      { path: "compose-page", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Inbox /></PrivateRoute>},
      { path: "inbox-page", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Inbox /></PrivateRoute>},
      { path: "all-message", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Inbox /></PrivateRoute>},
      { path: "sent-message", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Inbox /></PrivateRoute>},
      { path: "unread-message", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Inbox /></PrivateRoute>},
      { path: "single-message/:id", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <Inbox /></PrivateRoute>},
      // Expense Tracker Routes
      { path: "discovery-page", element:<PrivateRoute allowedRoles={['admin']}> <Discovery /></PrivateRoute>},
      { path: "expense-history", element:<PrivateRoute allowedRoles={['admin']}> <ExpenseHistory /></PrivateRoute>},
      { path: "other-expense", element:<PrivateRoute allowedRoles={['admin']}> <OtherExpense /></PrivateRoute>},
      { path: "rent-utilities", element:<PrivateRoute allowedRoles={['admin']}> <RentOrUtilities /></PrivateRoute>},
      { path: "employees-tracker", element:<PrivateRoute allowedRoles={['admin']}> <Employees /></PrivateRoute>},
      { path: "product-tracker", element:<PrivateRoute allowedRoles={['admin']}> <ProductTracker /></PrivateRoute>},
      // Inventory Routes
      { path: "inventory-card", element:<PrivateRoute allowedRoles={['admin', 'supervisor']}> <InventoryCard /></PrivateRoute>},
      { path: "inventory-history", element:<PrivateRoute allowedRoles={['admin']}> <InventoryHistory /></PrivateRoute>},
      { path: "inventory-user", element:<PrivateRoute allowedRoles={['admin', 'supervisor', 'user']}> <InventoryUser /></PrivateRoute>},
      { path: "profile-details", element:<PrivateRoute allowedRoles={['admin', 'supervisor', 'user']}> <UserProfile /></PrivateRoute>},
    ],
  },
  { path: "login", element: <Login /> },
  { path: "confirmPassword", element: <ConfirmPassword /> },
]);

export default Router;
