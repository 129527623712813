import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsyncCategory,
  fetchAsyncCategoryById,
} from "../../../store/category/categorySlice";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import { MdOutlineDeleteForever } from "react-icons/md";
import {
  deleteAsyncService,
  fetchAsyncAllServices,
} from "../../../store/categoryServices/serviceSlice";
import { Spinner } from "react-bootstrap";

const SingleCategory = () => {
  // All States
  const [newService, setNewService] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);

  // hooks
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handler Modal open and close
  const handleCloseService = () => setNewService(false);
  const handleCloseName = () => setChangeName(false);

  // Fetching single category
  useEffect(() => {
    dispatch(fetchAsyncCategoryById(id));
  }, [dispatch, id]);
  const singleCategory = useSelector((state) => state.Category.singleCategory);

  // Fetching all services
  useEffect(() => {
    dispatch(fetchAsyncAllServices(id));
  }, [dispatch, id]);
  const allServices = useSelector((state) => state.Services.allServices);
  const status = useSelector((state) => state.Services.fetchStatus);

  // Delete Single Service
  const handleDeleteService = async (serviceId) => {
    const result = await Swal.fire({
      title: "Are you sure for delete?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd6b55",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [serviceId]: true,
        }));
        await dispatch(deleteAsyncService({ id: id, serviceId: serviceId }));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } catch (error) {
        Swal.fire("Error", "An error occurred while deleting.", "error");
        console.error("Error deleting service:", error);
      } finally {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [serviceId]: false,
        }));
      }
    }
  };

  // Edit Single Service
  const handleEditService = (serviceId) => {
    navigate(`/singleCategory/${id}/editService/${serviceId}`);
  };

  // Delete Single Category
  const deleteTheCategory = async (categoryId) => {
    const result = await Swal.fire({
      title: "Are you sure for delete?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd6b55",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteAsyncCategory(categoryId));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        navigate("/category-page");
      } catch (error) {
        Swal.fire("Error", "An error occurred while deleting.", "error");
        console.error("Error deleting service:", error);
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  // Effect to filter services based on search term
  useEffect(() => {
    const filtered = allServices.filter((service) => {
      const searchTermLower = searchTerm.toLowerCase();
      const nameIncludes = service.name.toLowerCase().includes(searchTermLower);
      const durationIncludes = service.durationInMinutes
        .toString()
        .includes(searchTermLower);
      const waitlistPriceIncludes = service.waitlistPriceInDollar
        .toString()
        .includes(searchTermLower);
      const reservationPriceIncludes = service.reservationPriceInDollar
        .toString()
        .includes(searchTermLower);

      return (
        nameIncludes ||
        durationIncludes ||
        waitlistPriceIncludes ||
        reservationPriceIncludes
      );
    });

    setFilteredServices(filtered);
  }, [searchTerm, allServices]);

  return (
    <>
      <div className="d-flex gap-2 p-3 my-3">
        <Link to={`/singleCategory/${id}/editCategory`}>
          <button className={`btn btn-primary`}>Edit Category</button>
        </Link>
        <Link to={`/singleCategory/${id}/addService`}>
          <button className="btn btn-primary">Create New Service</button>
        </Link>
      </div>
      <div className="container">
        <div className="card">
          <div className="card-body d-flex flex-lg-row flex-column justify-content-between">
            {singleCategory && (
              <h3 className="mb-0 text-lg-start text-center mb-lg-0 mb-4">
                {singleCategory.name}
              </h3>
            )}
            <button
              className="btn btn-danger"
              onClick={() => {
                deleteTheCategory(singleCategory.id);
              }}
            >
              <span className="me-2">Delete this category</span>
              {deleteLoading === true ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <MdOutlineDeleteForever className="fs-4" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="container mh-auto">
        <div className="row mb-3">
          <div className="col-lg-6">
            <h2 className="text-start col-6 mb-3">Services</h2>
          </div>
          <div className="col-lg-6">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon2"
              >
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {status === "loading" ? (
            <>
              <div class="spinner-border mx-auto mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </>
          ) : (
            <>
              {allServices?.length === 0 ? (
                <p className="text-danger">There is no services.</p>
              ) : filteredServices?.length === 0 ? (
                <p className="text-danger">No search result found.</p>
              ) : (
                filteredServices?.map((item, index) => (
                  <div className="col-lg-12 col-xl-6 col-xxl-4" key={index}>
                    <div className="card">
                      <div className="card-body">
                        <div className="m-b-30">
                          <div className="new-arrival-content position-relative">
                            <div className="row">
                              <div className="col-6">
                                <h4>
                                  <h5 to="ecom-product-detail">{item.name}</h5>
                                </h4>
                                <p>
                                  Duration:
                                  <span className="item ms-1 fw-bold">
                                    {item.durationInMinutes} min
                                  </span>
                                </p>
                                <p>
                                  Wait List Price:
                                  <span className="item ms-1 fw-bold">
                                    ${item.waitlistPriceInDollar}
                                  </span>
                                </p>
                                <p>
                                  Reservation Price:
                                  <span className="item ms-1 fw-bold">
                                    ${item.reservationPriceInDollar}
                                  </span>
                                </p>
                              </div>
                              <div className="col-6 text-end d-inline-flex align-content-center justify-content-end">
                                <FaEdit
                                  className="fs-3 me-3 text-primary cursor-pointer"
                                  onClick={() => handleEditService(item.id)}
                                />
                                {loadingStates[item.id] === true ? (
                                  <Spinner
                                    className="mt-1"
                                    animation="border"
                                    size="sm"
                                  />
                                ) : (
                                  <FaTrashAlt
                                    onClick={() => handleDeleteService(item.id)}
                                    className="fs-3 text-danger cursor-pointer"
                                  ></FaTrashAlt>
                                )}
                              </div>
                            </div>
                            <p className="text-content">{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {/* {allServices.map((item, index) => (
                <div className="col-lg-12 col-xl-6 col-xxl-4" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <div className="m-b-30">
                        <div className="new-arrival-content position-relative">
                          <div className="row">
                            <div className="col-6">
                              <h4>
                                <Link to="ecom-product-detail">
                                  {item.name}
                                </Link>
                              </h4>
                              <p>
                                Duration:
                                <span className="item ms-1 fw-bold">
                                  {item.durationInMinutes} min
                                </span>
                              </p>
                              <p>
                                Wait List Price:
                                <span className="item ms-1 fw-bold">
                                  ${item.waitlistPriceInDollar}
                                </span>
                              </p>
                              <p>
                                Reservation Price:
                                <span className="item ms-1 fw-bold">
                                  ${item.reservationPriceInDollar}
                                </span>
                              </p>
                            </div>
                            <div className="col-6 text-end d-inline-flex align-content-center justify-content-end">
                              <FaEdit
                                className="fs-3 me-3 text-primary cursor-pointer"
                                onClick={() => handleEditService(item.id)}
                              />
                              {loadingStates[item.id] === true ? (
                                <Spinner
                                  className="mt-1"
                                  animation="border"
                                  size="sm"
                                />
                              ) : (
                                <FaTrashAlt
                                  onClick={() => handleDeleteService(item.id)}
                                  className="fs-3 text-danger cursor-pointer"
                                ></FaTrashAlt>
                              )}
                            </div>
                          </div>
                          <p className="text-content">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleCategory;
