import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { amplifyConfigs } from "../config";

const url = amplifyConfigs.AppSync.GraphQL.endpoint;
const region = "eu-central-1";
const auth = {
  type: "API_KEY",
  apiKey: amplifyConfigs.AppSync.GraphQL.apiKey,
};

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const subscriptionClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export { subscriptionClient };
