import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRecentExpense, getTotalExpense } from "./discoveryAPI";

export const fetchAsyncGetExpense = createAsyncThunk(
  "discovery/fetchExpense",
  async () => {
    const response = await getRecentExpense();
    return response;
  }
);

// Get Total Expense
export const fetchAsyncGetTotalExpense = createAsyncThunk(
  "discovery/fetchTotalExpense",
  async () => {
    const response = await getTotalExpense();
    return response;
  }
);

const initialState = {
  expense: [],
  totalExpense: {},
  expenseStatus: "idle",
  totalExpenseStatus: "idle",
  error: null,
};

const discoverySlice = createSlice({
  name: "discovery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get All Expense
      .addCase(fetchAsyncGetExpense.pending, (state) => {
        state.expenseStatus = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncGetExpense.fulfilled, (state, { payload }) => {
        state.expense = payload;
        state.expenseStatus = "success";
      })
      .addCase(fetchAsyncGetExpense.rejected, (state, action) => {
        state.expenseStatus = "failed";
        state.error = action.error.message;
      })
      // Get Total Expense
      .addCase(fetchAsyncGetTotalExpense.pending, (state) => {
        state.totalExpenseStatus = "loading";
        state.error = null;
      })
      .addCase(fetchAsyncGetTotalExpense.fulfilled, (state, { payload }) => {
        state.totalExpense = payload;
        state.totalExpenseStatus = "success";
      })
      .addCase(fetchAsyncGetTotalExpense.rejected, (state, action) => {
        state.totalExpenseStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default discoverySlice.reducer;
