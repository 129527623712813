import { del, get, post, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

// Get all Services
export async function getServicesByCategoryId(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${id}/services`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Get Single Service
export async function getServiceByCategoryId({ id, serviceId }) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${id}/services/${serviceId}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get single call failed: ", error);
  }
}

// Create a Service
export async function createServiceByCategoryId({ data, id }) {
  console.log(data);
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${id}/services`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("POST call failed: ", error);
  }
}

// Update a Service
export async function updateServiceByCategoryId({ data, id, serviceId }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${id}/services/${serviceId}`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Put call failed: ", error);
  }
}

// Delete a Service
export async function deleteServiceByCategoryId({ id, serviceId }) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/categories/${id}/services/${serviceId}`,
    });
    await restOperation.response;
  } catch (error) {
    console.log("DELETE call failed: ", error);
  }
}
