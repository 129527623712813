import {
  fetchUserAttributes,
  getCurrentUser,
  signIn,
  signOut,
} from "aws-amplify/auth";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

export const LoginAction = createAsyncThunk(
  "auth/login",
  async ({ username, password, navigate }) => {
    try {
      const user = await signIn({ username, password });
      if (user.isSignedIn && user.nextStep.signInStep === "DONE") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Login Success",
        });
        // Clear local storage after successful login
        navigate("/");
        // localStorage.clear();
      } else if (
        user.nextStep.signInStep ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        const encodedPassword = encodeURIComponent(password);
        navigate(
          `/confirmPassword?username=${username}&password=${encodedPassword}`
        );
      }
    } catch (error) {
      console.log("error signing in", error.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
    }
  }
);

export const LogoutAction = createAsyncThunk(
  "auth/logout",
  async ({ navigate }) => {
    try {
      await signOut();
      navigate("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
);

const initialState = {
  user: null,
  status: false,
  error: null,
  userInfo: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userRole: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginAction.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(LoginAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.error = null;
      })
      .addCase(LoginAction.rejected, (state, action) => {
        state.status = "failed";
        state.user = null;
        state.error = action.error.message;
      })
      .addCase(LogoutAction.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(LogoutAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.error = null;
      })
      .addCase(LogoutAction.rejected, (state, action) => {
        state.status = "failed";
        state.user = null;
        state.error = action.error.message;
      });
  },
});

export default authSlice.reducer;
