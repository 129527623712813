import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAsyncUserProfile } from "../../../store/employee/EmployeeSlice";
import Loading from "../../components/Loading";

const UserProfile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncUserProfile());
  }, [dispatch]);

  const data = useSelector((state) => state.Employee.userProfile);
  const userProfileStatus = useSelector(
    (state) => state.Employee.userProfileStatus
  );

  return (
    <>
      <div className="container">
        <div className="card m-3 py-5">
          <div className="p-3">
            <div className="row justify-content-between g-5 align-items-center">
              {userProfileStatus === "loading" ? (
                <Loading status={userProfileStatus} />
              ) : (
                <>
                  <div className="col-12 text-center col-lg-6">
                    <img className="employee-image" src={data?.url} alt="" />
                  </div>
                  <div className="col-12 col-lg-6 text-center text-lg-start">
                    <div>
                      <h4 className="text-muted">{data?.title}</h4>
                      <h2>{data?.name}</h2>
                      <p>{data?.description}</p>
                      {data?.phone && (
                        <p>
                          <span className="fw-bold me-2">Phone: </span>
                          {data.phone}
                        </p>
                      )}
                      {data?.dateOfBirth && (
                        <p>
                          <span className="fw-bold me-2">Date Of Birth: </span>
                          {data?.dateOfBirth}
                        </p>
                      )}
                      {data?.dateOfEmployment && (
                        <p>
                          <span className="fw-bold me-2">
                            Date Of Employment
                          </span>
                          {data?.dateOfEmployment}
                        </p>
                      )}
                      {data?.email && (
                        <p>
                          <span className="fw-bold me-2">Email: </span>
                          {data?.email}
                        </p>
                      )}
                      {data?.link ? (
                        <p>
                          <span className="fw-bold me-2">Link: </span>
                          {data?.link}
                        </p>
                      ) : (
                        <p>
                          <span className="fw-bold me-2">Link: </span>
                          {data?.barberShopLink}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
