import React, { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import Card from "./Card";
import TabViewCard from "./TabViewCard/TabViewCard";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchCompleted } from "../../../store/complete/completeSlice";
import { fetchUserRole } from "../../../store/userInfo/userInfoSlice";

const CompleteToday = ({ tableView }) => {
  const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
  const dispatch = useDispatch();
  const [filteredComplete, setFilteredComplete] = useState([]);
  
    useEffect(() => {
      dispatch(fetchCompleted());
      dispatch(fetchUserRole());

    }, [dispatch]);
    const todaysReservation = useSelector((state) => state.Complete.completed);
    
  // Offcanvas for card details
  const [show, setShow] = useState(false);
  const [waitlistId, setWaitlistId] = useState("");
  const handleCardClick = (id) => {
    setWaitlistId(id);
    setShow(true);
  };

  const userData = useSelector((state) => state.Employee.userProfile);
  const data = useSelector((state) => state.User.role);
  console.log(data);
  

  useEffect(() => {
    // Check if the logged-in user is Admin
    if (data === "admin" || data === "supervisor") {
      // If Admin, return all waitlist items
      
      setFilteredComplete(todaysReservation);
    } else {
      // If not Admin, filter the waitlist by the logged-in employee's name
      const filter = todaysReservation.filter(
        (servingItem) => servingItem.employee.name === userData.name
      );
      setFilteredComplete(filter);
      
    }
  }, [userData, data, todaysReservation]);


  return (
    <div className="">
      <div className="row">
        <div className="col-12">
          <div className="bg-white rounded-3 p-3">
            <div className="row align-items-center">
              <div className="col-9">
                <h4 className="mb-0">Completed today</h4>
                <p className="mb-0">{todaysReservation?.length} Client</p>
              </div>
            </div>
          </div>
        </div>
        {isTablet || tableView ? (
          <>
            <TabViewCard
              name={"completed"}
              items={todaysReservation}
            ></TabViewCard>
          </>
        ) : (
          <>
            {[...(filteredComplete || [])]?.reverse().map((item) => (
              <Card
                item={item}
                name={"completed"}
                handleCardClick={handleCardClick}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CompleteToday;
