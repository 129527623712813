import React, { useState } from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";

const TemplateModal = ({ show, onHide, onOptionSelect }) => {
  const [title, setTitle] = useState("");

  const handleOptionSelectInternal = (option) => {
    onOptionSelect(option);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your title here..."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="formOptions">
            <Form.Label>Options</Form.Label>
            <Dropdown onSelect={handleOptionSelectInternal} className="w-100">
              <Dropdown.Toggle variant="outline-primary w-100" id="dropdown-basic">
                Select an option
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="option1">Option 1</Dropdown.Item>
                <Dropdown.Item eventKey="option2">Option 2</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleOptionSelectInternal("save")}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateModal;
