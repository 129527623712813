import { del, get, post } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";

// Get all messages
export async function getAsyncAllMessages() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/messages`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log(error);
  }
}

// Get Unread messages
export async function getAsyncUnreadMessage() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/unread-messages`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log(error);
  }
}

// single message
export async function getAsyncSingleMessage(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/message/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log(error);
  }
}

// Delete message
export async function deleteAsyncMessage(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/message/${id}`,
    });
    await restOperation.response;
  } catch (error) {
    console.log(error);
  }
}

// Compose message
export async function postAsyncComposeMessage({ data }) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/promo-messages`,
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log(error);
  }
}

// Sent All messages
export async function getAsyncSentAllMessage(pages) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/promo-messages/${pages}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log(error);
  }
}

// Sent Reply message
export async function postAsyncReplyMessage({ data, messageId }) {
  console.log(data, messageId);
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: `/message/${messageId}`,
      options: { body: data }
    });
    await restOperation.response;
  } catch (error) {
    console.log(error);
  }
}
