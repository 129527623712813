import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
// import Home from "./components/Dashboard/Home";
// import DashboardDark from "./components/Dashboard/DashboardDark";
// import OrderList from "./components/Dashboard/OrderList";
// import OrderDetails from "./components/Dashboard/OrderDetails";
// import Analytics from "./components/Dashboard/Analytics";
// import Reviews from "./components/Dashboard/Reviews";
// import HomeCustomers from "./components/Dashboard/Customers";
// import Blog from "./components/Dashboard/Blog";

//Apps
// import Chat from "./components/AppsMenu/Chat";
// import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
// import AppProfile2 from "./components/AppsMenu/AppProfile/AppProfile2";
// import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
// import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
// import Compose from "./components/AppsMenu/Email/Compose/Compose";
// import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
// import Read from "./components/AppsMenu/Email/Read/Read";
// import Calendar from "./components/AppsMenu/Calendar/Calendar";

/// Product List
// import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
// import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
// import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
// import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
// import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
// import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
// import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
// import SparklineChart from "./components/charts/Sparkline";
// import ChartJs from "./components/charts/Chartjs";
// import RechartJs from "./components/charts/rechart";
// import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
// import UiAccordion from "./components/bootstrap/Accordion";
// import UiAlert from "./components/bootstrap/Alert";
// import UiBadge from "./components/bootstrap/Badge";
// import UiButton from "./components/bootstrap/Button";
// import UiModal from "./components/bootstrap/Modal";
// import UiButtonGroup from "./components/bootstrap/ButtonGroup";
// import UiListGroup from "./components/bootstrap/ListGroup";
// import UiCards from "./components/bootstrap/Cards";
// import UiCarousel from "./components/bootstrap/Carousel";
// import UiDropDown from "./components/bootstrap/DropDown";
// import UiPopOver from "./components/bootstrap/PopOver";
// import UiProgressBar from "./components/bootstrap/ProgressBar";
// import UiTab from "./components/bootstrap/Tab";
// import UiPagination from "./components/bootstrap/Pagination";
// import UiGrid from "./components/bootstrap/Grid";
// import UiTypography from "./components/bootstrap/Typography";

/// Plugins
// import Select2 from "./components/PluginsMenu/Select2/Select2";
// import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
// import Toastr from "./components/PluginsMenu/Toastr/Toastr";
// import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
// import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

// Widget
// import Widget from "./pages/Widget";

// Svg Icon
// import SvgIcon from "./pages/SvgIcon";

/// Table
// import SortingTable from "./components/table/SortingTable/SortingTable";
// import FilteringTable from "./components/table/FilteringTable/FilteringTable";
// import BootstrapTable from "./components/table/BootstrapTable";

/// Form
// import Element from "./components/Forms/Element/Element";
// import Wizard from "./components/Forms/Wizard/Wizard";
// import CkEditor from "./components/Forms/CkEditor/CkEditor";
// import Pickers from "./components/Forms/Pickers/Pickers";
// import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import { ThemeContext } from "../context/ThemeContext";
import Visit from "./pages/Visits/Visit";
import EmployeeForm from "./pages/Employee/EmployeeForm";
import Category from "./pages/Category/Category";
import Service from "./pages/Service/Service";
import AllEmployee from "./pages/Employee/AllEmployee";
import SingleEmployee from "./pages/Employee/SingleEmployee";
import SingleCategory from "./pages/Category/SingleCategory";
import Gallery from "./pages/Gallery";
import General from "./pages/General/General";
import Waitlist from "./pages/Waitlist";
import Booking from "./pages/Booking";
import Services from "./pages/Services";
import Profile from "./pages/Profile";

const Markup = () => {
  const allroutes = [
    // Dashboard
    // { url: "", component: <Home /> },
    // { url: "dashboard", component: <Home /> },
    // { url: "dashboard-dark", component: <DashboardDark /> },
    // { url: "order-list", component: <OrderList /> },
    // { url: "order-details", component: <OrderDetails /> },
    // { url: "analytics", component: <Analytics /> },
    // { url: "customers", component: <HomeCustomers /> },
    // { url: "reviews", component: <Reviews /> },
    // { url: "blog", component: <Blog /> },

    //Apps

    // { url: "chat", component: <Chat /> },
    // { url: "app-profile", component: <AppProfile /> },
    // { url: "app-profile-2", component: <AppProfile2 /> },
    // { url: "edit-profile", component: <EditProfile /> },
    // { url: "post-details", component: <PostDetails /> },
    // { url: "email-compose", component: <Compose /> },
    // { url: "email-inbox", component: <Inbox /> },
    // { url: "email-read", component: <Read /> },
    // { url: "app-calender", component: <Calendar /> },

    // Shop
    // { url: "ecom-product-grid", component: <ProductGrid /> },
    // { url: "ecom-product-list", component: <ProductList /> },
    // { url: "ecom-product-detail", component: <ProductDetail /> },
    // { url: "ecom-product-order", component: <ProductOrder /> },
    // { url: "ecom-checkout", component: <Checkout /> },
    // { url: "ecom-invoice", component: <Invoice /> },
    // { url: "ecom-customers", component: <Customers /> },
    // // Chart

    // { url: "chart-sparkline", component: <SparklineChart /> },
    // { url: "chart-chartjs", component: <ChartJs /> },
    // { url: "chart-apexchart", component: <ApexChart /> },
    // { url: "chart-rechart", component: <RechartJs /> },
    // Bootstrap

    // { url: "ui-accordion", component: <UiAccordion /> },
    // { url: "ui-alert", component: <UiAlert /> },
    // { url: "ui-badge", component: <UiBadge /> },
    // { url: "ui-button", component: <UiButton /> },
    // { url: "ui-modal", component: <UiModal /> },
    // { url: "ui-button-group", component: <UiButtonGroup /> },
    // { url: "ui-list-group", component: <UiListGroup /> },
    // { url: "ui-card", component: <UiCards /> },
    // { url: "ui-carousel", component: <UiCarousel /> },
    // { url: "ui-dropdown", component: <UiDropDown /> },
    // { url: "ui-popover", component: <UiPopOver /> },
    // { url: "ui-progressbar", component: <UiProgressBar /> },
    // { url: "ui-tab", component: <UiTab /> },
    // { url: "ui-pagination", component: <UiPagination /> },
    // { url: "ui-typography", component: <UiTypography /> },
    // { url: "ui-grid", component: <UiGrid /> },
    // // Plugin

    // { url: "uc-select2", component: <Select2 /> },
    // { url: "uc-sweetalert", component: <MainSweetAlert /> },
    // { url: "uc-toastr", component: <Toastr /> },
    // { url: "map-jqvmap", component: <JqvMap /> },
    // { url: "uc-lightgallery", component: <Lightgallery /> },
    // // Widget
    // { url: "widget-basic", component: <Widget /> },

  // Visit
  { url: "visit-page", component: <Visit /> },
  // EmployeeForm
  { url: "/employee/add", component: <EmployeeForm /> },
  { url: "/service/add", component: <EmployeeForm /> },
  { url: "/category/add", component: <EmployeeForm /> },
  { url: "/category/edit", component: <EmployeeForm /> },
  { url: "/employee/:id/edit", component: <EmployeeForm /> },
  { url: "/editEmployee/:id", component: <EmployeeForm /> },
  { url: "employee-page", component: <AllEmployee /> },
  // Category
  { url: "category-page", component: <Category /> },
  { url: "/singleCategory/:id", component: <SingleCategory /> },
  // Service
  { url: "service-page", component: <Service /> },
  // Gallery
  { url: "gallery-page", component: <Gallery /> },
  // General
  { url: "general-page", component: <General /> },
  // Waitlist
  { url: "waitlist-page", component: <Waitlist /> },
  // Booking
  { url: "booking-page", component: <Booking /> },
  // services
  { url: "services-page", component: <Services /> },
  // Profile
  { url: "profile-page", component: <Profile /> },

    // Svg Icon
    // { url: "svg-icons", component: <SvgIcon /> },
    // // Form

    // { url: "form-element", component: <Element /> },
    // { url: "form-wizard", component: <Wizard /> },
    // { url: "form-ckeditor", component: <CkEditor /> },
    // { url: "form-pickers", component: <Pickers /> },
    // { url: "form-validation", component: <FormValidation /> },

    // table
    // { url: "table-filtering", component: <FilteringTable /> },
    // { url: "table-sorting", component: <SortingTable /> },
    // { url: "table-bootstrap-basic", component: <BootstrapTable /> },
    // { url: "*", component: <Home/> },
  ];

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        <Route element={<MainLayout />}>  
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
