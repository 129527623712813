import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncEmployeeById } from "../../../store/employee/EmployeeSlice";
import { Link, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { fetchAsyncServicesByEmployee } from "../../../store/employeeServices/employeeServicesSlice";
import WorkingHoursOfEmployee from "./WorkingHoursOfEmployee";

const ViewEmployee = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncEmployeeById(id));
    dispatch(fetchAsyncServicesByEmployee(id));
  }, [dispatch, id]);
  const employee = useSelector((state) => state.Employee.singleEmployee);
  const status = useSelector((state) => state.Employee.fetchSingleStatus);
  const employeeServices = useSelector(
    (state) => state.EmployeeServices.employeeServices
  );

  return (
    <div className="container">
      <div className="card m-3 py-5">
        <div className="p-3">
          <div className="row justify-content-between g-5 align-items-center">
            {status === "loading" ? (
              <Loading status={status} />
            ) : (
              <>
                <div className="col-12 text-center col-lg-6">
                  <img className="employee-image" src={employee.url} alt="" />
                </div>
                <div className="col-12 col-lg-6 text-center text-lg-start">
                  <div>
                    <h4 className="text-muted">{employee.title}</h4>
                    <h2>{employee.name}</h2>
                    <p>{employee.description}</p>
                    <p>
                      <span className="fw-bold me-2">Phone: </span>
                      {employee.phone}
                    </p>
                    <p>
                      <span className="fw-bold me-2">Date Of Birth: </span>
                      {employee.dateOfBirth}
                    </p>
                    <p>
                      <span className="fw-bold me-2">Date Of Employment</span>
                      {employee.dateOfEmployment}
                    </p>
                    <p>
                      <span className="fw-bold me-2">Email: </span>
                      {employee.email}
                    </p>
                  </div>
                  <Link to={`/employee/${id}/edit`}>
                    <button className="btn btn-primary">Edit Employee</button>
                  </Link>
                </div>
              </>
            )}
            {/* services table start */}
            {employeeServices?.services?.length > 0 && (
              <div>
                <h3 className="text-center">Services List</h3>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="tab-content">
                      <div className="tab-pane fade active show">
                        <div className="card mt-2">
                          <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                              <div
                                id="orderlist-table"
                                className="dataTables_wrapper no-footer px-3"
                              >
                                <table
                                  id="empoloyees-tbl"
                                  className="table dataTable no-footer"
                                >
                                  <thead>
                                    <tr className="text-center">
                                      {/* <th>Index</th> */}
                                      <th>
                                        <span>Services</span>
                                      </th>
                                      <th>
                                        <span></span>Category
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {employeeServices?.services?.map(
                                      (service) => (
                                        <tr
                                          className="text-center"
                                          key={service.id}
                                        >
                                          <td className="text-center">
                                            <span>{service.name}</span>
                                          </td>
                                          <td className="text-center">
                                            <span>
                                              {service?.category?.name}
                                            </span>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* service table ends here */}
            {/* --- Working Hours --- */}
            <WorkingHoursOfEmployee employeeId={id}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEmployee;
