import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addNewReservation,
  addNewWaitlist,
  deleteReservation,
  deleteWaitlist,
  getAvailableTime,
} from "./bookingAndWaitlistApi";

// async thunks
export const fetchAsyncAvailableTimes = createAsyncThunk(
  "availableTimes/fetchAvailableTimes",
  async (data) => {
    const service = await getAvailableTime(data);
    return service;
  }
);

// Async thunk for adding to waitlist
export const createAsyncWaitlist = createAsyncThunk(
  "waitlist/addToWaitlist",
  async (data) => {
    const result = await addNewWaitlist(data);
    return result;
  }
);

// Async thunk for adding to reservation
export const createAsyncReservation = createAsyncThunk(
  "createReservation/addToReservation",
  async (data) => {
    const result = await addNewReservation(data);
    return result;
  }
);

// Async thunk for adding to reservation
export const deleteAsyncWaitlist = createAsyncThunk(
  "cancelWaitlistaitlist/cancelWaitlist",
  async (data) => {
    const result = await deleteWaitlist(data);
    return result;
  }
);

// Async thunk for adding to reservation
export const deleteAsyncReservation = createAsyncThunk(
  "reservation/cancelReservation",
  async (data) => {
    const result = await deleteReservation(data);
    return result;
  }
);

const initialState = {
  availabelTimes: [],
  availabelTimesStatus: "idle",
  availabelTimesError: null,

  addWailist: {},
  waitlistStatus: "idle",
  waitlistError: null,

  cancelWailist: {},
  cancelWaitlistStatus: "idle",
  cancelWaitlistError: null,

  addReservation: {},
  reservationStatus: "idle",
  reservationError: null,

  cancelReservation: {},
  cancelReservationStatus: "idle",
  cancelReservationError: null,
};

const employeeServicesSlice = createSlice({
  name: "employeeServices",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncAvailableTimes.pending, (state) => {
        state.availabelTimesStatus = "loading";
      })
      .addCase(fetchAsyncAvailableTimes.fulfilled, (state, action) => {
        state.availabelTimesStatus = "succeeded";
        state.availabelTimes = action.payload;
      })
      .addCase(fetchAsyncAvailableTimes.rejected, (state, action) => {
        state.availabelTimesStatus = "failed";
        state.availabelTimesError = action.error.message;
      })
      .addCase(createAsyncWaitlist.pending, (state) => {
        state.waitlistStatus = "loading";
      })
      .addCase(createAsyncWaitlist.fulfilled, (state, action) => {
        state.waitlistStatus = "succeeded";
        state.addWailist = action.payload;
      })
      .addCase(createAsyncWaitlist.rejected, (state, action) => {
        state.waitlistStatus = "failed";
        state.waitlistError = action.error.message;
      })
      .addCase(createAsyncReservation.pending, (state) => {
        state.reservationStatus = "loading";
      })
      .addCase(createAsyncReservation.fulfilled, (state, action) => {
        state.reservationStatus = "succeeded";
        state.addReservation = action.payload;
      })
      .addCase(createAsyncReservation.rejected, (state, action) => {
        state.reservationStatus = "failed";
        state.reservationError = action.error.message;
      })
      .addCase(deleteAsyncWaitlist.pending, (state) => {
        state.cancelWaitlistStatus = "loading";
      })
      .addCase(deleteAsyncWaitlist.fulfilled, (state, action) => {
        state.cancelWaitlistStatus = "succeeded";
        state.cancelWailist = action.payload;
      })
      .addCase(deleteAsyncWaitlist.rejected, (state, action) => {
        state.cancelWaitlistStatus = "failed";
        state.cancelWaitlistError = action.error.message;
      })
      .addCase(deleteAsyncReservation.pending, (state) => {
        state.cancelReseavationStatus = "loading";
      })
      .addCase(deleteAsyncReservation.fulfilled, (state, action) => {
        state.cancelReservationStatus = "succeeded";
        state.cancelReservation = action.payload;
      })
      .addCase(deleteAsyncReservation.rejected, (state, action) => {
        state.cancelReservationStatus = "failed";
        state.cancelReservationError = action.error.message;
      });
  },
});

export default employeeServicesSlice.reducer;
