import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteExpenses,
  expensedProducts,
  getAllExpense,
  getExpenseByType,
  getSingleExpense,
  postCalculation,
  postExpense,
  updateExpense,
} from "./ExpenseAPI";

// get all expense
export const FetchAllAsyncExpense = createAsyncThunk(
  "expense/getAllExpense",
  async () => {
    const expense = await getAllExpense();
    return expense;
  }
);

// Get expense by type
export const FetchExpenseByType = createAsyncThunk(
  "expense/getExpenseByType",
  async (type) => {
    console.log(type);
    const expense = await getExpenseByType(type);
    return expense;
  }
);

// Get single expense
export const FetchSingleExpense = createAsyncThunk(
  "expense/getSingleExpense",
  async (id) => {
    const expense = await getSingleExpense(id);
    return expense;
  }
);

// create all expense
export const CreateAsyncExpense = createAsyncThunk(
  "expense/postExpense",
  async (data) => {
    const expense = await postExpense(data);
    return expense;
  }
);

// Update expense
export const UpdateAsyncExpense = createAsyncThunk(
  "expense/updateExpense",
  async (data) => {
    console.log(data, "data");
    const expense = await updateExpense(data);
    return expense;
  }
);

// delete employee
export const deleteAsyncExpense = createAsyncThunk(
  "expense/deleteExpense",
  async (id) => {
    await deleteExpenses(id);
    return id;
  }
);

// Calculate percentage of the bonus of the employee
export const CalculatePercentage = createAsyncThunk(
  "expense/calculatePercentage",
  async (data) => {
    console.log(data);
    const expense = await postCalculation(data);
    return expense;
  }
);

export const addProductToExpense = createAsyncThunk(
  "expense/productExpense",
  async (data) => {
    console.log(data);
    const expense = await expensedProducts(data);
    return expense;
  }
);

const initialState = {
  singleExpense: {},
  expenseByType: {},
  expense: [],
  percentage: {},
  status: "idle",
  singleExpenseStatus: "idle",
  error: null,

  productExpense: {},
  productExpenseStatus: "idle",
  productExpenseError: null,
};

const ExpenseSlice = createSlice({
  name: "expenseTracker",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder
      // get all expense
      .addCase(FetchAllAsyncExpense.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchAllAsyncExpense.fulfilled, (state, { payload }) => {
        state.expense = payload;
        state.status = "succeeded";
      })
      .addCase(FetchAllAsyncExpense.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // create all expense
      .addCase(CreateAsyncExpense.pending, (state) => {
        state.status = "loading";
      })

      .addCase(CreateAsyncExpense.fulfilled, (state, { payload }) => {
        state.expense = payload;
        state.status = "succeeded";
      })
      .addCase(CreateAsyncExpense.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // get expense by type
      .addCase(FetchExpenseByType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchExpenseByType.fulfilled, (state, { payload }) => {
        state.expenseByType = payload;
        state.status = "succeeded";
      })
      .addCase(FetchExpenseByType.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // get single expense
      .addCase(FetchSingleExpense.pending, (state) => {
        state.singleExpenseStatus = "loading";
      })
      .addCase(FetchSingleExpense.fulfilled, (state, { payload }) => {
        state.singleExpense = payload;
        state.singleExpenseStatus = "succeeded";
      })
      .addCase(FetchSingleExpense.rejected, (state, { error }) => {
        state.error = error.message;
        state.singleExpenseStatus = "failed";
      })
      // update expense
      .addCase(UpdateAsyncExpense.pending, (state) => {
        state.status = "loading";
      })
      .addCase(UpdateAsyncExpense.fulfilled, (state, { payload }) => {
        state.expense = state.expense.map((exp) =>
          exp.id === payload.id ? payload : exp
        );
        state.status = "succeeded";
      })
      .addCase(UpdateAsyncExpense.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // delete expense
      .addCase(deleteAsyncExpense.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAsyncExpense.fulfilled, (state, { payload }) => {
        console.log(payload, "payload");
        state.expense = state.expense.filter(
          (expense) => expense.id !== payload
        );
        state.status = "succeeded";
      })
      .addCase(deleteAsyncExpense.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // Calculate percentage
      .addCase(CalculatePercentage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CalculatePercentage.fulfilled, (state, { payload }) => {
        console.log(payload, "payload");
        state.percentage = payload;
        state.status = "succeeded";
      })
      .addCase(CalculatePercentage.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      // create product expense
      .addCase(addProductToExpense.pending, (state) => {
        state.productExpenseStatus = "loading";
      })

      .addCase(addProductToExpense.fulfilled, (state, { payload }) => {
        state.productExpense = payload;
        state.productExpenseStatus = "succeeded";
      })
      .addCase(addProductToExpense.rejected, (state, { error }) => {
        state.productExpenseError = error.message;
        state.productExpenseStatus = "failed";
      });
  },
});

export default ExpenseSlice.reducer;
