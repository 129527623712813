// src/features/user/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserAttributes } from 'aws-amplify/auth';


export const fetchUserRole = createAsyncThunk(
  'user/fetchUserRole',
  async (_, { rejectWithValue }) => {
    try {
      const attributes = await fetchUserAttributes();
      return attributes['custom:role'];
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    role: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserRole.fulfilled, (state, action) => {
        state.role = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserRole.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default userSlice.reducer;
