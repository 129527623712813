import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllInventoryHistory,
  getInventoryHistoryOfIn,
  getInventoryHistoryOfOut,
} from "./inventoryAPI";

// Get all inventory history
export const fetchAllInventoryHistory = createAsyncThunk(
  "reservation/fetchAllInventoryHistory",
  async () => {
    const response = await getAllInventoryHistory();
    return response;
  }
);

// Get inventory history for In product
export const fetchInventoryHistoryOfIn = createAsyncThunk(
  "reservationIn/fetchInventoryHistoryOfIn",
  async () => {
    const response = await getInventoryHistoryOfIn();
    return response;
  }
);

// Get inventory history for Out product
export const fetchInventoryHistoryOfOut = createAsyncThunk(
  "reservationOut/fetchInventoryHistoryOfOut",
  async () => {
    const response = await getInventoryHistoryOfOut();
    return response;
  }
);

const initialState = {
  inventoryHistory: [],
  status: "idle",
  error: null,

  inventoryHistoryIn: [],
  inventoryHistoryInStatus: "idle",
  inventoryHistoryInError: null,

  inventoryHistoryOut: [],
  inventoryHistoryOutStatus: "idle",
  inventoryHistoryOutError: null,
};

const InventoryHistorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get all inventory history
      .addCase(fetchAllInventoryHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllInventoryHistory.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.inventoryHistory = payload;
      })
      .addCase(fetchAllInventoryHistory.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = "failed";
      })
      //get inventory history of in product
      .addCase(fetchInventoryHistoryOfIn.pending, (state) => {
        state.inventoryHistoryInStatus = "loading";
      })
      .addCase(fetchInventoryHistoryOfIn.fulfilled, (state, { payload }) => {
        state.inventoryHistoryInStatus = "succeeded";
        state.inventoryHistoryIn = payload;
      })
      .addCase(fetchInventoryHistoryOfIn.rejected, (state, { error }) => {
        state.inventoryHistoryInError = error.message;
        state.inventoryHistoryInStatus = "failed";
      })
      //get inventory history of in product
      .addCase(fetchInventoryHistoryOfOut.pending, (state) => {
        state.inventoryHistoryOutStatus = "loading";
      })
      .addCase(fetchInventoryHistoryOfOut.fulfilled, (state, { payload }) => {
        state.inventoryHistoryOutStatus = "succeeded";
        state.inventoryHistoryOut = payload;
      })
      .addCase(fetchInventoryHistoryOfOut.rejected, (state, { error }) => {
        state.inventoryHistoryOutError = error.message;
        state.inventoryHistoryOutStatus = "failed";
      });
  },
});

export default InventoryHistorySlice.reducer;
