import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createMapLink, getMapLinks, updateMapLink } from "./mapLinkAPI";

const initialState = {
  mapLink: {},
  state: "idle",
  error: null,
};

// Get map link
export const fetchMapLink = createAsyncThunk(
  "mapLink/fetchMapLink",
  async () => {
    const response = await getMapLinks();
    return response;
  }
);

// post map link
export const createAsyncMap = createAsyncThunk(
  "mapLink/postMapLink",
  async (data) => {
    const response = await createMapLink(data);
    return response;
  }
);

// update map link
export const updateAsyncMapLink = createAsyncThunk(
  "mapLink/updateMapLink",
  async (data) => {
    const response = await updateMapLink(data);
    return response;
  }
);

const mapLinkSlice = createSlice({
  name: "mapLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMapLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMapLink.fulfilled, (state, { payload }) => {
        state.mapLink = payload;
        state.status = "succeeded";
      })
      .addCase(fetchMapLink.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // post map link
      .addCase(createAsyncMap.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAsyncMap.fulfilled, (state, { payload }) => {
        state.mapLink = payload;
        state.status = "succeeded";
      })
      .addCase(createAsyncMap.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // update map link
      .addCase(updateAsyncMapLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAsyncMapLink.fulfilled, (state, { payload }) => {
        state.mapLink = payload;
        state.status = "succeeded";
      })
      .addCase(updateAsyncMapLink.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default mapLinkSlice.reducer;
