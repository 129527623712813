import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  state: "idle",
  error: null,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    increase: (state) => {
      // Increase page number by 1, but don't go above totalPages
      state.page = state.page + 1;
    },
    decrease: (state) => {
      if (state.page > 1) {
        state.page = state.page - 1;
      }
    },
  },
});

export default paginationSlice.reducer;
export const { increase, decrease } = paginationSlice.actions;
