import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteAsyncMessage,
  getAsyncAllMessages,
  getAsyncSingleMessage,
  getAsyncUnreadMessage,
} from "./messagesAPI";

// Get all messages
export const fetchAsyncMessages = createAsyncThunk(
  "messages/fetchAsyncMessages",
  async (pages) => {
    console.log(pages);
    const messages = await getAsyncAllMessages(pages);
    return messages;
  }
);

// Get Unread messages
export const fetchAsyncUnreadMessages = createAsyncThunk(
  "messages/fetchAsyncUnreadMessages",
  async (pages) => {
    const messages = await getAsyncUnreadMessage(pages);
    return messages;
  }
);

// Get single message
export const fetchAsyncSingleMessage = createAsyncThunk(
  "messages/fetchAsyncSingleMessage",
  async (id) => {
    const message = await getAsyncSingleMessage(id);
    return message;
  }
);

// Delete message
export const fetchAsyncDeleteMessage = createAsyncThunk(
  "messages/fetchAsyncDeleteMessage",
  async (id) => {
    const message = await deleteAsyncMessage(id);
    console.log(message);
    return message;
  }
);

const initialState = {
  // allMessages
  allMessages: {},
  allMessageStatus: "idle",
  allMessageError: null,
  // unreadMessage
  unreadMessage: {},
  unreadMessageStatus: "idle",
  unreadMessageError: null,
  // singleMessage
  singleMessage: {},
  singleMessageStatus: "idle",
  singleMessageError: null,
  // deleteMessage
  deleteMessage: {},
  deleteMessageStatus: "idle",
  deleteMessageError: null,
  // get composeMessage
  composeMessage: [],
  composeMessageStatus: "idle",
  composeMessageError: null,
  // get sentMessage
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get All messages
      .addCase(fetchAsyncMessages.pending, (state) => {
        state.allMessageStatus = "loading";
      })
      .addCase(fetchAsyncMessages.fulfilled, (state, action) => {
        state.allMessageStatus = "succeeded";
        state.allMessages = action.payload;
      })
      .addCase(fetchAsyncMessages.rejected, (state, action) => {
        state.allMessageStatus = "rejected";
        state.allMessageError = action.error.message;
      })
      // Get Unread messages
      .addCase(fetchAsyncUnreadMessages.pending, (state) => {
        state.unreadMessageStatus = "loading";
      })
      .addCase(fetchAsyncUnreadMessages.fulfilled, (state, action) => {
        state.unreadMessageStatus = "succeeded";
        state.unreadMessage = action.payload;
      })
      .addCase(fetchAsyncUnreadMessages.rejected, (state, action) => {
        state.unreadMessageStatus = "rejected";
        state.unreadMessageError = action.error.message;
      })
      // Get single message
      .addCase(fetchAsyncSingleMessage.pending, (state) => {
        state.singleMessageStatus = "loading";
      })
      .addCase(fetchAsyncSingleMessage.fulfilled, (state, action) => {
        state.singleMessageStatus = "succeeded";
        state.singleMessage = action.payload;
      })
      .addCase(fetchAsyncSingleMessage.rejected, (state, action) => {
        state.singleMessageStatus = "rejected";
        state.singleMessageError = action.error.message;
      })
      // Delete message
      .addCase(fetchAsyncDeleteMessage.pending, (state) => {
        state.deleteMessageStatus = "loading";
      })
      .addCase(fetchAsyncDeleteMessage.fulfilled, (state, { payload }) => {
        // state.allMessages = state.allMessages.filter(
        //   (message) => message.id !== payload.id
        // );
        state.deleteMessageStatus = "succeeded";
        state.deleteMessage = payload;
        console.log(payload);
      })
      .addCase(fetchAsyncDeleteMessage.rejected, (state, action) => {
        state.deleteMessageStatus = "rejected";
        state.deleteMessageError = action.error.message;
      });
  },
});

export default messagesSlice.reducer;
