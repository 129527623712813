import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createServiceByCategoryId,
  deleteServiceByCategoryId,
  getServiceByCategoryId,
  getServicesByCategoryId,
  updateServiceByCategoryId,
} from "./serviceAPI";

// Get all Services
export const fetchAsyncAllServices = createAsyncThunk(
  "services/fetchAllServices",
  async (categoryId) => {
    const services = await getServicesByCategoryId(categoryId);
    return services;
  }
);

// Get Single Service
export const fetchAsyncServiceById = createAsyncThunk(
  "services/fetchServiceById",
  async (data) => {
    const service = await getServiceByCategoryId(data);
    return service;
  }
);

// Create a Service
export const createAsyncService = createAsyncThunk(
  "services/createService",
  async (data) => {
    const service = await createServiceByCategoryId(data);
    return service;
  }
);

// Update a Service
export const updateAsyncService = createAsyncThunk(
  "services/updateService",
  async (data) => {
    const service = await updateServiceByCategoryId(data);
    return service;
  }
);

// Delete a Service
export const deleteAsyncService = createAsyncThunk(
  "services/deleteService",
  async (data) => {
    const service = await deleteServiceByCategoryId(data);
    return service;
  }
);

const initialState = {
  // Data
  allServices: [],
  singleService: {},
  // Status
  fetchStatus: "idle",
  fetchSingleStatus: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  // Errors
  fetchError: null,
  fetchSingleError: null,
  createError: null,
  updateError: null,
  deleteError: null,
};

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get all Services
      .addCase(fetchAsyncAllServices.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(fetchAsyncAllServices.fulfilled, (state, { payload }) => {
        // console.log("payload", payload)
        state.allServices = payload;
        state.fetchStatus = "succeeded";
      })
      .addCase(fetchAsyncAllServices.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      })
      // Get Single Service
      .addCase(fetchAsyncServiceById.pending, (state) => {
        state.fetchSingleStatus = "loading";
        state.fetchSingleError = null;
      })
      .addCase(fetchAsyncServiceById.fulfilled, (state, action) => {
        state.singleService = action.payload;
        state.fetchSingleStatus = "succeeded";
      })
      .addCase(fetchAsyncServiceById.rejected, (state, action) => {
        state.fetchSingleStatus = "failed";
        state.fetchSingleError = action.error.message;
      })
      // Create a Service
      .addCase(createAsyncService.pending, (state) => {
        state.createStatus = "loading";
        state.createError = null;
      })
      .addCase(createAsyncService.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.allServices.push(action.payload);
        state.createStatus = "succeeded";
      })
      .addCase(createAsyncService.rejected, (state, action) => {
        state.createStatus = "failed";
        state.createError = action.error.message;
      })
      // Update a Service
      .addCase(updateAsyncService.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(updateAsyncService.fulfilled, (state, action) => {
        state.allServices = state.allServices.map((service) =>
          service.id === action.payload.id ? action.payload : service
        );
        state.updateStatus = "succeeded";
      })
      .addCase(updateAsyncService.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.updateError = action.error.message;
      })
      // Delete a Service
      .addCase(deleteAsyncService.pending, (state) => {
        state.deleteStatus = "loading";
        state.deleteError = null;
      })
      .addCase(deleteAsyncService.fulfilled, (state, action) => {
        state.allServices = state.allServices.filter(
          (service) => service.id !== action.meta.arg.serviceId
        );
        state.deleteStatus = "succeeded";
      })
      .addCase(deleteAsyncService.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.deleteError = action.error.message;
      });
  },
});

export default serviceSlice.reducer;
