import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncUnreadMessages } from "../../../store/messages/messagesSlice";
import Loading from "../../components/Loading";

const UnreadMessage = ({ page }) => {
  const dispatch = useDispatch();
  const pages = useSelector((state) => state.Pagination.page);

  // Fetch all unread messages
  useEffect(() => {
    dispatch(fetchAsyncUnreadMessages());
  }, [dispatch]);
  const allUnreadMessage = useSelector((state) => state.Messages.unreadMessage);
  const status = useSelector((state) => state.Messages.unreadMessageStatus);

  
  const [dataToShow, setDataToShow] = useState([]);
  useEffect(() => {
    if (allUnreadMessage?.length > 0) {
      setDataToShow(allUnreadMessage);
    }
  }, [allUnreadMessage]);
  

  // Chackbox function
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 10;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const paginatedData = dataToShow?.slice(firstIndex, lastIndex);

  console.log("paginatedData", paginatedData);
  const npage = Math.ceil(dataToShow?.length / recordsPage);
  const number = [...Array(npage + 1)?.keys()]?.slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <>
      <Tab.Container defaultActiveKey="Import">
        <div className="email-list">
          {status === "loading" ? (
            <Loading status={status} />
          ) : (
            <>
              {allUnreadMessage?.messages?.length === 0 && (
                <div className="text-center mt-5">
                  No unread message in this page
                </div>
              )}
              {dataToShow?.map((data, ind) => (
                <div className="message bg-white" key={ind}>
                  <div>
                    <div className="d-flex message-single">
                      <div className="ps-1 align-self-center mt-3">
                        <div className="form-check custom-checkbox">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={() => chackboxFun()}
                            id="checkbox2"
                          />
                        </div>
                      </div>
                    </div>
                    <Link
                      to={`/single-message/${data.id}`}
                      className="col-mail col-mail-2"
                    >
                      <div className="hader">{data.name}</div>
                      <div className="subject">{data.subject}</div>
                      <div className="date">
                        {new Date(data.createdAt * 1000).toLocaleString()}
                      </div>
                    </Link>
                    {/* <div className="icon-svg"></div> */}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {/* pagination */}
        <div className="d-sm-flex text-center justify-content-between align-items-center">
          {dataToShow?.length > 0 ? (
            <div className="dataTables_info">
              Showing {lastIndex - recordsPage + 1} to{" "}
              {dataToShow?.length < lastIndex ? dataToShow?.length : lastIndex}{" "}
              of {dataToShow?.length} entries
            </div>
          ) : (
            <div className="dataTables_info">No Entries yet</div>
          )}
          <div
            className="dataTables_paginate paging_simple_numbers justify-content-center"
            id="example2_paginate"
          >
            <Link className="btn btn-primary previous" to="#" onClick={prePage}>
              <i className="fa-solid fa-angle-left" />
            </Link>
            <span>
              {number.map((n, i) => (
                <Link
                  className={`btn btn-primary ms-2 ${
                    currentPage === n ? "current" : ""
                  } `}
                  key={i}
                  onClick={() => changeCPage(n)}
                >
                  {n}
                </Link>
              ))}
            </span>
            <Link
              className="btn btn-primary next ms-2"
              to="#"
              onClick={nextPage}
            >
              <i className="fa-solid fa-angle-right" />
            </Link>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

export default UnreadMessage;
