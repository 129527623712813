import React, { useState, useEffect } from "react";
import { IMAGES } from "../../constant/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  checkAsyncEmail,
  createAsyncEmployee,
  deleteAsyncEmployee,
  fetchAsyncEmployeeById,
  updateAsyncEmployee,
} from "../../../store/employee/EmployeeSlice";
import Loading from "../../components/Loading";
import { employeeSchema } from "../../components/ValidationSchema";
import { fetchAsyncAllCategory } from "../../../store/category/categorySlice";
import { fetchAsyncServicesByEmployee } from "../../../store/employeeServices/employeeServicesSlice";
import EmployeeServices from "./EmployeeServices";
import { Spinner } from "react-bootstrap";

const EmployeeForm = () => {
  // All Hooks
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const { id } = useParams();
  // Validation states
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  // All the states
  const [base64, setBase64] = useState("");
  const [image, setImage] = useState("" || IMAGES.User);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // State for the form fields
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfEmployment, setDateOfEmployment] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");

  const [email, setEmail] = useState("");
  const phonePrefix = "+961";


  // Check Edit Employee Form or Add Employee Form
  const [editEmployee, setEditEmployee] = useState(false);
  const [addEmployee, setAddEmployee] = useState(false);

  // check if employee email aleady exists
  const [emailExists, setEmailExists] = useState(false);

  // // Check Edit Employee Form or Add Employee Form
  useEffect(() => {
    if (path === `/employee/add`) {
      setAddEmployee(true);
      setEditEmployee(false);
    } else if (path === `/employee/${id}`) {
      setAddEmployee(false);
      setEditEmployee(false);
    } else if (path === `/employee/${id}/edit`) {
      setEditEmployee(true);
      setAddEmployee(false);
    } else {
      setAddEmployee(false);
      setEditEmployee(false);
    }
  }, [id, path]);

  // Barbershop Info
  const data = useSelector((state) => state.Employee.userProfile);

  const emailPrefix = `@${data.link}.com`;


  // Handle Form Submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const data = {
      name,
      title,
      description,
      dateOfBirth,
      dateOfEmployment,
      email,
      phone,
    };
    if (!base64 && !editEmployee) {
      setLoading(false);
      Swal.fire("Validation Error", "Image is required", "error");
      return;
    }
    if (base64) {
      data.image = base64;
    }
    try {
      // Validate form data against the schema
      await employeeSchema.validate(data);
      // If validation is successful, dispatch the action
      if (addEmployee) {
        let finalPhone = phonePrefix + data.phone;
        data.phone = finalPhone;
        let finalEmail = data.email + emailPrefix;
        data.email = finalEmail;
        dispatch(createAsyncEmployee(data))
          .then(() => {
            setLoading(false);
            Swal.fire("Created!", "Your Employee has been Created.", "success");
            navigate("/employees");
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire(
              "Error!",
              "There was an error updating your Employee.",
              "error"
            );
          });
      } else if (editEmployee) {
        let finalPhone = phonePrefix + data.phone;
        data.phone = finalPhone;
        let finalEmail = data.email + emailPrefix;
        data.email = finalEmail;
        dispatch(updateAsyncEmployee({ data: data, id: id }))
          .then(() => {
            setLoading(false);
            Swal.fire("Updated!", "Your Employee has been updated.", "success");
            navigate("/employees");
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire(
              "Error!",
              "There was an error updating your Employee.",
              "error"
            );
          });
      }
    } catch (error) {
      setLoading(false);
      // Handle validation errors
      console.error("Validation Error:", error);
      Swal.fire("Validation Error", error.message, "error");
    }
  };

  // Validate field individually
  const validateField = async (name, value) => {
    try {
      // Validate the field only
      await employeeSchema.validateAt(name, { [name]: value });
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (error) {
      // Set error message if validation fails
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  // Handle field change
  const handleFieldChange = (name, value) => {
    // Set the touched state
    setTouched((prev) => ({ ...prev, [name]: true }));
    // Validate the field
    validateField(name, value);
  };

  // Fetch Single Employee
  useEffect(() => {
    if (editEmployee) {
      dispatch(fetchAsyncEmployeeById(id));
    }
  }, [dispatch, editEmployee, id]);

  // all category are fetching here to get services
  useEffect(() => {
    if (id) {
      dispatch(fetchAsyncAllCategory());
      dispatch(fetchAsyncServicesByEmployee(id));
    }
  }, [dispatch, id]);

  const selectedEmployee = useSelector(
    (state) => state.Employee.singleEmployee
  );
  const status = useSelector((state) => state.Employee.fetchSingleStatus);

  const userEmail = useSelector((state) => state.Employee.email);
  console.log("userEmail", userEmail);

  // Delete Employee
  const deleteEmployee = async (id) => {
    setDeleteLoading(false);
    const result = await Swal.fire({
      title: "Are you sure for delete?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd6b55",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        setDeleteLoading(true);
        await dispatch(deleteAsyncEmployee(id));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        navigate("/employees");
      } catch (error) {
        Swal.fire("Error", "An error occurred while deleting.", "error");
        console.error("Error deleting service:", error);
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  // Set All States after fetching single employee
  useEffect(() => {
    if (editEmployee) {
      if (selectedEmployee) {
        setName(selectedEmployee.name || "");
        setTitle(selectedEmployee.title || "");
        setDescription(selectedEmployee.description || "");
        setDateOfEmployment(selectedEmployee.dateOfEmployment || "");
        setDateOfBirth(selectedEmployee.dateOfBirth || "");
        setPhone(
          selectedEmployee.phone ? selectedEmployee.phone.split("+961")[1] : ""
        );
        setEmail(
          selectedEmployee.email
            ? selectedEmployee.email.split(`@${data.link}.com`)[0]
            : ""
        );
        setImage(selectedEmployee.url || IMAGES.User);
      }
    }
  }, [selectedEmployee, editEmployee, data.link]);

  // Image Upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileNameClean = reader.result.split("base64,")[1];
      setBase64(fileNameClean);

      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            {status === "loading" ? (
              <Loading status={status} />
            ) : (
              <div className="card profile-card card-bx m-b30">
                <div className="card-header">
                  {editEmployee ? (
                    <h6 className="title">Edit Employee</h6>
                  ) : (
                    <h6 className="title">Add Employee</h6>
                  )}
                </div>
                <div className="p-5">
                  <div className="author-profile">
                    <div className="author-media">
                      <img src={image} className="" alt="" />
                      <div
                        className="upload-link"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-original-title="update"
                      >
                        <input
                          type="file"
                          className="update-flie"
                          onChange={handleFileChange}
                        />
                        <i className="fa fa-camera"></i>
                      </div>
                    </div>
                    <div className="author-info">
                      <h6 className="title">{name}</h6>
                      <span>{title}</span>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleFormSubmit} className="profile-form">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 m-b30">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          value={name}
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) => {
                            setName(e.target.value);
                            handleFieldChange("name", e.target.value);
                          }}
                          onBlur={() => handleFieldChange("name", name)}
                        />
                        {touched.name && errors.name && (
                          <p style={{ color: "red" }}>{errors.name}</p>
                        )}
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label htmlFor="phone" className="form-label">
                          Phone
                        </label>
                        <div className="input-group mb-1">
                          <span class="input-group-text" id="basic-addon2">
                            {phonePrefix}
                          </span>
                          <input
                            type="text"
                            id="phone"
                            value={phone}
                            className="form-control"
                            onChange={(e) => {
                              setPhone(e.target.value);
                              handleFieldChange("phone", e.target.value);
                            }}
                            onBlur={() => handleFieldChange("phone", phone)}
                          />
                        </div>
                        {touched.phone && errors.phone && (
                          <p style={{ color: "red" }}>{errors.phone}</p>
                        )}
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label htmlFor="title" className="form-label">
                          Email
                        </label>
                        <div class="input-group mb-3">
                          <input
                            {...(editEmployee ? { readOnly: true } : {})}
                            type="text"
                            id="email"
                            value={email}
                            class="form-control"
                            placeholder="Email"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailExists(false);
                              handleFieldChange("email", e.target.value);
                            }}
                            onBlur={async () => {
                              if (email) {
                                const emailToCheck = email + emailPrefix;

                                const emailCheckResult = await dispatch(
                                  checkAsyncEmail(emailToCheck)
                                );
                                setEmailExists(emailCheckResult.payload.exists);
                              }
                              handleFieldChange("email", email);
                            }}
                          />
                          <span class="input-group-text" id="basic-addon2">
                            {emailPrefix}
                          </span>
                        </div>
                        {touched.email && errors.email && (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        )}
                        {userEmail === "This email is not unique" ? (
                          <p style={{ color: "red" }}>Email already exists</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label htmlFor="title" className="form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          id="title"
                          value={title}
                          className="form-control"
                          onChange={(e) => {
                            setTitle(e.target.value);
                            handleFieldChange("title", e.target.value);
                          }}
                          onBlur={() => handleFieldChange("title", title)}
                        />
                        {touched.title && errors.title && (
                          <p style={{ color: "red" }}>{errors.title}</p>
                        )}
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <textarea
                          type="text"
                          id="description"
                          value={description}
                          className="form-control"
                          onChange={(e) => {
                            setDescription(e.target.value);
                            handleFieldChange("description", e.target.value);
                          }}
                          onBlur={() =>
                            handleFieldChange("description", description)
                          }
                        />
                        {touched.description && errors.description && (
                          <p style={{ color: "red" }}>{errors.description}</p>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-6 col-lg-6 m-b30">
                            <label className="form-label">
                              Date Of Employment
                            </label>
                            <br />
                            <input
                              type="date"
                              value={dateOfEmployment}
                              placeholder="yyyy-MM-dd"
                              className="form-control"
                              onChange={(e) => {
                                setDateOfEmployment(e.target.value);
                                handleFieldChange(
                                  "dateOfEmployment",
                                  e.target.value
                                );
                              }}
                              onBlur={() =>
                                handleFieldChange(
                                  "dateOfEmployment",
                                  dateOfEmployment
                                )
                              }
                            />
                            {touched.dateOfEmployment &&
                              errors.dateOfEmployment && (
                                <p style={{ color: "red" }}>Date Required</p>
                              )}
                          </div>
                          <div className="col-6 col-lg-6 m-b30">
                            <p className="d-block d-lg-none mb-4 "></p>
                            <label className="form-label">Date Of Birth </label>
                            <br />
                            <input
                              type="date"
                              value={dateOfBirth}
                              placeholder="yyyy-MM-dd"
                              className="form-control"
                              onChange={(e) => {
                                setDateOfBirth(e.target.value);
                                handleFieldChange(
                                  "dateOfBirth",
                                  e.target.value
                                );
                              }}
                              onBlur={() =>
                                handleFieldChange("dateOfBirth", dateOfBirth)
                              }
                            />
                            {touched.dateOfBirth && errors.dateOfBirth && (
                              <p style={{ color: "red" }}>Date Required</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    {editEmployee ? (
                      <>
                        {loading ? (
                          <div className="btn btn-primary d-flex align-items-center justify-content-center">
                            <span className="me-2">Initiated</span>
                            <Spinner animation="border" size="sm" />
                          </div>
                        ) : (
                          <>
                            <input
                              value={"Save"}
                              type="submit"
                              className={"btn btn-primary"}
                              onClick={handleFormSubmit}
                            />
                            {deleteLoading ? (
                              <div className="btn btn-danger d-flex align-items-center justify-content-center">
                                <span className="me-2">Initiated</span>
                                <Spinner animation="border" size="sm" />
                              </div>
                            ) : (
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDeleteLoading(true);
                                  deleteEmployee(id).finally(() =>
                                    setDeleteLoading(false)
                                  );
                                }}
                                className="btn btn-danger"
                              >
                                Delete
                              </button>
                            )}
                          </>
                        )}
                      </>
                    ) : addEmployee ? (
                      <>
                        {loading ? (
                          <div className="btn btn-primary d-flex align-items-center justify-content-center">
                            <span className="me-2">Initiated</span>
                            <Spinner animation="border" size="sm" />
                          </div>
                        ) : (
                          <>
                            {deleteLoading ? (
                              <div className="btn btn-danger d-flex align-items-center justify-content-center">
                                <span className="me-2">Initiated</span>
                                <Spinner animation="border" size="sm" />
                              </div>
                            ) : (
                              <input
                                type="submit"
                                value={"Add Employee"}
                                className={"btn btn-primary"}
                                onClick={handleFormSubmit}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </form>
                {/* services form */}
                {editEmployee && (
                  <>
                    <hr />
                    <EmployeeServices id={id} />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeForm;
