import React, { useState } from "react";
import { BiSolidBellRing } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { useDispatch } from "react-redux";
import {
  fetchServiceWaitlist,
  updateAsyncWaitlistStatus,
} from "../../../../store/waitlist/waitlistSlice";
import Swal from "sweetalert2";
import { updateAsyncReservationStatus } from "../../../../store/reservation/reservationSlice";
import { fetchCompleted } from "../../../../store/complete/completeSlice";
import CardDetailsOffCanvas from "../CardDetailsOffCanvas";
import { createCustomerSms } from "../../../../store/customerSms/customerSmsSlice";

const TabViewContent = ({ name, item }) => {
  let today = new Date();
  let date = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
  console.log(date);
  const dispatch = useDispatch();
  console.log("TabViewContent", item);
  console.log("TabViewContent", name);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [itemId, setItemId] = useState("");
  const handleCardClick = (id) => {
    setItemId(id);
    setShow(true);
  };

  const handleStatus = (itemId, event) => {
    event.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (item.status === "Confirmed") {
          dispatch(async (dispatch, getState) => {
            await dispatch(
              updateAsyncReservationStatus({
                itemId: itemId,
                data: { status: "Serving" },
              })
            );
            dispatch(fetchServiceWaitlist());
          });
        } else if (item.status === "Serving") {
          if (item.servingType === "reservation") {
            dispatch(async (dispatch, getState) => {
              await dispatch(
                updateAsyncReservationStatus({
                  itemId: itemId,
                  data: { status: "Completed" },
                })
              );
              dispatch(fetchServiceWaitlist());
              dispatch(fetchCompleted());
            });
          } else if (item.servingType === "waitlist") {
            dispatch(async (dispatch, getState) => {
              await dispatch(
                updateAsyncWaitlistStatus({
                  itemId: itemId,
                  data: { status: "Completed" },
                })
              );
              dispatch(fetchServiceWaitlist());
              dispatch(fetchCompleted());
            });
          } else {
            console.log("Please check service type");
          }
        } else if (item.status === "Waiting") {
          dispatch(async (dispatch, getState) => {
            await dispatch(
              updateAsyncWaitlistStatus({
                itemId: itemId,
                data: { status: "Serving" },
              })
            );
            dispatch(fetchServiceWaitlist());
          });
        }
      }
    });
  };

  const handleSendSms = (item, event) => {
    event.stopPropagation();
    const data = {
      customerName: item?.name,
      phone: item?.customer?.phone,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send SMS?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(createCustomerSms(data));
      }
    });
  };

  return (
    <>
      <tr className="cursor-pointer" onClick={() => handleCardClick(item?.id)}>
        <td>
          <div className="d-flex align-items-center">
            {item.status === "Waiting"
              ? item.name
              : item.status === "Serving"
              ? item.customerName
              : item.name}
          </div>
        </td>
        <td>
          {/* <SlCalender className="fs-4 me-2"></SlCalender> */}
          {name === "booking"
            ? item.reservationDate
            : name === "waitlist"
            ? item.service?.waitlistPriceInDollar + " Minutes"
            : name === "serving"
            ? item.serviceDuration + " Minutes"
            : name === "completed"
            ? item.service?.durationInMinutes + " Minutes"
            : ""}
        </td>
        <td>
          {item.status === "Serving" ? item.employeeName : item?.employee?.name}
        </td>
        <td>
          {item.status === "Confirmed"
            ? item.service.name
            : item.status === "Waiting"
            ? item.service.name
            : item.status === "Completed"
            ? item.service.name
            : item.serviceName}
        </td>
        <td>
          <div className="">
            {name === "completed" ? (
              ""
            ) : name === "serving" ? (
              <>
                <FaCheckCircle
                  onClick={(event) => handleStatus(item?.id, event)}
                  className="text-success ms-1 fs-2 cursor-pointer"
                ></FaCheckCircle>
              </>
            ) : name === "waitlist" ? (
              // Add your JSX for the "waitlist" condition here
              <>
                <BiSolidBellRing
                  onClick={(event) => handleSendSms(item, event)}
                  className="fs-2 cursor-pointer"
                />
                <FaCheckCircle
                  onClick={(event) => handleStatus(item?.id, event)}
                  className="text-success ms-1 fs-2 cursor-pointer"
                ></FaCheckCircle>
              </>
            ) : (
              <>
                {item.reservationDate === date ? (
                  <FaCheckCircle
                    onClick={(event) => handleStatus(item?.id, event)}
                    className="text-success ms-1 fs-2 cursor-pointer"
                  ></FaCheckCircle>
                ) : null}
              </>
            )}
          </div>
        </td>
      </tr>
      <CardDetailsOffCanvas
        show={show}
        item={item}
        itemId={itemId}
        handleClose={handleClose}
      />
    </>
  );
};

export default TabViewContent;
