import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  addServicesForEmployee,
  getEmployeeByserviceId,
  getServicesByEmployeeId,
  removeServicesForEmployee,
} from "./employeeServicesAPI";

// async thunks
export const fetchAsyncServicesByEmployee = createAsyncThunk(
  "employeeServices/fetchAsyncServicesByEmployee",
  async (employeeId) => {
    const services = await getServicesByEmployeeId(employeeId);
    return services;
  }
);

export const createAsyncServiceForEmployee = createAsyncThunk(
  "employeeServices/createAsyncServiceForEmployee",
  async (data) => {
    const service = await addServicesForEmployee(data);
    return service;
  }
);

export const deleteAsyncServiceForEmployee = createAsyncThunk(
  "employeeServices/deleteAsyncServiceForEmployee",
  async (data) => {
    const services = await removeServicesForEmployee(data);
    return services;
  }
);

export const fetchAsyncEmployeeByServiceId = createAsyncThunk(
  "servicesOfEmployee/fetchAsyncEmployeesByServiceId",
  async (serviceId) => {
    const services = await getEmployeeByserviceId(serviceId);
    return services;
  }
);


const initialState = {
  employeeServices: [],
  status: "idle",
  error: null,

  servicesGivenEmpoyees: [],
  servicesGivenEmpoyeesStatus: "idle",
  servicesGivenEmpoyeesError: null,
};

const employeeServicesSlice = createSlice({
  name: "employeeServices",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncServicesByEmployee.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncServicesByEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employeeServices = action.payload;
      })
      .addCase(fetchAsyncServicesByEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createAsyncServiceForEmployee.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAsyncServiceForEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employeeServices = action.payload;
      })
      .addCase(createAsyncServiceForEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteAsyncServiceForEmployee.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAsyncServiceForEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employeeServices.services =
          state.employeeServices.services.filter(
            (service) => service.id !== action.meta.arg.serviceId
          );
      })
      .addCase(deleteAsyncServiceForEmployee.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // filtered services list which given by employees
      .addCase(fetchAsyncEmployeeByServiceId.pending, (state) => {
        state.servicesGivenEmpoyeesStatus = "loading";
      })
      .addCase(fetchAsyncEmployeeByServiceId.fulfilled, (state, action) => {
        state.servicesGivenEmpoyeesStatus = "succeeded";
        state.servicesGivenEmpoyees = action.payload;
      })
      .addCase(fetchAsyncEmployeeByServiceId.rejected, (state, action) => {
        state.servicesGivenEmpoyeesStatus = "failed";
        state.servicesGivenEmpoyeesError = action.error.message;
      });
  },
});

export default employeeServicesSlice.reducer;
