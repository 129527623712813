import { del, get, post, put } from "aws-amplify/api";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

// Create employee
export async function createEmployee(data) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/employees",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log("POST call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Employee created successfully!",
    });
    return response;
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Get all employees
export async function getEmployees() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/employees",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// Get employee by id
export async function getEmployeeById(id) {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Delete employee
export async function deleteEmployee(id) {
  console.log(id);
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${id}`,
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("DELETE call failed: ", error);
  }
}

// Update employee
export async function updateEmployee({ data, id }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/employees/${id}`,
      options: {
        body: data,
      },
    });
    await restOperation.response;
    return id;
  } catch (err) {
    console.log("PUT call failed: ", err);
  }
}

// Check email
export async function checkEmail(email) {
  console.log(email);
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/employees/check-unique-email",
      options: {
        body: {
          email: email,
        },
      },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}

// User Profile

export async function getUserProfile() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/barbers/profile",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("Get call failed: ", error);
  }
}
