import { configureStore } from "@reduxjs/toolkit";
import employeeSlice from "./employee/EmployeeSlice";
import categorySlice from "./category/categorySlice";
import singInSignUpSlice from "./actions/singInSignOutSlice";
import barberInfoSlice from "./barberInfo/barberInfoSlice";
import contactSlice from "./contact/contactSlice";
import gallerySlice from "./gallery/gallerySlice";
import barberHomeSlice from "./barberHome/barberHomeSlice";
import barberSocialSlice from "./barberSocial/barberSocialSlice";
import barberAboutSlice from "./barberAbout/barberAboutSlice";
import BarberService from "./barberService/barberServiceSlice";
import serviceSlice from "./categoryServices/serviceSlice";
import testSlice from "./testAPI/testSlice";
import messagesSlice from "./messages/messagesSlice";
import paginationSlice from "./paginationSlice";
import composeSlice from "./messages/composeSlice";
import authSlice from "./authSlice";
import barberGallerySlice from "./barberGallery/barberGallerySlice";
import trendingSlice from "./trending/trendingSlice";
import ExpenseSlice from "./expenseTracker/ExpenseSlice";
import ExpenseCategory from "./expenseTracker/ExpenseCategory";
import EmployeeServicesSlice from "./employeeServices/employeeServicesSlice";
import workingHoursSlice from "./workingHoursSlice";
import discoverySlice from "./discovery/discoverySlice";
import inventorySlice from "./inventory/inventorySlice";
import singInSignOutSlice from "./actions/singInSignOutSlice";
import barberContactSlice from "./barberContact/barberContactSlice";
import barberServiceSlice from "./barberService/barberServiceSlice";
import waitlistSlice from "./waitlist/waitlistSlice";
import reservationSlice from "./reservation/reservationSlice";
import historySlice from "./history/historySlice";
import analyticsSlice from "./analytics/analyticsSlice";
import customerSlice from "./customer/customerSlice";
import revenueSlice from "./revenue/revenueSlice";
import monthlyRevenueSlice from "./daysAndMonthlyRevenue/dailyAndMonthlyRevenueSlice";
import inventoryHistorySlice from "./inventory/inventoryHistorySlice";
import servingSlice from "./serving/servingSlice";
import completeSlice from "./complete/completeSlice";
import employeeWorkingHoursSlice from "./employeeWorkingHours/employeeWorkingHoursSlice";
import employeeSpecialHoursSlice from "./employeeWorkingHours/employeeSpecialHoursSlice";
import inventoryUserSlice from "./inventoryUser/inventoryUserSlice";
import bookingAndWaitlistSlice from "./bookingAndWaitlist/bookingAndWaitlistSlice";
import mapLinkSlice from "./mapLink/mapLinkSlice";
import customerSmsSlice from "./customerSms/customerSmsSlice";
import userInfoSlice from "./userInfo/userInfoSlice";

export const store = configureStore({
  reducer: {
    Employee: employeeSlice,
    Category: categorySlice,
    singInSignUp: singInSignUpSlice,
    BarberInfo: barberInfoSlice,
    BarberHome: barberHomeSlice,
    BarberSocial: barberSocialSlice,
    BarberAbout: barberAboutSlice,
    BarberContact: barberContactSlice,
    BarberGallery: barberGallerySlice,
    BarberService: barberServiceSlice,
    Contact: contactSlice,
    Gallery: gallerySlice,
    Trending: trendingSlice,
    Services: serviceSlice,
    Messages: messagesSlice,
    Test: testSlice,
    Pagination: paginationSlice,
    Compose: composeSlice,
    Expense: ExpenseSlice,
    ExpenseCategory: ExpenseCategory,
    EmployeeServices: EmployeeServicesSlice,
    WorkingHours: workingHoursSlice,
    Discovery: discoverySlice,
    Inventory: inventorySlice,
    ProductInventory: inventoryUserSlice,
    InventoryHistory: inventoryHistorySlice,
    Waitlist: waitlistSlice,
    Reservation: reservationSlice,
    Serving: servingSlice,
    Complete: completeSlice,
    History: historySlice,
    Customer: customerSlice,
    analytics: analyticsSlice,
    Revenue: revenueSlice,
    MonthlyRevenue: monthlyRevenueSlice,
    EmployeeHours: employeeWorkingHoursSlice,
    EmployeeSpecialWorking: employeeSpecialHoursSlice,
    BookingAndWaitlist: bookingAndWaitlistSlice,
    CustomerSms: customerSmsSlice,
    Map: mapLinkSlice,
    User: userInfoSlice,
  },
});
