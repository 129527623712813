import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SmsCounter from "sms-counter";
import {
  fetchAsyncDeleteMessage,
  fetchAsyncSingleMessage,
} from "../../../store/messages/messagesSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import {
  fetchAsyncReplyMessage,
  fetchAsyncSentMessages,
} from "../../../store/messages/composeSlice";

const SingleMessage = ({ singleMessageId }) => {
  // All Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // all state
  const [message, setMessage] = useState("");
  const [smsInfo, setSmsInfo] = useState(null);

  // Get single message
  useEffect(() => {
    dispatch(fetchAsyncSingleMessage(singleMessageId));
  }, [dispatch, singleMessageId]);
  const singleMessage = useSelector((state) => state.Messages.singleMessage);
  const status = useSelector((state) => state.Messages.singleMessageStatus);
  console.log(singleMessage);

  // handles are here
  const handleInputChange = (event) => {
    event.preventDefault();
    const newMessage = event.target.value;
    setMessage(newMessage);
    const smsCount = SmsCounter.count(newMessage, true);
    setSmsInfo(smsCount);
  };

  // handles for send message
  const handleSubmitData = () => {
    const data = {
      phone: singleMessage?.phone,
      body: message,
    };
    dispatch(fetchAsyncReplyMessage({ data, messageId: singleMessageId }))
      .then(() => {
        Swal.fire({
          position: "top-end",
          title: "Message sent successfully",
          showConfirmButton: false,
          icon: "success",
          timer: 2000,
          toast: true,
        });
        // Reset the form after sending the message
        setMessage("");
        setSmsInfo(null);
      })
      .catch((error) => {
        Swal.fire(
          "Error",
          "An error occurred while sending the message.",
          "error"
        );
        // console.error('Error sending message:', error);
      });
  };

  // Delete message
  const handleDeleteMessage = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this message?",
      text: "You will not be able to recover it!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(fetchAsyncDeleteMessage(id))
          .then(() => {
            Swal.fire({
              position: "top-end",
              title: "Message deleted successfully",
              showConfirmButton: false,
              icon: "success",
              timer: 2000,
              toast: true,
            });

            navigate("/inbox-page");
          })
          .catch((error) => {
            Swal.fire(
              "Error",
              "An error occurred while deleting the message.",
              "error"
            );
            // console.error('Error deleting message:', error);
          });
      }
    });
  };

  // format the date and time
  const date = { year: "numeric", month: "short", day: "numeric" };
  const time = { hour: "numeric", minute: "numeric", hour12: true };

  // Regex pattern for a valid Lebanese phone number
  const lebanesePhoneNumberPattern = /^(\+961|0)?(1|3|4|7|8)\d{7}$/;

  return (
    <>
      {status === "loading" ? (
        <Loading status={status} />
      ) : (
        <>
          <div role="toolbar" className="toolbar ms-1 ms-sm-0">
            <div className="saprat ps-3">
              <div className="mail-tools ms-0">
                <Link
                  to="/all-message"
                  className="btn btn-primary px-3 light me-2"
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="right-box-padding border-start p-0">
                <div className="read-wapper dz-scroll mb-3" id="read-content">
                  <div className="read-content">
                    <div className="media pt-3 d-sm-flex d-block justify-content-between">
                      <div className="clearfix mb-3 d-flex">
                        <div className="media-body me-2">
                          <h5 className="text-primary mb-0 mt-1">
                            Name: {singleMessage?.name}
                          </h5>
                          <h5 className="mb-0">
                            Phone: {singleMessage?.phone}
                          </h5>
                          {/* <p className="mb-0">
                            {new Date(
                              singleMessage?.createdAt * 1000
                            ).toLocaleDateString(undefined, date)}
                          </p>
                          <p className="mb-0">
                            Time:{" "}
                            {new Date(
                              singleMessage?.createdAt * 1000
                            ).toLocaleTimeString(undefined, time)}
                          </p> */}
                        </div>
                      </div>
                      <div className="clearfix mb-3">
                        <a
                          href="#write-email"
                          className="btn btn-primary px-3 light me-2"
                        >
                          <i className="fa fa-reply"></i>
                        </a>

                        <Link
                          onClick={() => handleDeleteMessage(singleMessageId)}
                          className="btn btn-primary px-3 light me-2"
                        >
                          <i className="fa fa-trash"></i>
                        </Link>
                      </div>
                    </div>
                    <hr />
                    <div className="media mb-2 mt-3">
                      <div className="media-body">
                        <span className="float-end">
                          <p className="mb-0">
                            {new Date(
                              singleMessage?.createdAt * 1000
                            ).toLocaleDateString(undefined, date)}{" "}
                            Time:
                            {new Date(
                              singleMessage?.createdAt * 1000
                            ).toLocaleTimeString(undefined, time)}
                          </p>
                        </span>
                        <p className="read-content-email">
                          Subject: {singleMessage?.subject}
                        </p>
                      </div>
                    </div>
                    <div className="read-content-body">
                      <p className="mb-2">{singleMessage?.message}</p>
                      <hr />
                      <h5>Your Reply</h5>
                      {singleMessage?.replays?.length > 0 ? (
                        singleMessage.replays.map((reply, index) => (
                          <div key={index} className="border p-3 rounded-2 mb-4">
                            <p className="mb-2">{reply.body}</p>
                          </div>
                        ))
                      ) : (
                        <p>You didn't reply anything.</p>
                      )}
                    </div>

                    <div className="mb-3 pt-3">
                      {lebanesePhoneNumberPattern.test(singleMessage?.phone) ? (
                        <>
                          <>
                            <textarea
                              name="write-email"
                              id="write-email"
                              cols="30"
                              rows="5"
                              className="form-control"
                              placeholder="enter your message..."
                              value={message}
                              onChange={handleInputChange}
                            ></textarea>
                            {smsInfo && (
                              <div className="row justify-content-between">
                                <small className="col form-text">
                                  Character Count: {smsInfo.length}
                                </small>
                                <small className="col form-text text-center">
                                  Remaining Character: {smsInfo.remaining}
                                </small>
                                <small className="col form-text text-end">
                                  SMS Count: {smsInfo.messages}
                                </small>
                              </div>
                            )}
                          </>
                          <div className="text-end mt-2">
                            <button
                              className="btn btn-primary btn-sl-sm me-2"
                              type="button"
                              onClick={() => handleSubmitData()}
                            >
                              <span className="me-2">
                                <i className="fa fa-paper-plane"></i>
                              </span>
                              Send
                            </button>
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-danger">
                          You can only reply to a lebanese number!
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SingleMessage;
