import React, { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import TabViewCard from "../TabViewCard/TabViewCard";
import Card from "../Card";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchTodaysReservation } from "../../../../store/reservation/reservationSlice";
import { updateReservationStatus } from "../../../../store/reservation/reservationAPI";
import BookingOffCanvas from "./BookingOffCanvas";
import { fetchCompleted } from "../../../../store/complete/completeSlice";
import { fetchAllData } from "../../../../store/globalActions";
import { fetchUserRole } from "../../../../store/userInfo/userInfoSlice";

const Booking = ({ tableView }) => {
  const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
  const [filteredReservation, setFilteredReservation] = useState([]);

  const dispatch = useDispatch();
    // Get todays waitlist
    useEffect(() => {
      dispatch(fetchTodaysReservation());
      dispatch(fetchUserRole());

    }, [dispatch]);
    const allReservation = useSelector(
      (state) => state.Reservation.todaysReservation
    );
  

  const userData = useSelector((state) => state.Employee.userProfile);
  const data = useSelector((state) => state.User.role);
  console.log(data);

  useEffect(() => {
    // Check if the logged-in user is Admin
    if (data === "admin" || data === "supervisor") {
      // If Admin, return all waitlist items
      console.log(allReservation);
      setFilteredReservation(allReservation);
    } else {
      // If not Admin, filter the waitlist by the logged-in employee's name
      const filter = allReservation.filter(
        (waitlistItem) => waitlistItem.employee.name === userData.name
      );
      setFilteredReservation(filter);
      console.log(filteredReservation);
    }
  }, [data, allReservation]);


  // Offcanvas for card details
  const [show, setShow] = useState(false);
  // Offcanvas for details
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const handleCloseOffCanvas = () => {
    setShowOffCanvas(false);
  };

  // Update waitlist status
  const handleStatus = async (waitlistId) => {
    await dispatch(
      updateReservationStatus({
        waitlistId: waitlistId,
        data: { status: "Serving" },
      })
    );
    // dispatch(fetchAllData());
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-12">
          <div className="bg-white rounded-3 p-3">
            <div className="row align-items-center">
              <div className="col-9">
                <h4 className="mb-0">Today's Booking</h4>
                <p className="mb-0">{allReservation?.length} Client</p>
              </div>
              <div className="col-3 text-end">
                <BsFillPlusCircleFill
                  onClick={() => setShowOffCanvas(true)}
                  className="cursor-pointer text-primary display-6"
                ></BsFillPlusCircleFill>
              </div>
            </div>
          </div>
        </div>
        <BookingOffCanvas show={showOffCanvas} handleClose={handleCloseOffCanvas} />
        {isTablet || tableView ? (
          <TabViewCard name={"booking"} items={allReservation} />
        ) : (
          <>
            {filteredReservation?.map((item) => (
              <Card
                item={item}
                name={"booking"}
                handleCardClick={handleCloseOffCanvas}
                handleStatus={handleStatus}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Booking;
