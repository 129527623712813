import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postCustomerSms } from "./customerSmsAPI";


export const createCustomerSms = createAsyncThunk(
  "customerSms/createCustomerSms",
  async (data) => {
    const services = await postCustomerSms(data);
    return services;
  }
);

const initialState = {
  customerSms: "",
  loading: false,
  error: null,
};


const customerSmsSlice = createSlice({  
  name: "customerSms",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerSms.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomerSms.fulfilled, (state, action) => {
        state.customerSms = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(createCustomerSms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});


export default customerSmsSlice.reducer;