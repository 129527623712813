import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import LogoutPage from "./Logout";
import { SVGICON } from "../../constant/theme";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncUnreadMessages } from "../../../store/messages/messagesSlice";
import loadingImg from "../../../images/user.jpg";
import { fetchUserAttributes } from "aws-amplify/auth";

const Header = () => {
  const data = useSelector((state) => state.Employee.userProfile);
  const userProfileStatus = useSelector(
    (state) => state.Employee.userProfileStatus
  );
  // console.log(data)


  const [role, setRole] = useState(null);

  useEffect(() => {
    fetchUserAttributes()
      .then((attributes) => {
        const userRole = attributes["custom:role"];

        setRole(userRole);
      })
      .catch((error) => {});
  }, []);

  const [headerFix, setheaderFix] = useState(false);
  const [header, setHeader] = useState("Dashboard");
  const { pathname } = useLocation();

  // Fetch all unread messages
  const pages = useSelector((state) => state.Pagination.page);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAsyncUnreadMessages(pages));
  }, [dispatch, pages]);
  const allUnreadMessage = useSelector((state) => state.Messages.unreadMessage);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  // Header name dynamic with pathname
  useEffect(() => {
    if (pathname === "/") {
      setHeader("Dashboard");
    } else if (pathname === "/visit-page") {
      setHeader("Visit Page");
    } else if (pathname === "/employees") {
      setHeader("Employees Page");
    } else if (pathname === "/category-page") {
      setHeader("Category Page");
    } else if (pathname === "/general-page") {
      setHeader("General Page");
    } else if (pathname === "/waitlist-page") {
      setHeader("Waitlist Page");
    } else if (pathname === "/booking-page") {
      setHeader("Booking Page");
    } else if (pathname === "/gallery-page") {
      setHeader("Gallery Page");
    } else if (pathname === "/trending-page") {
      setHeader("Trending Page");
    } else if (pathname === "/contact-page") {
      setHeader("Contact Page");
    } else if (pathname === "/about-page") {
      setHeader("About Page");
    } else if (pathname === "/profile-page") {
      setHeader("Profile Page");
    } else if (pathname === "/inbox-page") {
      setHeader("Inbox Page");
    } else if (pathname === "/history-page") {
      setHeader("History Page");
    } else if (pathname === "/customers-page") {
      setHeader("Customer Page");
    } else if (pathname === "/discovery-page") {
      setHeader("Discovery");
    } else if (pathname === "/expense-history") {
      setHeader("Expense History");
    } else if (pathname === "/other-expense") {
      setHeader("Other Expenses");
    } else if (pathname === "/rent-utilities") {
      setHeader("Rent or Utilities");
    } else if (pathname === "/employees-tracker") {
      setHeader("Employees");
    } else if (pathname === "/product-tracker") {
      setHeader("Employees");
    } else if (pathname === "/inventory-card") {
      setHeader("Inventory");
    } else if (pathname === "/inventory-history") {
      setHeader("Inventory History");
    } else if (pathname === "/inventory-user") {
      setHeader("Inventory User");
    }
  }, [pathname]);

  return (
    <div className={`header zIndex ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {header}
              </div>
            </div>
            <div className="header-right d-flex align-items-center">
              <ul className="navbar-nav ">
                {role === "admin" && (
                  <Link
                    as="li"
                    className="nav-item dropdown notification_dropdown"
                    to="/inbox-page"
                  >
                    <Dropdown.Toggle
                      variant=""
                      as="a"
                      className="nav-link  i-false c-pointer"
                    >
                      {SVGICON.MailIcon}
                      <span className="badge light text-white bg-secondary rounded-circle">
                        {allUnreadMessage?.total}
                      </span>
                    </Dropdown.Toggle>
                  </Link>
                )}
                <li className="nav-item ps-3">
                  <Dropdown className="header-profile2">
                    <Dropdown.Toggle className="nav-link i-false" as="div">
                      <div className="header-info2 d-flex align-items-center">
                        <div className="header-media">
                          {userProfileStatus === "loading" ? (
                            <img src={loadingImg} alt="" />
                          ) : (
                            <img className="profile-logo" src={data?.url} alt="" />
                          )}
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <div className="card border-0 mb-0">
                        <div className="card-header py-2">
                          <div className="products">
                            <img
                              src={data?.url}
                              className="avatar avatar-md"
                              alt=""
                            />
                            <div>
                              <h6>{data?.name}</h6>
                              <span>{data?.title}</span>
                            </div>
                          </div>
                        </div>
                        <div className="card-body px-0 py-2">
                          <Link
                            to={
                              role === "admin"
                                ? "/profile-page"
                                : "/profile-details"
                            }
                            className="dropdown-item ai-icon"
                          >
                            {SVGICON.UserSvg}
                            <span className="ms-2">Profile</span>
                          </Link>
                        </div>
                        <LogoutPage />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
