import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAsyncBarberSocialInfo,
  putAsyncBarberSocialInfo,
} from "../../../store/barberSocial/barberSocialSlice";
import Swal from "sweetalert2";
import { Card, Spinner } from "react-bootstrap";

const GeneralSocial = () => {
  const dispatch = useDispatch();
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [loading, setLoading] = useState(false);

  // Get barber Social info
  useEffect(() => {
    dispatch(getAsyncBarberSocialInfo());
  }, [dispatch]);

  const barberSocial = useSelector((state) => state.BarberSocial.barberSocial);

  // put barber social info
  const handleSocialInfo = async () => {
    try {
      setLoading(true);
      if (facebook === "" || instagram === "" || tiktok === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please fill in all fields!",
        });
      } else {
        // Validate and correct URLs
        const correctedFacebook = /^(f|ht)tps?:\/\//i.test(facebook)
          ? facebook
          : `https://${facebook}`;
        const correctedInstagram = /^(f|ht)tps?:\/\//i.test(instagram)
          ? instagram
          : `https://${instagram}`;
        const correctedTiktok = /^(f|ht)tps?:\/\//i.test(tiktok)
          ? tiktok
          : `https://${tiktok}`;

        await dispatch(
          putAsyncBarberSocialInfo({
            facebook: correctedFacebook,
            instagram: correctedInstagram,
            tiktok: correctedTiktok,
          })
        );

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your changes have been saved!",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving your changes!",
      });
    } finally {
      setLoading(false);
    }
  };

  // Update state after get barber Social info
  useEffect(() => {
    if (barberSocial) {
      setFacebook(barberSocial.facebook);
      setInstagram(barberSocial.instagram);
      setTiktok(barberSocial.tiktok);
    }
  }, [barberSocial]);

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <h5>Socials</h5>
          <p className="text-muted">
            Your location name abss is displayed across many areas including on
            your online pages and messages to clients.
          </p>
        </div>
        <div className="col-md-9">
          <h5>Facebook</h5>
          <input
            value={facebook}
            placeholder="Add a Facebook link"
            onChange={(e) => setFacebook(e.target.value)}
            type="text"
            className="form-control"
          />
          <h5 className="mt-3">Instagram</h5>
          <input
            value={instagram}
            placeholder="Add an Instagram link"
            onChange={(e) => setInstagram(e.target.value)}
            type="text"
            className="form-control"
          />
          <h5 className="mt-3">Tiktok</h5>
          <input
            value={tiktok}
            placeholder="Add a TikTok link"
            onChange={(e) => setTiktok(e.target.value)}
            type="text"
            className="form-control"
          />
          <button
            onClick={() => handleSocialInfo()}
            className="btn btn-outline-primary rounded-5 mt-4"
          >
            {loading === true ? (
              <div className="d-flex align-items-center justify-content-center ">
                <span className="me-2">Initiated</span>
                <Spinner animation="border" size="sm" />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      <Card.Footer className="mt-3" />
    </>
  );
};

export default GeneralSocial;
