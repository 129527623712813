import React from "react";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { postAsyncTrendingImage } from "../../../store/trending/trendingSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const AddCustomModal = (props) => {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [fileName, setFileName] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState("");

  const dispatch = useDispatch();

  // Function to convert file to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileNameClean = reader.result.split("base64,")[1];
        resolve(fileNameClean);
      };
      reader.onerror = (error) => {
        console.error("Error converting file to base64!", error);
        reject(error);
      };
    });
  };
  // Handle Image Upload
  const handleChange = async (file) => {
    setFileName(file.name);
    if (file) {
      try {
        let base64 = await convertToBase64(file);
        console.log(base64);
        setImage(base64);
      } catch (error) {
        Swal.fire("Error", "An error occurred while uploading.", "error");
      }
    }
  };

  const handleUpload = async () => {
    if (image === null || title === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields!",
      });
    } else {
      try {
        setLoading(true);
        await dispatch(postAsyncTrendingImage({ title: title, image: image }));
      } catch (error) {
        Swal.fire("Error", "An error occurred while uploading.", "error");
      } finally {
        setLoading(false);
      }
    }
    setTitle("");
    setImage("");
    setFileName("");
    props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        className="custom-modal-size"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Custom Images
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body mx-auto">
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                  />
                </div>
                <h6 className="text-center">
                  <span className="text-primary">File Name: </span>{" "}
                  {fileName.slice(0, 100)}
                </h6>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
              <h3>Add Image Title</h3>
              <input
                placeholder="Add Image Title Here"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleUpload()}>
            {loading === true ? (
              <div className="d-flex align-items-center justify-content-center ">
                <span className="me-2">Initiated</span>
                <Spinner animation="border" size="sm" />
              </div>
            ) : (
              "Add Image"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCustomModal;
