import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCompletedWaitlist,
  getServingWaitlist,
  getTodaysWaitlist,
  updateWaitlistStatus,
} from "./waitlistAPI";

// get todays waitlist
export const fetchTodaysWaitlist = createAsyncThunk(
  "waitlist/fetchTodaysWaitlist",
  async () => {
    const response = await getTodaysWaitlist();
    return response;
  }
);

// get serving waitlist
export const fetchServiceWaitlist = createAsyncThunk(
  "waitlist/fetchServiceWaitlist",
  async () => {
    const response = await getServingWaitlist();
    return response;
  }
);



// get Completed waitlist
export const fetchCompletedWaitlist = createAsyncThunk(
  "waitlist/fetchCompletedWaitlist",
  async () => {
    const response = await getCompletedWaitlist();
    return response;
  }
);

// Update waitlist status
export const updateAsyncWaitlistStatus = createAsyncThunk(
  "waitlist/updateWaitlistStatus",
  async (data) => {
    console.log("data", data);
    const response = await updateWaitlistStatus(data);
    return response;
  }
);

const initialState = {
  todaysWaitlist: [],
  serviceWaitlist: [],
  completedWaitlist: [],
  waitlist: [],
  status: "idle",
  error: null,
};

const waitlistSlice = createSlice({
  name: "waitlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get todays waitlist
      .addCase(fetchTodaysWaitlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTodaysWaitlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.todaysWaitlist = action.payload;
      })
      .addCase(fetchTodaysWaitlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Update waitlist status
      .addCase(updateAsyncWaitlistStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAsyncWaitlistStatus.fulfilled, (state, action) => {
        // Store the updated item
        const updatedItem = action.payload;

        // If the updated status is "Serving", remove the item from todaysWaitlist and add it to serviceWaitlist
        if (updatedItem?.status === "Serving") {
          state.todaysWaitlist = state.todaysWaitlist?.filter(
            (item) => item.id !== updatedItem.id
          );
          state.serviceWaitlist = state.serviceWaitlist?.filter(
            (item) => item.id !== updatedItem.id
          );
          state.serviceWaitlist.push(updatedItem);
        }

        // If the updated status is "Completed", remove the item from serviceWaitlist and add it to completedWaitlist
        if (updatedItem?.status === "Completed") {
          state.serviceWaitlist = state.serviceWaitlist?.filter(
            (item) => item.id !== updatedItem.id
          );
          state.completedWaitlist.push(updatedItem);
        }

        state.status = "succeeded";
      })
      .addCase(updateAsyncWaitlistStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // get serving waitlist
      .addCase(fetchServiceWaitlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServiceWaitlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.serviceWaitlist = action.payload;
      })
      .addCase(fetchServiceWaitlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // get Completed waitlist
      .addCase(fetchCompletedWaitlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompletedWaitlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.completedWaitlist = action.payload;
      })
      .addCase(fetchCompletedWaitlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default waitlistSlice.reducer;
