import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import TabViewContent from "./TabViewContent";
import { useSelector } from "react-redux";

const TabViewHeader = ({ name, items }) => {
  
  

  const [filteredReservation, setFilteredReservation] = useState([]);
  const [filteredWaitlist, setFilteredWaitlist] = useState([]);
  const [filteredServing, setFilteredServing] = useState([]);
  const [filteredComplete, setFilteredComplete] = useState([]);
  const data = useSelector((state) => state.Employee.userProfile);
  

  useEffect(() => {
    if (name === "booking") {
      // Check if the logged-in user is Admin
      if (data.title === "Admin") {
        // If Admin, return all waitlist items
        
        setFilteredReservation(items);
      } else {
        // If not Admin, filter the waitlist by the logged-in employee's name
        const filter = items.filter(
          (waitlistItem) =>
            waitlistItem.employee.name.trim() === data.name.trim()
        );
        setFilteredReservation(filter);
        
      }
      
    } else if (name === "waitlist") {
      // Check if the logged-in user is Admin
      if (data.title === "Admin") {
        // If Admin, return all waitlist items
        
        setFilteredWaitlist(items);
      } else {
        // If not Admin, filter the waitlist by the logged-in employee's name
        const filter = items.filter(
          (waitlistItem) =>
            waitlistItem.employee.name.trim() === data.name.trim()
        );
        setFilteredWaitlist(filter);
        
      }
      
    } else if (name === "serving") {
      // Check if the logged-in user is Admin
      if (data.title === "Admin") {
        // If Admin, return all waitlist items
        
        setFilteredServing(items);
      } else {
        // If not Admin, filter the waitlist by the logged-in employee's name
        const filter = items.filter(
          (servingItem) => servingItem.employeeName === data.name
        );
        setFilteredServing(filter);
        
      }
      
    } else if (name === "completed") {
      // Check if the logged-in user is Admin
      if (data.title === "Admin") {
        // If Admin, return all waitlist items
        setFilteredComplete(items);
      } else {
        // If not Admin, filter the waitlist by the logged-in employee's name
        const filter = items.filter(
          (servingItem) => servingItem.employee.name === data.name
        );
        setFilteredComplete(filter);
        
      }
      
    } else {
      console.log("error");
    }
  }, [data, items]);

  return (
    <>
      <div className="col-12 mt-1">
        <Card>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  {name === "booking" ? (
                    <th>
                      <strong>Booking Date</strong>
                    </th>
                  ) : (
                    <th>
                      <strong>Duration</strong>
                    </th>
                  )}
                  <th>
                    <strong>staff</strong>
                  </th>
                  <th>
                    <strong>Service</strong>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  // Check the name of the tab and render the content accordingly
                  name === "booking" ? (
                    <>
                      {filteredReservation?.map((item) => (
                        <TabViewContent name={name} item={item}></TabViewContent>
                      ))}
                    </>
                  ) : name === "waitlist" ? (
                    <>
                      {filteredWaitlist?.map((item) => (
                        <TabViewContent name={name} item={item}></TabViewContent>
                      ))}
                    </>
                  ) : name === "serving" ? (
                    <>
                      {filteredServing?.map((item) => (
                        <TabViewContent name={name} item={item}></TabViewContent>
                      ))}
                    </>
                  ) : name === "completed" ? (
                    <>
                      {filteredComplete?.map((item) => (
                        <TabViewContent name={name} item={item}></TabViewContent>
                      ))}
                    </>
                  ) : (
                    <></>
                  )
                }
                {/* {items?.map((item) => (
                  <TabViewContent name={name} item={item}></TabViewContent>
                ))} */}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default TabViewHeader;
