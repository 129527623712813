import { get, post, put, del } from "@aws-amplify/api-rest";
import { amplifyConfigs } from "../../config";
import Swal from "sweetalert2";

// Create product
export async function createProduct(data) {
  try {
    const restOperation = post({
      apiName: amplifyConfigs.apiName,
      path: "/products",
      options: {
        body: data,
      },
    });
    const response = await restOperation.response;
    console.log("POST call succeeded: ", response);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Product created successfully!",
    });
    return response;
  } catch (error) {
    console.log("POST call failed: ", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
  }
}

// Get all products
export async function getProduct() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/products",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// update products
export async function editProduct({ id, data }) {
  try {
    const restOperation = put({
      apiName: amplifyConfigs.apiName,
      path: `/products/${id}`,
      options: {
        body: data,
      },
    });
    await restOperation.response;
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Product edited successfully!",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", id);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Delete employee
export async function removeProduct(id) {
  try {
    const restOperation = del({
      apiName: amplifyConfigs.apiName,
      path: `/products/${id}`,
    });
    await restOperation.response;
  } catch (error) {
    console.log("DELETE call failed: ", error);
  }
}

// Get all inventory history
export async function getAllInventoryHistory() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/inventory/history",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Get inventory history for the in product
export async function getInventoryHistoryOfIn() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/inventory/in",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

// Get inventory history for the in product
export async function getInventoryHistoryOfOut() {
  try {
    const restOperation = get({
      apiName: amplifyConfigs.apiName,
      path: "/inventory/out",
    });
    const response = await restOperation.response;
    console.log("GET call succeeded: ", response);
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}